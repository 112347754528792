import React, { useRef } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import FormProperties from './B13aFormFormProperties'
import B13aFormFieldsPlaceholder from './B13aFormFieldsPlaceholder'
import { FormProvider, useForm } from 'react-hook-form'
import _ from 'lodash'
import { Box, Card, Divider, Grid } from '@material-ui/core'
import useCustomYupValidationResolver from '../../../../components/aciacehighway/hooks/useCustomYupValidationResolver'

const {
  button: { CngButton }
} = components

const { initialValues, makeValidationSchema } = FormProperties.formikProps

function B13aFormSection(props) {
  const { b13aForm: b13aFormProp, onClose, onSubmit } = props

  const generatedIndex = useRef(0)
  const methods = useForm({ defaultValues: { b13aForm: b13aFormProp } })
  const {
    formState: { isSubmitting },
    getValues,
    setValue,
    watch
  } = methods
  const b13aForm = watch('b13aForm')

  function handleAddB13aForm(data) {
    const b13aForm = [...getValues('b13aForm'), data]

    setValue('b13aForm', b13aForm)
  }

  function handleDeleteB13aForm(index) {
    const b13aForm = [...getValues('b13aForm')]

    b13aForm.splice(index, 1)

    setValue('b13aForm', b13aForm)
  }

  function handleEditB13aForm(index, data) {
    const b13aForm = [...getValues('b13aForm')]

    b13aForm[index] = data

    setValue('b13aForm', b13aForm)
  }

  async function handleSubmit(data) {
    return new Promise((resolve) => {
      setTimeout(() => {
        onSubmit(data.b13aForm)
        resolve()
      }, 500)
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card variant='outlined'>
          <Grid container spacing={2}>
            {_.isEmpty(b13aForm) ? (
              <Grid item xs={12}>
                <Box padding={2}>
                  <B13aFormFieldsPlaceholder onAdd={handleAddB13aForm} />
                </Box>
              </Grid>
            ) : (
              b13aForm.map((item, index) => (
                <React.Fragment key={++generatedIndex.current}>
                  <Grid item xs={12}>
                    <Box padding={2}>
                      <B13aFormEntry
                        b13aForm={item}
                        onDelete={() => handleDeleteB13aForm(index)}
                        onEdit={(data) => handleEditB13aForm(index, data)}
                      />
                    </Box>
                  </Grid>
                  {index + 1 !== b13aForm.length && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </React.Fragment>
              ))
            )}
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify='space-between' spacing={2}>
          <Grid item xs='auto'>
            <CngButton
              color='secondary'
              disabled={isSubmitting}
              onClick={() => methods.reset(b13aForm || initialValues)}
            >
              Clear all
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <Grid container spacing={2}>
              <Grid item xs='auto'>
                <CngButton
                  color='secondary'
                  disabled={isSubmitting}
                  onClick={onClose}
                >
                  Discard
                </CngButton>
              </Grid>
              <Grid item xs='auto'>
                <CngButton
                  disabled={isSubmitting}
                  onClick={methods.handleSubmit(handleSubmit)}
                >
                  Save
                </CngButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

function B13aFormEntry(props) {
  const { b13aForm, onDelete, onEdit } = props

  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const methods = useForm({
    defaultValues: b13aForm,
    resolver: useCustomYupValidationResolver(makeValidationSchema(translate))
  })
  const {
    formState: { isDirty }
  } = methods

  function handleEditB13aForm(data) {
    methods.reset(data)
    onEdit(data)
  }

  return (
    <FormProvider {...methods}>
      <Grid alignItems='center' container spacing={1}>
        <Grid item xs={12}>
          <FormProperties.Fields />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify='flex-end' spacing={1}>
            {isDirty ? (
              <>
                <Grid item xs='auto'>
                  <CngButton
                    color='secondary'
                    onClick={() => methods.reset()}
                    size='medium'
                  >
                    Reset
                  </CngButton>
                </Grid>
                <Grid item xs='auto'>
                  <CngButton
                    color='primary'
                    onClick={methods.handleSubmit(handleEditB13aForm)}
                    size='medium'
                  >
                    Save changes
                  </CngButton>
                </Grid>
              </>
            ) : (
              <Grid item xs='auto'>
                <CngButton
                  color='primary'
                  onClick={() => onDelete(b13aForm)}
                  size='medium'
                >
                  Remove
                </CngButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default B13aFormSection
