import React, { useRef } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import FormProperties from './ImportDeclFormFormProperties'
import ImportDeclFormFieldsPlaceholder from './ImportDeclFormFieldsPlaceholder'
import { FormProvider, useForm } from 'react-hook-form'
import _ from 'lodash'
import { Box, Card, Divider, Grid } from '@material-ui/core'
import useCustomYupValidationResolver from '../../../../components/aciacehighway/hooks/useCustomYupValidationResolver'

const {
  button: { CngButton }
} = components

const { initialValues, makeValidationSchema } = FormProperties.formikProps

function ImportDeclFormSection(props) {
  const { importDeclForm: importDeclFormProp, onClose, onSubmit } = props

  const generatedIndex = useRef(0)
  const methods = useForm({ defaultValues: { importDeclForm: importDeclFormProp } })
  const {
    formState: { isSubmitting },
    getValues,
    setValue,
    watch
  } = methods
  const importDeclForm = watch('importDeclForm')

  function handleAddImportDeclForm(data) {
    const importDeclForm = [...getValues('importDeclForm'), data]

    setValue('importDeclForm', importDeclForm)
  }

  function handleDeleteImportDeclForm(index) {
    const importDeclForm = [...getValues('importDeclForm')]

    importDeclForm.splice(index, 1)

    setValue('importDeclForm', importDeclForm)
  }

  function handleEditImportDeclForm(index, data) {
    const importDeclForm = [...getValues('importDeclForm')]

    importDeclForm[index] = data

    setValue('importDeclForm', importDeclForm)
  }

  async function handleSubmit(data) {
    return new Promise((resolve) => {
      setTimeout(() => {
        onSubmit(data.importDeclForm)
        resolve()
      }, 500)
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card variant='outlined'>
          <Grid container spacing={2}>
            {_.isEmpty(importDeclForm) ? (
              <Grid item xs={12}>
                <Box padding={2}>
                  <ImportDeclFormFieldsPlaceholder onAdd={handleAddImportDeclForm} />
                </Box>
              </Grid>
            ) : (
              importDeclForm.map((item, index) => (
                <React.Fragment key={++generatedIndex.current}>
                  <Grid item xs={12}>
                    <Box padding={2}>
                      <ImportDeclFormEntry
                        importDeclForm={item}
                        onDelete={() => handleDeleteImportDeclForm(index)}
                        onEdit={(data) => handleEditImportDeclForm(index, data)}
                      />
                    </Box>
                  </Grid>
                  {index + 1 !== importDeclForm.length && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </React.Fragment>
              ))
            )}
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify='space-between' spacing={2}>
          <Grid item xs='auto'>
            <CngButton
              color='secondary'
              disabled={isSubmitting}
              onClick={() => methods.reset(importDeclForm || initialValues)}
            >
              Clear all
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <Grid container spacing={2}>
              <Grid item xs='auto'>
                <CngButton
                  color='secondary'
                  disabled={isSubmitting}
                  onClick={onClose}
                >
                  Discard
                </CngButton>
              </Grid>
              <Grid item xs='auto'>
                <CngButton
                  disabled={isSubmitting}
                  onClick={methods.handleSubmit(handleSubmit)}
                >
                  Save
                </CngButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

function ImportDeclFormEntry(props) {
  const { importDeclForm, onDelete, onEdit } = props

  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const methods = useForm({
    defaultValues: importDeclForm,
    resolver: useCustomYupValidationResolver(makeValidationSchema(translate))
  })
  const {
    formState: { isDirty }
  } = methods

  function handleEditImportDeclForm(data) {
    methods.reset(data)
    onEdit(data)
  }

  return (
    <FormProvider {...methods}>
      <Grid alignItems='center' container spacing={1}>
        <Grid item xs={12}>
          <FormProperties.Fields />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify='flex-end' spacing={1}>
            {isDirty ? (
              <>
                <Grid item xs='auto'>
                  <CngButton
                    color='secondary'
                    onClick={() => methods.reset()}
                    size='medium'
                  >
                    Reset
                  </CngButton>
                </Grid>
                <Grid item xs='auto'>
                  <CngButton
                    color='primary'
                    onClick={methods.handleSubmit(handleEditImportDeclForm)}
                    size='medium'
                  >
                    Save changes
                  </CngButton>
                </Grid>
              </>
            ) : (
              <CngButton
                color='primary'
                onClick={() => onDelete(importDeclForm)}
                size='medium'
              >
                Remove
              </CngButton>
            )}
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default ImportDeclFormSection
