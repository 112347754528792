import makeValidationSchema from './CiFormMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../constants/locale/key/NBCICanadaInvoice'
import { Box, Card, Grid, Typography } from '@material-ui/core'
import CargoSummarySection from './CargoSummarySection'
import { useFormContext } from 'react-hook-form'

const {
  form: {
    field: {
      CngCountryAutocompleteField,
      CngTextField,
      CngSelectField
    }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  countryTransShipment: '',
  countryOfOrigin: '',
  placeOfDirectShipment: '',
  alternativeInvNo: '',
  customsRuling: '',
  transportTermCode: '',
  termOfSale: '',
  royalityPayments: 'N',
  royalityGoods: 'N',
  ciCargoSummary: [],
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { setValue } = useFormContext()

  function makeTranslatedTextsObject() {
    const countryTransShipment = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CiForm.COUNTRY_TRANS_SHIPMENT
    )
    const countryOfOrigin = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CiForm.COUNTRY_OF_ORIGIN
    )
    const placeOfDirectShipment = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CiForm.PLACE_OF_DIRECT_SHIPMENT
    )
    const alternativeInvNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CiForm.ALTERNATIVE_INV_NO
    )
    const customsRuling = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CiForm.CUSTOMS_RULING
    )
    const transportTermCode = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CiForm.TRANSPORT_TERM_CODE
    )
    const termOfSale = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CiForm.TERM_OF_SALE
    )
    const royalityPayments = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CiForm.ROYALITY_PAYMENTS
    )
    const royalityGoods = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CiForm.ROYALITY_GOODS
    )

    return {
      countryTransShipment,
      countryOfOrigin,
      placeOfDirectShipment,
      alternativeInvNo,
      customsRuling,
      transportTermCode,
      termOfSale,
      royalityPayments,
      royalityGoods,
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.countryTransShipment}>            
            <CngCountryAutocompleteField
              name='countryTransShipment'
              label={translatedTextsObject.countryTransShipment}
              disabled={disabled}
              size='small'
              onChange={(value) => setValue('countryTransShipment', value, { shouldDirty: true })}
              lookupProps={{
                label: (record) => `${record.code};${record.descriptionEn}`
              }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.countryOfOrigin}>
            <CngCountryAutocompleteField
              name='countryOfOrigin'
              label={translatedTextsObject.countryOfOrigin}
              onChange={(value) => setValue('countryOfOrigin', value, { shouldDirty: true })}
              disabled={disabled}
              size='small'
              lookupProps={{
                label: (record) => `${record.code};${record.descriptionEn}`
              }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.placeOfDirectShipment}>
            <CngTextField
              name='placeOfDirectShipment'
              label={translatedTextsObject.placeOfDirectShipment}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.alternativeInvNo}>
            <CngTextField
              name='alternativeInvNo'
              label={translatedTextsObject.alternativeInvNo}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.customsRuling}>
            <CngTextField
              name='customsRuling'
              label={translatedTextsObject.customsRuling}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.termOfSale}>
            <CngTextField
              name='termOfSale'
              label={translatedTextsObject.termOfSale}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.transportTermCode}>
            <CngSelectField
              name='transportTermCode'
              label={translatedTextsObject.transportTermCode}
              disabled={disabled}
              size='small'
              items={[
                { text: 'Cost and Freight', value: 'CFR' },
                { text: 'Cost, Insurance, and Freight', value: 'CIF' },
                { text: 'Carriage and Insurance Paid To', value: 'CIP' },
                { text: 'Carriage Paid To', value: 'CPT' },
                { text: 'Delivered at Frontier', value: 'DAF' },
                { text: 'Delivered Duty Paid', value: 'DDP' },
                { text: 'Deliver Duty Unpaid', value: 'DDU' },
                { text: 'Delivered Ex Quay', value: 'DEQ' },
                { text: 'Delivered Ex Ship ', value: 'DES' },
                { text: 'Ex Works', value: 'EXW' },
                { text: 'Free Alongside Ship ', value: 'FAS' },
                { text: 'Free Carrier', value: 'FCA' },
                { text: 'Free on Board', value: 'FOB' },
              ]}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.royalityPayments}>
            <Card variant='outlined'>
              <Box bgcolor='background.sectionOddBg' p={2}>
                <Box mb={1}>
                  <Typography>
                    {translatedTextsObject.royalityPayments}
                  </Typography>
                </Box>
                <CngSelectField
                  label='Answer'
                  name='royalityPayments'
                  disabled={disabled}
                  items={[
                    { text: 'Yes', value: 'Y' },
                    { text: 'No', value: 'N' }
                  ]}
                  size='small'
                />
              </Box>
            </Card>
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.royalityGoods}>
            <Card variant='outlined'>
              <Box bgcolor='background.sectionOddBg' p={2}>
                <Box mb={1}>
                  <Typography>
                    {translatedTextsObject.royalityGoods}
                  </Typography>
                </Box>
                <CngSelectField
                  label='Answer'
                  name='royalityGoods'
                  disabled={disabled}
                  items={[
                    { text: 'Yes', value: 'Y' },
                    { text: 'No', value: 'N' }
                  ]}
                  size='small'
                />
              </Box>
            </Card>
          </CngGridItem>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CargoSummarySection />
      </Grid>
    </Grid>
  )
}

const CiFormFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default CiFormFormProperties
