import React from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../../constants/locale/key/NBCICanadaInvoice'
import CngField from '../../../components/cngcomponents/CngField'
import Grid from '@material-ui/core/Grid'
import _ from 'lodash'
import moment from 'moment'

function CfiaFormViewContent(props) {
  const { cfiaForm } = props

  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const etaDateTime = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.ETA_DATE_TIME
    )
    const cbsaReleaseOff = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.CBSA_RELEASE_OFF
    )
    const deliveryAddress = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.DELIVERY_ADDRESS
    )
    const meatShipment = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.MEAT_SHIPMENT
    )
    const unmarkMeatShip = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.UNMARK_MEAT_SHIP
    )
    const quantity = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.QUANTITY
    )
    const driverWaiting = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.DRIVER_WAITING
    )
    const isEdi = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.IS_EDI
    )
    const transactionNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.TRANSACTION_NO
    )
    const prodDesc = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.PROD_DESC
    )
    const voyageNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.VOYAGE_NO
    )
    const entryPoint = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.ENTRY_POINT
    )
    const blNumber = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.BL_NUMBER
    )
    const comments = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.COMMENTS
    )
    const payerName = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.PAYER_NAME
    )
    const cfiaClientAcctNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.CFIA_CLIENT_ACCT_NO
    )
    const sendAddressInd = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.SEND_ADDRESS_IND
    )

    return {
      etaDateTime,
      cbsaReleaseOff,
      deliveryAddress,
      meatShipment,
      unmarkMeatShip,
      quantity,
      driverWaiting,
      isEdi,
      transactionNo,
      prodDesc,
      voyageNo,
      entryPoint,
      blNumber,
      comments,
      payerName,
      cfiaClientAcctNo,
      sendAddressInd,
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.etaDateTime}>
          {cfiaForm.etaDateTime &&
            moment(cfiaForm.etaDateTime).format('D MMM YYYY, HH:mm')}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.cbsaReleaseOff}>
          {cfiaForm.cbsaReleaseOff}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.deliveryAddress}>
          {cfiaForm.deliveryAddress}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.meatShipment}>
          {cfiaForm.meatShipment}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.unmarkMeatShip}>
          {cfiaForm.unmarkMeatShip}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.quantity}>
          {cfiaForm.quantity}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.driverWaiting}>
          {cfiaForm.driverWaiting}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.isEdi}>
          {cfiaForm.isEdi}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.transactionNo}>
          {cfiaForm.transactionNo}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.prodDesc}>
          {cfiaForm.prodDesc}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.voyageNo}>
          {cfiaForm.voyageNo}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.entryPoint}>
          {cfiaForm.entryPoint}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.blNumber}>
          {cfiaForm.blNumber}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.comments}>
          {cfiaForm.comments}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.payerName}>
          {cfiaForm.payerName}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.cfiaClientAcctNo}>
          {cfiaForm.cfiaClientAcctNo}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.sendAddressInd}>
          {cfiaForm.sendAddressInd}
        </CngField>
      </Grid>
    </Grid>
  )
}

export default CfiaFormViewContent
