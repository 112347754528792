import React, { useContext } from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../../constants/locale/key/NBCICanadaInvoice'
import { CAInvoiceLookupsContext } from '../lookups/CAInvoiceLookupsContext'
import CngField from '../../../components/cngcomponents/CngField'
import { Grid, useTheme } from '@material-ui/core'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function PackingListFormViewContent(props) {
  const { packingList } = props

  const { getLookupValue } = useContext(CAInvoiceLookupsContext)
  const theme = useTheme()
  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const otherRefNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.PackingList.OTHER_REF_NO
    )
    const responsibleForCustoms = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.PackingList.RESPONSIBLE_FOR_CUSTOMS
    )
    const instructionsToBroker = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.PackingList.INSTRUCTIONS_TO_BROKER
    )
    const containerized = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.PackingList.CONTAINERIZED
    )
    const billChargesTo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.PackingList.BILL_CHARGES_TO
    )
    const portOfLoading = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.PackingList.PORT_OF_LOADING
    )
    const flightDetails = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.PackingList.FLIGHT_DETAILS
    )
    const placeOfDelivery = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.PackingList.PLACE_OF_DELIVERY
    )
    const specialInstructions = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.PackingList.SPECIAL_INSTRUCTIONS
    )

    return {
      otherRefNo,
      portOfLoading,
      responsibleForCustoms,
      flightDetails,
      instructionsToBroker,
      placeOfDelivery,
      billChargesTo,
      containerized,
      specialInstructions,
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.otherRefNo}>
          {packingList.otherRefNo}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.portOfLoading}>
          {packingList.portOfLoading}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.responsibleForCustoms}>
          {packingList.responsibleForCustoms}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.flightDetails}>
          {packingList.flightDetails}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.instructionsToBroker}>
          {getLookupValue('instructionsToBroker', packingList.instructionsToBroker)}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.placeOfDelivery}>
          {packingList.placeOfDelivery}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.billChargesTo}>
          {getLookupValue('billChargesTo', packingList.billChargesTo)}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Grid container spacing={2}>
          <Grid item xs='auto'>
            <FontAwesomeIcon
              color={
                packingList.containerized === 'Y'
                  ? theme.palette.primary.main
                  : theme.palette.error.main
              }
              fixedWidth
              icon={['fal', packingList.containerized === 'Y' ? 'check' : 'ban']}
            />
          </Grid>
          <Grid item xs>
            {translatedTextsObject.containerized}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.specialInstructions}>
          {packingList.specialInstructions}
        </CngField>
      </Grid>
    </Grid>
  )
}

export default PackingListFormViewContent
