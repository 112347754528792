import NBCICanadaInvoiceApiUrls from '../../apiUrls/NBCICanadaInvoiceApiUrls'
import FormProperties from './FormProperties'
import { Box, Card, Grid, useTheme } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components } from 'cng-web-lib'
import pathMap from '../../paths/pathMap'
import Stepper from '../../components/aciacehighway/Stepper'
import AlertDialog from '../../components/aciacehighway/AlertDialog'
import withFormState from '../../components/aciacehighway/hocs/withFormState'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'
import { useLocation } from 'react-router-dom'

const {
  button: { CngButton },
  form: { CngAddForm }
} = components

const { initialValues } = FormProperties.formikProps

function AddPage(props) {
  const { history, onSetLoading, showNotification } = props
  const loc = useLocation()
  const onSuccessCallback = useRef((e) =>
    history.push(`${pathMap.N_B_C_I_CANADA_INVOICE}/edit/` + e.id)
  )
  let formikPropsData;
  if(loc.state !== undefined) {
    formikPropsData = {
          ...FormProperties.formikProps,
          initialValues: FormProperties.toClientDataFormat(loc.state)
    }
  } else {
    formikPropsData = FormProperties.formikProps
  }
  
  const theme = useTheme()

  function handleSaveAndContinue() {
    onSuccessCallback.current = (e) => {
      history.push({
        pathname: `${pathMap.N_B_C_I_CANADA_INVOICE}/edit/` + e.id,
        state: { step: 1, invoiceData: FormProperties.toClientDataFormat(e) }
      })
    }
  }

  return (
    <Card>
      <Stepper
        steps={['Provide details', 'Add invoice lines & summary invoice']}
        activeStep={0}
      />
      <Box p={2}>
        <CngAddForm
          fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
          history={history}
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
              onSetLoading={onSetLoading}
            />
          )}
          formikProps={formikPropsData}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          create={{
            url: NBCICanadaInvoiceApiUrls.POST,
            onSuccess: (e) => {
              const { errorMessages } = e

              if (!_.isEmpty(errorMessages)) {
                errorMessages.forEach((message) =>
                  showNotification('error', message)
                )
              } else {
                showNotification('success', 'Invoice created successfully.')
                onSuccessCallback.current(e)
              }
            }
          }}
          renderButtonSection={() => (
            <AddPageButtonSection
              history={history}
              onSetLoading={onSetLoading}
              onSaveAndContinue={handleSaveAndContinue}
            />
          )}
        />
      </Box>
    </Card>
  )
}

function AddPageButtonSection(props) {
  const { history, onSetLoading, onSaveAndContinue } = props

  const [confirmDialog, setConfirmDialog] = useState(false)
  const {
    formState: { isSubmitting }
  } = useFormContext()

  useEffect(() => {
    onSetLoading(isSubmitting)
  }, [isSubmitting])

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={isSubmitting}
            onClick={() => setConfirmDialog(true)}
            size='medium'
          >
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={isSubmitting}
                type='submit'
                size='medium'
              >
                Save as draft
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={isSubmitting}
                type='submit'
                onClick={onSaveAndContinue}
                size='medium'
              >
                Save &amp; continue
              </CngButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AlertDialog
        cancelLabel='Continue editing'
        confirmLabel='Yes, discard'
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        onCancel={() => setConfirmDialog(false)}
        onConfirm={() => history.push(pathMap.N_B_C_I_CANADA_INVOICE_LIST_VIEW)}
        title='Discard'
      >
        All progress in this session will be lost and cant't be restored. Are
        you sure about this?
      </AlertDialog>
    </>
  )
}

export default withFormState(AddPage)
