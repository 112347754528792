import { Yup } from 'cng-web-lib'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'

function makeValidationSchema() {
  const regexAlphaNumeric =
    ValidationRegex.regexAlphaNumeric
  const errMsgAlphaNumeric =
    'Invalid Value - Allowed Alphanumeric, spaces, and /,+-$#@%&!^_:;.?()[]{}\'"'
  const regexDigit = '^[0-9]*$'
  const errMsgDigit = 'Invalid Value - Allowed Digits'

  const validateStringField = (maxLength, regexPattern, errorMsg) =>
    Yup.string()
      .nullable()
      .matches(regexPattern, errorMsg)
      .max(
        maxLength,
        'Field can contain maximum of ' + maxLength + ' characters'
      )

  return Yup.object({
    additionDeductionInd: Yup.string().nullable(),
    additionDeductionCode: Yup.string().nullable(),
    amountRatePercent: validateStringField(10, regexDigit, errMsgDigit),
    additionDeductionType: Yup.string().nullable(),
    explanation: validateStringField(22, regexAlphaNumeric, errMsgAlphaNumeric)
  })
}

export default makeValidationSchema
