import React, { useRef } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../../../constants/locale/key/NBCICanadaInvoice'
import FormProperties from './ProdDetailsFormProperties'
import CngSection from '../../../../components/cngcomponents/CngSection'
import ProdDetailsFieldsPlaceholder from './ProdDetailsFieldsPlaceholder'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import _ from 'lodash'
import { Divider, Grid, Tooltip } from '@material-ui/core'

const {
  button: { CngButton, CngIconButton }
} = components

const { initialValues } = FormProperties.formikProps

function ProductDetailsSection() {
  const generatedIndex = useRef(0)
  const { getValues, setValue, watch } = useFormContext()

  const prodDetails = watch('prodDetails')

  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function handleAddProdDetails(data) {
    const prodDetails = [...getValues('prodDetails'), data]

    setValue('prodDetails', prodDetails)
  }

  function handleDeleteProdDetails(index) {
    const prodDetails = [...getValues('prodDetails')]

    prodDetails.splice(index, 1)

    setValue('prodDetails', prodDetails)
  }

  function handleEditProdDetails(index, data) {
    const prodDetails = [...getValues('prodDetails')]

    prodDetails[index] = data

    setValue('prodDetails', prodDetails)
  }

  function makeTranslatedTextsObject() {
    const prodDetails = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ProdDetails.TITLE
    )

    return { prodDetails }
  }

  return (
    <CngSection title={translatedTextsObject.prodDetails}>
      <Grid container spacing={2}>
        {_.isEmpty(prodDetails) ? (
          <Grid item xs={12}>
            <ProdDetailsFieldsPlaceholder onAdd={handleAddProdDetails} />
          </Grid>
        ) : (
          prodDetails.map((item, index) => (
            <React.Fragment key={++generatedIndex.current}>
              <Grid item xs={12}>
                <ProdDetailsEntry
                  prodDetails={item}
                  onAdd={handleAddProdDetails}
                  onDelete={() => handleDeleteProdDetails(index)}
                  onEdit={(data) => handleEditProdDetails(index, data)}
                />
              </Grid>
              {index + 1 !== prodDetails.length && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          ))
        )}
      </Grid>
    </CngSection>
  )
}

function ProdDetailsEntry(props) {
  const { prodDetails, onAdd, onDelete, onEdit } = props

  const methods = useForm({ defaultValues: prodDetails })
  const {
    formState: { isDirty }
  } = methods

  function handleEditProdDetails(data) {
    methods.reset(data)
    onEdit(data)
  }

  return (
    <FormProvider {...methods}>
      <Grid alignItems='center' container spacing={1}>
        <Grid item xs={12}>
          <FormProperties.Fields />
        </Grid>
        <Grid item xs={12}>
          <Grid justify='flex-end' container spacing={1}>
            {isDirty ? (
              <>
                <Grid item xs='auto'>
                  <CngButton
                    color='secondary'
                    onClick={() => methods.reset()}
                    size='medium'
                  >
                    Reset
                  </CngButton>
                </Grid>
                <Grid item xs='auto'>
                  <CngButton
                    color='primary'
                    onClick={methods.handleSubmit(handleEditProdDetails)}
                    size='medium'
                  >
                    Save changes
                  </CngButton>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Add'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'plus']}
                        onClick={() => onAdd(initialValues)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Clone'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'copy']}
                        onClick={() => onAdd({ ...prodDetails, id: undefined })}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Delete'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'trash']}
                        onClick={() => onDelete(prodDetails)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default ProductDetailsSection
