import React, { useEffect, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import NaCodeMaintenanceApiUrls from '../../../apiUrls/NaCodeMaintenanceApiUrls'

const {
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL },
  CodeMaintenanceType
} = constants

const CAInvoiceLookupsContext = React.createContext()

const CAInvoiceLookupsConsumer = CAInvoiceLookupsContext.Consumer

const localLookups = {
  transportMode: {
    7: 'Mail',
    J: 'Road',
    A: 'Air',
    R: 'Rail',
    S: 'Ocean',
    T: 'Best Way(Shippers Opinion)',
    W: 'Inland Waterland'
  },
  transportTermCode: {
    CFR: 'Cost and Freight',
    CIF: 'Cost, Insurance, and Freight',
    CIP: 'Carriage and Insurance Paid To',
    CPT: 'Carriage Paid To',
    DAF: 'Delivered at Frontier',
    DDP: 'Delivered Duty Paid',
    DDU: 'Deliver Duty Unpaid',
    DEQ: 'Delivered Ex Quay',
    DES: 'Delivered Ex Ship ',
    EXW: 'Ex Works',
    FAS: 'Free Alongside Ship ',
    FCA: 'Free Carrier',
    FOB: 'Free on Board'
  },
  certifierType: {
    IR: 'Importer',
    EX: 'Exporter',
    MP: 'Producer'
  },
  freightCharges: {
    Y: 'Prepaid',
    N: 'Collect'
  },
  pkgsType: {
    CD: 'Carat',
    C3: 'Centiliter',
    CM: 'Centimeter',
    CC: 'Cubic Centimeter',
    C8: 'Cubic Decimeter',
    CO: 'Cubic Meters (Net)',
    '4D': 'Curie',
    DL: 'Deciliter',
    DM: 'Decimeter',
    DZ: 'Dozen',
    DP: 'Dozen Pair',
    EA: 'Each',
    G4: 'Gigabecquerel',
    GR: 'Gram',
    GG: 'Great Gross',
    GS: 'Gross',
    HG: 'Hectogram',
    H4: 'Hectoliter',
    HU: 'Hundred',
    86: 'Joules',
    J2: 'Joule Per Kilogram',
    KG: 'Kilogram',
    K4: 'Kilovolt Amperes',
    K7: 'Kilowatt',
    KH: 'Kilowatt Hour',
    LT: 'Liter',
    MU: 'Millicurie',
    ME: 'Milligram',
    MM: 'Milliliter',
    ML: 'Milliliter',
    '4N': 'Megabecquerel',
    MR: 'Meter',
    MP: 'Metric Ton',
    LB: 'Pound',
    PC: 'Piece',
    PR: 'Pair',
    D3: 'Square Decimeter',
    SC: 'Square Centimeter',
    SM: 'Square Meter',
    TH: 'Thousand',
    99: 'Watt'
  },
  b13aStatus: {
    E: 'Exporter (Shipper)',
    o: 'Other'
  },
  instructionsToBroker: {
    Y: 'Arrange customs clearance',
    N: 'In bond to destination'
  },
  billChargesTo: {
    E: 'Exporter',
    S: 'Sold To',
    C: 'Consignee'
  },
  weightUom: {
    KG: 'Kilograms',
    LB: 'Pounds'
  },
  transactionType: {
    Y: 'CSA Receipt - Yes',
    N: 'CSA Receipt - No'
  },
  reference: {
    XC: 'Cargo Control Number',
    BM: 'Bill of Lading',
    MB: 'Master Bill of Lading',
    CN: 'Container Number'
  },
  noOfPkgsUom: {
    BA: 'Bale',
    BB: 'Base Box',
    BC: 'Bucket',
    BG: 'Bag',
    BN: 'Bulk',
    BR: 'Barrel',
    BS: 'Basket',
    BX: 'Box',
    C4: 'Carload',
    CA: 'Case',
    CH: 'Container',
    CL: 'Cylinder',
    CP: 'Crate',
    CT: 'Carton',
    DR: 'Drum',
    EV: 'Envelope',
    GD: 'Gross Barrels',
    HB: 'Hundred Boxes',
    KE: 'Keg',
    NB: 'Barge',
    NV: 'Vehicle',
    PC: 'Piece',
    PF: 'Pallet(Lift)',
    PK: 'Package',
    PL: 'Pallete/Unit Load',
    RA: 'Rack',
    RE: 'Reel',
    RL: 'Roll',
    SH: 'Sheet',
    SK: 'Split Tanktruck',
    SO: 'Spool',
    SP: 'ShelfPackage',
    SV: 'Skid',
    TK: 'Tank',
    UN: 'Unit'
  },
  notAProducerOfGoods: {
    1: '1. Your knowledge of whether the goods qualifies as an originating good.',
    2: '2. Your reliance on the producer\'s written representation (other than a Certificate of Origin) that the good qualifies as an originating.',
    3: '3. A completed & signed Certificate for the good, voluntarily provided to the exporter by the producer.',
  },
}

const CAInvoiceLookupsProvider = ({ children }) => {
  const [lookups, setLookups] = useState(null)
  const { fetchRecords } = useServices()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  useEffect(() => {
    Promise.all([
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'NBCI_CA_INV_PARTY_TYPE' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'CA_INV_ADD_DED_IND' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'CA_INV_ADD_DED_CODE' }
      ]),
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'CA_INV_ADD_DED_TYPE' }
      ]),
      //fetchCodeMaintenanceLookup(CodeMaintenanceType.COUNTRY),
      fetchRecords.execute(
        NaCodeMaintenanceApiUrls.GET,
        {
          filters: [
            { field: 'active', operator: EQUAL, value: true },
            { field: 'indicator', operator: EQUAL, value: 'cci' }
          ],
          customData: { codeMType: 'NACountryCode' }
        },
        (res) => {
          const result = {}

          res.content.forEach((item) => {
            result[item.code] = item.descriptionEn
          })

          return result
        }
      ),
      fetchRecords.execute(
        NaCodeMaintenanceApiUrls.GET,
        {
          filters: [
            { field: 'active', operator: EQUAL, value: true },
            { field: 'indicator', operator: EQUAL, value: 'ACI' }
          ],
          customData: { codeMType: 'CountryStateCode' }
        },
        (res) => res.content
      ),
      // Ace port for port of exit
      fetchRecords.execute(
        NaCodeMaintenanceApiUrls.GET,
        {
          filters: [
            { field: 'set_indicator', operator: EQUAL, value: 'ACEHWY_POE' }
          ],
          customData: { codeMType: 'DocPortCode' }
        },
        (res) => {
          const result = {}

          res.content.forEach((item) => {
            result[item.code] = item.descriptionEn
          })

          return result
        }
      )
    ]).then(
      ([
        partyType,
        additionDeductionInd,
        additionDeductionCode,
        additionDeductionType,
        country,
        countryStates,
        acePort
      ]) => {
        const states = Object.keys(country).reduce((acc, countryCode) => {
          acc[countryCode] = countryStates.filter(
            (state) => state.code === countryCode
          )

          return acc
        }, {})

        setLookups({
          ...localLookups,
          partyType,
          additionDeductionInd,
          additionDeductionCode,
          additionDeductionType,
          country,
          states,
          acePort
        })
      }
    )
  }, [])

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function getCountryStateLabel(country, state) {
    if (!lookups || !country || !state) return state

    if (Object.keys(lookups.country).includes(country)) {
      const result = lookups.states[country].find(
        (countryState) => countryState.intlcode === state
      )

      return result ? result.descriptionEn : state
    } else {
      return state
    }
  }

  return (
    <CAInvoiceLookupsContext.Provider
      value={{ lookups, getLookupValue, getCountryStateLabel }}
    >
      {children}
    </CAInvoiceLookupsContext.Provider>
  )
}

export default CAInvoiceLookupsProvider

export { CAInvoiceLookupsContext, CAInvoiceLookupsConsumer }
