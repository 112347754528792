import React, { useRef } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../../../constants/locale/key/NBCICanadaInvoice'
import FormProperties from './ContDetailsFormProperties'
import CngSection from '../../../../components/cngcomponents/CngSection'
import ContDetailsFieldsPlaceholder from './ContDetailsFieldsPlaceholder'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import _ from 'lodash'
import { Divider, Grid, Tooltip } from '@material-ui/core'

const {
  button: { CngButton, CngIconButton }
} = components

const { initialValues } = FormProperties.formikProps

function ContainerDetailsSection() {
  const generatedIndex = useRef(0)
  const { getValues, setValue, watch } = useFormContext()

  const contDetails = watch('contDetails')

  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function handleAddContDetails(data) {
    const contDetails = [...getValues('contDetails'), data]

    setValue('contDetails', contDetails, { shouldDirty: true })
  }

  function handleDeleteContDetails(index) {
    const contDetails = [...getValues('contDetails')]

    contDetails.splice(index, 1)

    setValue('contDetails', contDetails, { shouldDirty: true })
  }

  function handleEditContDetails(index, data) {
    const contDetails = [...getValues('contDetails')]

    contDetails[index] = data

    setValue('contDetails', contDetails, { shouldDirty: true })
  }

  function makeTranslatedTextsObject() {
    const contDetails = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ContDetails.TITLE
    )

    return { contDetails }
  }

  return (
    <CngSection title={translatedTextsObject.contDetails}>
      <Grid container spacing={2}>
        {_.isEmpty(contDetails) ? (
          <Grid item xs={12}>
            <ContDetailsFieldsPlaceholder onAdd={handleAddContDetails} />
          </Grid>
        ) : (
          contDetails.map((item, index) => (
            <React.Fragment key={++generatedIndex.current}>
              <Grid item xs={12}>
                <ContDetailsEntry
                  contDetails={item}
                  onAdd={handleAddContDetails}
                  onDelete={() => handleDeleteContDetails(index)}
                  onEdit={(data) => handleEditContDetails(index, data)}
                />
              </Grid>
              {index + 1 !== contDetails.length && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          ))
        )}
      </Grid>
    </CngSection>
  )
}

function ContDetailsEntry(props) {
  const { contDetails, onAdd, onDelete, onEdit } = props

  const methods = useForm({ defaultValues: contDetails })
  const {
    formState: { isDirty }
  } = methods

  function handleEditContDetails(data) {
    methods.reset(data)
    onEdit(data)
  }

  return (
    <FormProvider {...methods}>
      <Grid alignItems='center' container spacing={1}>
        <Grid item xs={12}>
          <FormProperties.Fields />
        </Grid>
        <Grid item xs={12}>
          <Grid justify='flex-end' container spacing={1}>
            {isDirty ? (
              <>
                <Grid item xs='auto'>
                  <CngButton
                    color='secondary'
                    onClick={() => methods.reset()}
                    size='medium'
                  >
                    Reset
                  </CngButton>
                </Grid>
                <Grid item xs='auto'>
                  <CngButton
                    color='primary'
                    onClick={methods.handleSubmit(handleEditContDetails)}
                    size='medium'
                  >
                    Save changes
                  </CngButton>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Add'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'plus']}
                        onClick={() => onAdd(initialValues)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Clone'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'copy']}
                        onClick={() => onAdd({ ...contDetails, id: undefined })}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Delete'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'trash']}
                        onClick={() => onDelete(contDetails)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default ContainerDetailsSection
