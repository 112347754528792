import { useTheme } from '@material-ui/core'
import {
  constants,
  useTranslation,
  useServices
} from 'cng-web-lib'

import UsProductMasterApiUrls from '../../../apiUrls/UsProductMasterApiUrls'
import UsProductMasterKeys from '../../../constants/locale/key/UsProductMaster'
import Namespace from '../../../constants/locale/Namespace'
import React, { useRef, useState } from 'react'
import Table from '../../../components/aciacehighway/Table'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import pathMap from '../../../paths/pathMap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'

const {
  filter: { EQUAL, LIKE }
} = constants

function TablePage(props) {
  const { history, showNotification } = props
  const tableRef = useRef(null)
  const [alertDialog, setAlertDialog] = useState({ open: false, products: null })
  const theme = useTheme()
  const { createRecord, deleteRecord } = useServices()
  const { translate } = useTranslation(Namespace.US_PRODUCT_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const docRefNo = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.DOC_REF_NO
    )
    const productId = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.PRODUCT_ID
    )
    const manufacturingPartNo = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.MANUFACTURING_PART_NO
    )
    const usorcaMaster = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.USORCA_MASTER
    )
    const countryOfOrigin = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.COUNTRY_OF_ORIGIN
    )
    const cloneButton = translate(
      Namespace.ACI_AIR,
      UsProductMasterKeys.CLONE_BUTTON
    )

    return {
      docRefNo,
      productId,
      manufacturingPartNo,
      usorcaMaster,
      countryOfOrigin,
      cloneButton,
    }
  }

  const columns = [
    {
      field: 'docRefNo',
      sortKey: 'docRefNo',
      title: translatedTextsObject.docRefNo
    },
    {
      field: 'productId',
      sortKey: 'productId',
      title: translatedTextsObject.productId
    },
    {
      field: 'manufacturingPartNo',
      sortKey: 'manufacturingPartNo',
      title: translatedTextsObject.manufacturingPartNo
    },
    {
      field: 'usorcaMaster',
      sortKey: 'usorcaMaster',
      title: translatedTextsObject.usorcaMaster
    },
    {
      field: 'countryOfOrigin',
      sortKey: 'countryOfOrigin',
      title: translatedTextsObject.countryOfOrigin
    },
  ]

  const filters = [
    {
      label: translatedTextsObject.docRefNo,
      type: 'textfield',
      name: 'docRefNo',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.productId,
      type: 'textfield',
      name: 'productId',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.manufacturingPartNo,
      type: 'textfield',
      name: 'manufacturingPartNo',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.countryOfOrigin,
      type: 'textfield',
      name: 'countryOfOrigin',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.usorcaMaster,
      type: 'select',
      name: 'usorcaMaster',
      operatorType: EQUAL,
      options: [
        {
          fullWidth: true,
          label: 'US',
          value: 'US',
          filterValue: {
            value: 'US'
          }
        },
        {
          fullWidth: true,
          label: 'CA',
          value: 'CA',
          filterValue: {
            value: 'CA'
          }
        },
      ]
    },
  ]

  function handleCloneProduct(data) {
    createRecord.execute(
      UsProductMasterApiUrls.CLONE,
      data,
      (data) => history.push(pathMap.US_PRODUCT_MASTER_ADD_VIEW, data),
      (error) => console.log(error)
    )
  }

  function handleDeleteProducts() {
    if (!_.isEmpty(alertDialog.products)) {
      deleteRecord.execute(
        UsProductMasterApiUrls.DELETE,
        alertDialog.products,
        () => {
          showNotification('success', 'Delete succeeded')
          setAlertDialog({ open: false, products: null })

          if (tableRef.current?.performRefresh) {
            tableRef.current.performRefresh()
          }
        },
        (error) => console.log(error)
      )
    }
  }

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.US_PRODUCT_MASTER_ADD_VIEW)
            },
            label: 'Create product'
          }
        ]}
        columns={columns}
        compact
        customRowActions={[
          {
            iconButtonProps: {
              icon: ['fal', 'pen'],
              style: { color: theme.palette.primary.main }
            },
            onClick: (rowData) =>
              history.push(`${pathMap.US_PRODUCT_MASTER}/edit/${rowData.id}`),
            tooltip: () => 'Edit'
          }
        ]}
        exportData={{ url: UsProductMasterApiUrls.EXPORT }}
        fetch={{ url: UsProductMasterApiUrls.GET }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        filters={filters}
        onRowClick={(rowData) => history.push(`${pathMap.US_PRODUCT_MASTER}/view/${rowData.id}`)}
        rowActions={[
          {
            icon: <FontAwesomeIcon icon={['fal', 'copy']} />,
            label: translatedTextsObject.cloneButton,
            onClick: handleCloneProduct
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: 'Delete',
            onClick: (rowData) =>
              setAlertDialog({ open: true, products: rowData }),
          },
        ]}
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
        persistSettings
        tableRef={tableRef}
      />
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={alertDialog.open}
        onClose={() => setAlertDialog({ open: false, products: null })}
        onCancel={() => setAlertDialog({ open: false, products: null })}
        onConfirm={handleDeleteProducts}
        title='Delete'
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
    </>
  )
}

export default TablePage
