import makeValidationSchema from './ImportDeclFormMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../../../constants/locale/key/NBCICanadaInvoice'
import { Box, Card, Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import ProductDetailsSection from './ProductDetailsSection'
import ContainerDetailsSection from './ContainerDetailsSection'

const {
  form: {
    field: { CngTextField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  transactionNo: '',
  containerNo1: '',
  containerNo2: '',
  containerNo3: '',
  flightNo: '',
  trailerNo: '',
  isDairy: '',
  isProcessFoodVeg: '',
  isHoney: '',
  isMapleProd: '',
  isSeeds: '',
  isFeed: '',
  isFortizer: '',
  prodDetails: [],
  contDetails: [],
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { setValue, watch } = useFormContext()

  const isDairy = watch('isDairy')
  const isProcessFoodVeg = watch('isProcessFoodVeg')
  const isHoney = watch('isHoney')
  const isMapleProd = watch('isMapleProd')
  const isSeeds = watch('isSeeds')
  const isFeed = watch('isFeed')
  const isFortizer = watch('isFortizer')
  
  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const transactionNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ImportDeclForm.TRANSACTION_NO
    )
    const containerNo1 = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ImportDeclForm.CONTAINER_NO1
    )
    const containerNo2 = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ImportDeclForm.CONTAINER_NO2
    )
    const containerNo3 = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ImportDeclForm.CONTAINER_NO3
    )
    const flightNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ImportDeclForm.FLIGHT_NO
    )
    const trailerNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ImportDeclForm.TRAILER_NO
    )
    const isDairy = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ImportDeclForm.IS_DAIRY
    )
    const isProcessFoodVeg = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ImportDeclForm.IS_PROCESS_FOOD_VEG
    )
    const isHoney = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ImportDeclForm.IS_HONEY
    )
    const isMapleProd = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ImportDeclForm.IS_MAPLE_PROD
    )
    const isSeeds = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ImportDeclForm.IS_SEEDS
    )
    const isFeed = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ImportDeclForm.IS_FEED
    )
    const isFortizer = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ImportDeclForm.IS_FORTIZER
    )

    return {
      transactionNo,
      containerNo1,
      containerNo2,
      containerNo3,
      flightNo,
      trailerNo,
      isDairy,
      isProcessFoodVeg,
      isHoney,
      isMapleProd,
      isSeeds,
      isFeed,
      isFortizer,
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card variant='outlined'>
          <Box px={2} py={1}>
            <Grid container spacing={1}>
              <CngGridItem xs='auto' shouldHide={shouldHideMap?.isDairy}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isDairy === 'Y'}
                      onChange={(event) => setValue('isDairy', event.target.checked ? 'Y' : 'N', { shouldDirty: true })}
                      color='primary'
                      size='small'
                    />
                  }
                  label={<Typography variant='body2'>{translatedTextsObject.isDairy}</Typography>}
                />
              </CngGridItem>
              <CngGridItem xs='auto' shouldHide={shouldHideMap?.isProcessFoodVeg}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isProcessFoodVeg === 'Y'}
                      onChange={(event) => setValue('isProcessFoodVeg', event.target.checked ? 'Y' : 'N', { shouldDirty: true })}
                      color='primary'
                      size='small'
                    />
                  }
                  label={<Typography variant='body2'>{translatedTextsObject.isProcessFoodVeg}</Typography>}
                />
              </CngGridItem>
              <CngGridItem xs='auto' shouldHide={shouldHideMap?.isHoney}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isHoney === 'Y'}
                      onChange={(event) => setValue('isHoney', event.target.checked ? 'Y' : 'N', { shouldDirty: true })}
                      color='primary'
                      size='small'
                    />
                  }
                  label={<Typography variant='body2'>{translatedTextsObject.isHoney}</Typography>}
                />
              </CngGridItem>
              <CngGridItem xs='auto' shouldHide={shouldHideMap?.isMapleProd}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isMapleProd === 'Y'}
                      onChange={(event) => setValue('isMapleProd', event.target.checked ? 'Y' : 'N', { shouldDirty: true })}
                      color='primary'
                      size='small'
                    />
                  }
                  label={<Typography variant='body2'>{translatedTextsObject.isMapleProd}</Typography>}
                />
              </CngGridItem>
              <CngGridItem xs='auto' shouldHide={shouldHideMap?.isSeeds}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isSeeds === 'Y'}
                      onChange={(event) => setValue('isSeeds', event.target.checked ? 'Y' : 'N', { shouldDirty: true })}
                      color='primary'
                      size='small'
                    />
                  }
                  label={<Typography variant='body2'>{translatedTextsObject.isSeeds}</Typography>}
                />
              </CngGridItem>
              <CngGridItem xs='auto' shouldHide={shouldHideMap?.isFeed}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isFeed === 'Y'}
                      onChange={(event) => setValue('isFeed', event.target.checked ? 'Y' : 'N', { shouldDirty: true })}
                      color='primary'
                      size='small'
                    />
                  }
                  label={<Typography variant='body2'>{translatedTextsObject.isFeed}</Typography>}
                />
              </CngGridItem>
              <CngGridItem xs='auto' shouldHide={shouldHideMap?.isFortizer}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isFortizer === 'Y'}
                      onChange={(event) => setValue('isFortizer', event.target.checked ? 'Y' : 'N', { shouldDirty: true })}
                      color='primary'
                      size='small'
                    />
                  }
                  label={<Typography variant='body2'>{translatedTextsObject.isFortizer}</Typography>}
                />
              </CngGridItem>
            </Grid>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.transactionNo}>
            <CngTextField
              name='transactionNo'
              label={translatedTextsObject.transactionNo}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.flightNo}>
            <CngTextField
              name='flightNo'
              label={translatedTextsObject.flightNo}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.trailerNo}>
            <CngTextField
              name='trailerNo'
              label={translatedTextsObject.trailerNo}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.containerNo1}>
            <CngTextField
              name='containerNo1'
              label={translatedTextsObject.containerNo1}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.containerNo2}>
            <CngTextField
              name='containerNo2'
              label={translatedTextsObject.containerNo2}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.containerNo3}>
            <CngTextField
              name='containerNo3'
              label={translatedTextsObject.containerNo3}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ProductDetailsSection />
      </Grid>
      <Grid item xs={12}>
        <ContainerDetailsSection />
      </Grid>
    </Grid>
  )
}

const ImportDeclFormFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default ImportDeclFormFormProperties
