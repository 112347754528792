import React, { useContext } from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../../../constants/locale/key/NBCICanadaInvoice'
import { CAInvoiceLookupsContext } from '../../lookups/CAInvoiceLookupsContext'
import CngSection from '../../../../components/cngcomponents/CngSection'
import CngField from '../../../../components/cngcomponents/CngField'
import TinyChip from '../../../../components/aciacehighway/TinyChip'
import { Divider, Grid, Typography, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import moment from 'moment'

function InvoiceDetailsViewContent(props) {
  const { invoiceDetail } = props

  const { getLookupValue, getCountryStateLabel } = useContext(CAInvoiceLookupsContext)
  const theme = useTheme()
  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const invoiceDetails = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.TITLE
    )
    const subProbillNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.SUB_PROBILL_NO
    )
    const partNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.PART_NO
    )
    const productId = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.PRODUCT_ID
    )
    const hsCode = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.HS_CODE
    )
    const countryOfOrigin = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.COUNTRY_OF_ORIGIN
    )
    const stateOfOrigin = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.STATE_OF_ORIGIN
    )
    const qtyInvoiced = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.QTY_INVOICED
    )
    const qtyUom = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.QTY_UOM
    )
    const unitPrice = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.UNIT_PRICE
    )
    const description = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.DESCRIPTION
    )
    const purchaseOrderNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.PURCHASE_ORDER_NO
    )
    const noOfPkgs = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.NO_OF_PKGS
    )
    const noOfPkgsUom = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.NO_OF_PKGS_UOM
    )
    const lineItemTotal = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.LINE_ITEM_TOTAL
    )
    const netWeight = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.NET_WEIGHT
    )
    const netWeightUom = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.NET_WEIGHT_UOM
    )
    const grossWeight = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.GROSS_WEIGHT
    )
    const grossWeightUom = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.GROSS_WEIGHT_UOM
    )
    const lineAmount = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.LINE_AMOUNT
    )
    const naftaCriteria = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.NAFTA_CRITERIA
    )
    const dateRangeFrom = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.DATE_RANGE_FROM
    )
    const dateRangeTo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.DATE_RANGE_TO
    )
    const includeProdNafata = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.INCLUDE_PROD_NAFATA
    )
    const isProducer = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.IS_PRODUCER
    )
    const notAProducerOfGoods = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.NOT_A_PRODUCER_OF_GOODS
    )
    const netCost = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.NET_COST
    )
    const servicePromotion = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ServicePromotion.TITLE
    )
    const additionDeductionInd = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ServicePromotion.ADDITION_DEDUCTION_IND
    )
    const additionDeductionCode = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ServicePromotion.ADDITION_DEDUCTION_CODE
    )
    const amountRatePercent = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ServicePromotion.AMOUNT_RATE_PERCENT
    )
    const additionDeductionType = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ServicePromotion.ADDITION_DEDUCTION_TYPE
    )
    const explanation = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ServicePromotion.EXPLANATION
    )

    return {
      invoiceDetails,
      subProbillNo,
      partNo,
      description,
      productId,
      hsCode,
      countryOfOrigin,
      stateOfOrigin,
      qtyInvoiced,
      qtyUom,
      unitPrice,
      purchaseOrderNo,
      netWeight,
      netWeightUom,
      grossWeight,
      grossWeightUom,
      lineItemTotal,
      lineAmount,
      noOfPkgs,
      noOfPkgsUom,
      includeProdNafata,
      isProducer,
      naftaCriteria,
      dateRangeFrom,
      dateRangeTo,
      notAProducerOfGoods,
      netCost,
      servicePromotion,
      additionDeductionInd,
      additionDeductionCode,
      amountRatePercent,
      additionDeductionType,
      explanation,
    }
  }

  if (!invoiceDetail) return null

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngSection title='Line item information'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={translatedTextsObject.subProbillNo}>
                    {invoiceDetail.subProbillNo}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={translatedTextsObject.partNo}>
                    {invoiceDetail.partNo}
                  </CngField>
                </Grid>
                <Grid item xs={12} md={3}>
                  <CngField label={translatedTextsObject.description}>
                    {invoiceDetail.description}
                  </CngField>
                </Grid>
                <Grid item xs={12} md={3}>
                  <CngField label={translatedTextsObject.productId}>
                    {invoiceDetail.productId}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={translatedTextsObject.hsCode}>
                    {invoiceDetail.hsCode}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={translatedTextsObject.countryOfOrigin}>
                    {getLookupValue('country', invoiceDetail.countryOfOrigin)}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={translatedTextsObject.stateOfOrigin}>
                    {getCountryStateLabel(invoiceDetail.countryOfOrigin, invoiceDetail.stateOfOrigin)}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={translatedTextsObject.qtyInvoiced}>
                    {invoiceDetail.qtyInvoiced}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={translatedTextsObject.qtyUom}>
                    {getLookupValue('pkgsType', invoiceDetail.qtyUom)}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={translatedTextsObject.unitPrice}>
                    {invoiceDetail.unitPrice}
                  </CngField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CngField label={translatedTextsObject.purchaseOrderNo}>
                    {invoiceDetail.purchaseOrderNo}
                  </CngField>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <CngSection title='Weight Information'>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={translatedTextsObject.netWeight}>
                      {invoiceDetail.netWeight}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={translatedTextsObject.netWeightUom}>
                      <Typography component='div' variant='inherit'>
                        {getLookupValue('weightUom', invoiceDetail.netWeightUom)}
                        <TinyChip label={invoiceDetail.netWeightUom} variant='outlined' />
                      </Typography>
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={translatedTextsObject.grossWeight}>
                      {invoiceDetail.grossWeight}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={translatedTextsObject.grossWeightUom}>
                      <Typography component='div' variant='inherit'>
                        {getLookupValue('weightUom', invoiceDetail.grossWeightUom)}
                        <TinyChip label={invoiceDetail.grossWeightUom} variant='outlined' />
                      </Typography>
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={translatedTextsObject.lineItemTotal}>
                      {invoiceDetail.lineItemTotal}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={translatedTextsObject.noOfPkgs}>
                      {invoiceDetail.noOfPkgs}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={translatedTextsObject.noOfPkgsUom}>
                      {getLookupValue('noOfPkgsUom', invoiceDetail.noOfPkgsUom)}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={translatedTextsObject.netCost}>
                      {invoiceDetail.netCost}
                    </CngField>
                  </Grid>
                </Grid>
              </CngSection>
            </Grid>
            <Grid item xs={12}>
              <CngSection title='CUSMA related information'>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs='auto'>
                        <FontAwesomeIcon
                          color={
                            invoiceDetail.includeProdNafata === 'Y'
                              ? theme.palette.primary.main
                              : theme.palette.error.main
                          }
                          icon={[
                            'fal',
                            invoiceDetail.includeProdNafata === 'Y'
                              ? 'check'
                              : 'ban'
                          ]}
                        />
                      </Grid>
                      <Grid item xs>
                        <Typography variant='body2'>
                          {translatedTextsObject.includeProdNafata} - preference
                          criteria&nbsp;
                          <Typography color='primary' variant='inherit'>
                            {invoiceDetail.naftaCriteria}
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <CngSection title={translatedTextsObject.includeProdNafata}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={3}>
                          <CngField label={translatedTextsObject.dateRangeFrom}>
                            {invoiceDetail.dateRangeFrom &&
                              moment(invoiceDetail.dateRangeFrom).format('D MMM YYYY')}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <CngField label={translatedTextsObject.dateRangeTo}>
                            {invoiceDetail.dateRangeTo &&
                              moment(invoiceDetail.dateRangeTo).format('D MMM YYYY')}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Grid container spacing={2}>
                            <Grid item xs='auto'>
                              <FontAwesomeIcon
                                color={
                                  invoiceDetail.isProducer === 'Y'
                                    ? theme.palette.primary.main
                                    : theme.palette.error.main
                                }
                                icon={[
                                  'fal',
                                  invoiceDetail.isProducer === 'Y'
                                    ? 'check'
                                    : 'ban'
                                ]}
                              />
                            </Grid>
                            <Grid item xs>
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <Typography variant='body2'>
                                    {translatedTextsObject.isProducer}
                                  </Typography>
                                </Grid>
                                {invoiceDetail.isProducer === 'Y' && invoiceDetail.notAProducerOfGoods && (
                                  <Grid item xs={12}>
                                    <Typography variant='body2'>
                                      {getLookupValue(
                                        'notAProducerOfGoods',
                                        invoiceDetail.notAProducerOfGoods
                                      )}
                                    </Typography>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CngSection>
                  </Grid>
                </Grid>
              </CngSection>
            </Grid>
            <Grid item xs={12}>
              <CngSection title={translatedTextsObject.servicePromotion}>
                {!_.isEmpty(invoiceDetail.servicePromotion) ? (
                  <Grid container spacing={2}>
                    {invoiceDetail.servicePromotion.map((item, index) => (
                      <React.Fragment key={item.id}>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={3}>
                              <CngField label={translatedTextsObject.additionDeductionInd}>
                                {getLookupValue('additionDeductionInd', item.additionDeductionInd)}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <CngField label={translatedTextsObject.additionDeductionCode}>
                                {getLookupValue('additionDeductionCode', item.additionDeductionCode)}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <CngField label={translatedTextsObject.amountRatePercent}>
                                {item.amountRatePercent}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <CngField label={translatedTextsObject.additionDeductionType}>
                                {getLookupValue('additionDeductionType', item.additionDeductionType)}
                              </CngField>
                            </Grid>
                            <Grid item xs={12}>
                              <CngField label={translatedTextsObject.explanation}>
                                {item.explanation}
                              </CngField>
                            </Grid>
                          </Grid>
                        </Grid>
                        {index + 1 < invoiceDetail.servicePromotion.length && (
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        )}
                      </React.Fragment>
                    ))}
                  </Grid>
                ) : (
                  <Typography variant='body2' color='textSecondary'>
                    No records to display
                  </Typography>
                )}
              </CngSection>
            </Grid>
          </Grid>
        </CngSection>
      </Grid>
    </Grid>
  )
}

export default InvoiceDetailsViewContent
