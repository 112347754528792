import makeValidationSchema from './ServicePromotionMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../../constants/locale/key/NBCICanadaInvoice'
import Grid from '@material-ui/core/Grid'

const {
  form: {
    field: { CngSelectField, CngTextField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  additionDeductionInd: '',
  additionDeductionCode: '',
  amountRatePercent: '',
  additionDeductionType: '',
  explanation: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const additionDeductionInd = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ServicePromotion.ADDITION_DEDUCTION_IND
    )
    const additionDeductionCode = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ServicePromotion.ADDITION_DEDUCTION_CODE
    )
    const amountRatePercent = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ServicePromotion.AMOUNT_RATE_PERCENT
    )
    const additionDeductionType = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ServicePromotion.ADDITION_DEDUCTION_TYPE
    )
    const explanation = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ServicePromotion.EXPLANATION
    )

    return {
      additionDeductionInd,
      additionDeductionCode,
      amountRatePercent,
      additionDeductionType,
      explanation,
    }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.additionDeductionInd}>
        <CngSelectField
          name='additionDeductionInd'
          label={translatedTextsObject.additionDeductionInd}
          disabled={disabled}
          size='small'
          items={[
            { text: 'Addition', value: 'C' },
            { text: 'Deduction', value: 'A' }
          ]}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.additionDeductionCode}>
        <CngSelectField
          name='additionDeductionCode'
          label={translatedTextsObject.additionDeductionCode}
          disabled={disabled}
          size='small'
          items={[
            { text: 'Promotional Allowance', value: 'F800' },
            { text: 'Discount', value: 'C310' },
            { text: 'Service Charge', value: 'G740' }
          ]}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.amountRatePercent}>
        <CngTextField
          name='amountRatePercent'
          label={translatedTextsObject.amountRatePercent}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.additionDeductionType}>
        <CngSelectField
          name='additionDeductionType'
          label={translatedTextsObject.additionDeductionType}
          disabled={disabled}
          size='small'
          items={[
            { text: 'Amount', value: 'A' },
            { text: 'Percent', value: 'P' }
          ]}
        />
      </CngGridItem>
      <CngGridItem xs={12} shouldHide={shouldHideMap?.explanation}>
        <CngTextField
          name='explanation'
          label={translatedTextsObject.explanation}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
    </Grid>
  )
}

const ServicePromotionFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default ServicePromotionFormProperties
