import makeValidationSchema from './ContDetailsMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../../../constants/locale/key/NBCICanadaInvoice'
import Grid from '@material-ui/core/Grid'

const {
  form: {
    field: { CngTextField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  typeNetContent: '',
  quantity: '',
  labelNo: '',
  importPurpose: '',
  documentation: '',
  registrationNo: '',
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const typeNetContent = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ContDetails.TYPE_NET_CONTENT
    )
    const quantity = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ContDetails.QUANTITY
    )
    const labelNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ContDetails.LABEL_NO
    )
    const importPurpose = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ContDetails.IMPORT_PURPOSE
    )
    const documentation = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ContDetails.DOCUMENTATION
    )
    const registrationNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ContDetails.REGISTRATION_NO
    )

    return {
      typeNetContent,
      quantity,
      labelNo,
      importPurpose,
      documentation,
      registrationNo,
    }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} lg={4} shouldHide={shouldHideMap?.typeNetContent}>
        <CngTextField
          name='typeNetContent'
          label={translatedTextsObject.typeNetContent}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} lg={4} shouldHide={shouldHideMap?.quantity}>
        <CngTextField
          name='quantity'
          label={translatedTextsObject.quantity}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} lg={4} shouldHide={shouldHideMap?.labelNo}>
        <CngTextField
          name='labelNo'
          label={translatedTextsObject.labelNo}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} lg={4} shouldHide={shouldHideMap?.importPurpose}>
        <CngTextField
          name='importPurpose'
          label={translatedTextsObject.importPurpose}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} lg={4} shouldHide={shouldHideMap?.documentation}>
        <CngTextField
          name='documentation'
          label={translatedTextsObject.documentation}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} lg={4} shouldHide={shouldHideMap?.registrationNo}>
        <CngTextField
          name='registrationNo'
          label={translatedTextsObject.registrationNo}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
    </Grid>
  )
}

const ContDetailsFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default ContDetailsFormProperties
