import makeValidationSchema from './InvoiceDetailsMakeValidationSchema'
import { components, useTranslation, constants,DateTimeFormatter } from 'cng-web-lib'
import React, { useState, useEffect } from 'react'
import Namespace from '../../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../../constants/locale/key/NBCICanadaInvoice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FileForUserGetPartyId, FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import TemplateSelectDialog from '../../../components/aciacehighway/TemplateSelectDialog'
import productMasterApiUrls from '../../../apiUrls/UsProductMasterApiUrls'
import {
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  Divider,
  IconButton,
  InputAdornment
} from '@material-ui/core'
import { NaStateCodeAutoCompleteField } from '../../../components/na'
import { useFormContext, useWatch } from 'react-hook-form'
import CollapsibleSection from '../../../components/ui/CollapsibleSection'
import RadioButtonGroup from '../../../components/aciacehighway/RadioButtonGroup'
import CngSection from '../../../components/cngcomponents/CngSection'
import ServicePromotionOtherChargesSection from './ServicePromotionOtherChargesSection'
import { NaNbciCountryCodeAutocompleteField, NaNbciProductMasterAutoCompleteField } from 'src/components/na/autocomplete/codemaintenance'

const {
  form: {
    field: {
      CngCountryAutocompleteField,
      CngSelectField,
      CngTextField,
      CngDateField,
      CngCheckboxField
    }
  },
  CngGridItem
} = components
const {
  filter: { EQUAL, IN, LIKE }
} = constants
const DEFAULT_INITIAL_VALUES = Object.freeze({
  subProbillNo: '',
  productId: '',
  partNo: '',
  hsCode: '',
  countryOfOrigin: '',
  stateOfOrigin: '',
  qtyInvoiced: '',
  qtyUom: '',
  unitPrice: '',
  description: '',
  purchaseOrderNo: '',
  noOfPkgs: '',
  noOfPkgsUom: '',
  lineItemTotal: '',
  netWeight: '',
  netWeightUom: '',
  grossWeight: '',
  grossWeightUom: '',
  lineAmount: "",
  naftaCriteria: 'A',
  dateRangeFrom: '',
  dateRangeTo: '',
  netCost: '',
  isProducer: 'N',
  includeProdNafata: 'N',
  notAProducerOfGoods: '',
  servicePromotion: [],
  saveFlag: false,
  productId: ""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { setValue, getValues, watch, trigger, reset } = useFormContext()
  const includeProdNafata = watch('includeProdNafata')
  const notAProducerOfGoods = watch('notAProducerOfGoods')
  const isProducer = watch('isProducer')
  const [templateDialog, setTemplateDialog] = useState(false)
  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()
  const saveFlag = watch('saveFlag');
  const servicePromotion = useWatch({ name: 'servicePromotion' });

  const lineItemTotalWatch = getValues('lineItemTotal')
  const lineitemTotalFloat = parseFloat(lineItemTotalWatch)

  const {
    naftaCriteria,
    countryOfOrigin
  } = useWatch()

  useEffect(() => {
    const calculateTotal = () => {
      let totalValAdd = 0;
      let totalValDedd = 0;
      if(servicePromotion){
      servicePromotion.forEach((obj) => {
        if (obj.additionDeductionType == "P") {
          if (obj.additionDeductionInd == "C") {
              var divisor = obj.amountRatePercent/100;  
              var actualAmount = lineItemTotalWatch * (divisor);
              totalValAdd= parseFloat(totalValAdd) + parseFloat(actualAmount);
              }else if (obj.additionDeductionInd == "A") {
                var divisor = obj.amountRatePercent/100;  
                var actualAmount = lineItemTotalWatch * (divisor);
              totalValDedd=parseFloat(totalValDedd)+parseFloat(actualAmount);
             }
             } else if (obj.additionDeductionType == "A"){
             if (obj.additionDeductionInd == "C") {
           totalValAdd = parseFloat(totalValAdd)+parseFloat(obj.amountRatePercent);
             } else if (obj.additionDeductionInd == "A") {
           totalValDedd = parseFloat(totalValDedd)+parseFloat(obj.amountRatePercent);    
         }
         }
      });
      const Sum=parseFloat(lineItemTotalWatch)+parseFloat(totalValAdd)-parseFloat(totalValDedd);      
      setValue('lineAmount', Sum , { shouldDirty: true })
      console.log(" line amount:" + Sum );
    };
  }
    calculateTotal();
  }, [lineitemTotalFloat, servicePromotion]);
  const columns = [
    {
      field: 'productId',
      title: translatedTextsObject.productId
    },
    {
      field: 'itemDescription',
      title: translatedTextsObject.productDescription,
    },
    {
      field: 'manufacturingPartNo',
      title: translatedTextsObject.partNumber
    },
    {
      field: 'hsCode',
      title: translatedTextsObject.hsCode,
    }

  ]

  const sortOptions = [
    {
      label: translatedTextsObject.productId,
      value: 'productId'
    }
  ]

  function handleApplyTemplate(template) {
    if (template) {
      const {
        productId,
        manufacturingPartNo,
        hsCode,
        countryOfOrigin,
        uom,
        unitPrice,
        itemDescription,
        naftaCriteria,
        dateRangeFrom,
        dateRangeTo,
        netCost,
        isProducer,
        includeProduct
      } = template


      setValue('productId', productId, { shouldDirty: true })
      setValue('partNo', manufacturingPartNo || '', { shouldDirty: true })
      setValue('hsCode', hsCode || '', { shouldDirty: true })
      setValue('countryOfOrigin', countryOfOrigin || '', { shouldDirty: true })
      setValue('qtyUom', uom || '', { shouldDirty: true })
      setValue('unitPrice', unitPrice || '', { shouldDirty: true })
      setValue('description', itemDescription || '', { shouldDirty: true })
      setValue('naftaCriteria', naftaCriteria || '', { shouldDirty: true })
      setValue('includeProdNafata', includeProduct || '', { shouldDirty: true })
      if (dateRangeTo) {
        const  valuedateFrom  = dateRangeTo;
        const partsDateFrom = valuedateFrom.split('/');
        if (partsDateFrom.length === 3) {
          // parts[2] is the year, parts[1] is the month, parts[0] is the day
          const formattedDateRangeFrom = `${partsDateFrom[2]}-${partsDateFrom[1]}-${partsDateFrom[0]}`;
          setValue( 'dateRangeFrom',
          DateTimeFormatter.toClientDate(formattedDateRangeFrom),{ shouldDirty: true })
        }  else{
          setValue( 'dateRangeFrom', '',{ shouldDirty: true })
        }
        const value = dateRangeTo;
        const parts = value.split('/');
        if (parts.length === 3) {
          // parts[2] is the year, parts[1] is the month, parts[0] is the day
          const formattedDateRangeTo = `${parts[2]}-${parts[1]}-${parts[0]}`;
          setValue( 'dateRangeTo',
           DateTimeFormatter.toClientDate(formattedDateRangeTo),{ shouldDirty: true })
        }  else{
          setValue( 'dateRangeTo', '',{ shouldDirty: true })
        }
      }

      setValue('netCost', netCost || '', { shouldDirty: true })
      setValue('isProducer', isProducer || '', { shouldDirty: true })
      trigger()
    } else {
      reset()
    }
  }
  function makeTranslatedTextsObject() {
    const invoiceDetails = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.TITLE
    )
    const subProbillNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.SUB_PROBILL_NO
    )
    const partNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.PART_NO
    )
    const productId = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.PRODUCT_ID
    )
    const hsCode = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.HS_CODE
    )
    const countryOfOrigin = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.COUNTRY_OF_ORIGIN
    )
    const stateOfOrigin = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.STATE_OF_ORIGIN
    )
    const qtyInvoiced = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.QTY_INVOICED
    )
    const qtyUom = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.QTY_UOM
    )
    const unitPrice = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.UNIT_PRICE
    )
    const description = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.DESCRIPTION
    )
    const purchaseOrderNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.PURCHASE_ORDER_NO
    )
    const noOfPkgs = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.NO_OF_PKGS
    )
    const noOfPkgsUom = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.NO_OF_PKGS_UOM
    )
    const lineItemTotal = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.LINE_ITEM_TOTAL
    )
    const netWeight = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.NET_WEIGHT
    )
    const netWeightUom = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.NET_WEIGHT_UOM
    )
    const grossWeight = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.GROSS_WEIGHT
    )
    const grossWeightUom = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.GROSS_WEIGHT_UOM
    )
    const lineAmount = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.LINE_AMOUNT
    )
    const naftaCriteria = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.NAFTA_CRITERIA
    )
    const dateRangeFrom = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.DATE_RANGE_FROM
    )
    const dateRangeTo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.DATE_RANGE_TO
    )
    const includeProdNafata = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.INCLUDE_PROD_NAFATA
    )
    const isProducer = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.IS_PRODUCER
    )
    const notAProducerOfGoods = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.NOT_A_PRODUCER_OF_GOODS
    )
    const netCost = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.NET_COST
    )
    const autoFill = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.AUTOFILL
    )
    const productDescription = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.PRODUCT_DESCRIPTION
    )
    const partNumber = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.PART_NUMBER
    )
    const saveFlag = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.SAVE_FLAG
    )

    return {
      invoiceDetails,
      subProbillNo,
      partNo,
      description,
      productId,
      hsCode,
      countryOfOrigin,
      stateOfOrigin,
      qtyInvoiced,
      qtyUom,
      unitPrice,
      purchaseOrderNo,
      netWeight,
      netWeightUom,
      grossWeight,
      grossWeightUom,
      lineItemTotal,
      lineAmount,
      noOfPkgs,
      noOfPkgsUom,
      includeProdNafata,
      isProducer,
      naftaCriteria,
      dateRangeFrom,
      dateRangeTo,
      notAProducerOfGoods,
      netCost,
      autoFill,
      productId,
      productDescription,
      partNumber,
      hsCode,
      saveFlag
    }
  }

  function handleChangeIncludeProdNafta(event) {
    setValue('includeProdNafata', event.target.checked ? 'Y' : 'N')

    if (!event.target.checked) {
      setValue('dateRangeFrom', '')
      setValue('dateRangeTo', '')
      setValue('isProducer', 'N')
      setValue('notAProducerOfGoods', '')
    }
  }

  const handleInputChange = (e) => {
    let inputValue = e.target.value;

    if (inputValue.startsWith('.')) {
      inputValue = '0' + inputValue;
    }

    setValue('unitPrice', inputValue)
  };

  return (
    <>
      <CngSection
        title={translatedTextsObject.invoiceDetails}
        subheader={
          <Typography color='textSecondary' variant='caption'>
            <Typography variant='inherit' color='error'>*</Typography>
          Mandatory fields
        </Typography>
        }
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid container spacing={1}>
                <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.saveFlag}>
                  <CngCheckboxField
                    label={
                      <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                        {translatedTextsObject.saveFlag}
                      </Typography>
                    }
                    name='saveFlag'
                    disabled={disabled}
                    onChange={(e) => {
                      setValue('saveFlag', e.target.checked)

                      if (!e.target.checked) {
                        setValue("productId", "")
                      }
                    }}
                    size='small'
                    style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm shouldHide={shouldHideMap?.productId}>
                  <CngTextField
                    name="productId"
                    inputProps={{
                      style: { textTransform: 'uppercase' },
                      maxLength: 35
                    }}
                    placeholder={translatedTextsObject.productId}
                    disabled={!saveFlag || disabled}
                    onChange={(e) => {
                      setValue("productId", e.target.value.toUpperCase(), { shouldValidate: true })
                    }}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm='auto'>
                  <Divider orientation='vertical' />
                </CngGridItem>
                <CngGridItem xs={12} sm={4}>
                  <NaNbciProductMasterAutoCompleteField
                    name="productDropDown"
                    label={translatedTextsObject.autoFill}
                    disabled={disabled}
                    onChange={(_, options) => handleApplyTemplate(options.data)}
                    size='small'
                    fullWidth
                    disableClearable
                    textFieldProps={{
                      InputProps: {
                        customEndAdornment: () => (
                          <InputAdornment position='end' style={{ marginTop: -16 }}>
                            <IconButton onClick={(event) => {
                              event.stopPropagation()
                              setTemplateDialog(true)
                            }}>
                              <Box display='flex' alignItems='center' justifyContent='center' width={16} height={16}>
                                <FontAwesomeIcon icon={['fal', 'money-check-edit']} size='xs' />
                              </Box>
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    }}
                    forcePopupIcon={false}
                    lookupProps={{
                      filters: [{
                        field: 'partyId',
                        operator: EQUAL,
                        value: FileForUserGetPartyId()
                      },
                      { field: 'usorcaMaster', operator: EQUAL, value: 'CA' }
                      ]
                    }}
                  />
                </CngGridItem>
              </Grid>
              <CngGridItem xs={12} md={6} lg={3} shouldHide={shouldHideMap?.subProbillNo}>
                <CngTextField
                  name='subProbillNo'
                  label={translatedTextsObject.subProbillNo}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} md={6} lg={3} shouldHide={shouldHideMap?.partNo}>
                <CngTextField
                  name='partNo'
                  label={translatedTextsObject.partNo}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} lg={6} shouldHide={shouldHideMap?.description}>
                <CngTextField
                  name='description'
                  label={translatedTextsObject.description}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.productId}>
                <CngTextField
                  name='productId'
                  label={translatedTextsObject.productId}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.hsCode}>
                <CngTextField
                  name='hsCode'
                  label={translatedTextsObject.hsCode}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.countryOfOrigin}>
                <NaNbciCountryCodeAutocompleteField
                  name='countryOfOrigin'
                  label={translatedTextsObject.countryOfOrigin}
                  disabled={disabled}
                  size='small'
                  key={countryOfOrigin}
                  lookupProps={{
                    label: (record) => `${record.code};${record.descriptionEn}`
                  }}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.stateOfOrigin}>
                <NaStateCodeAutoCompleteField
                  name='stateOfOrigin'
                  label={translatedTextsObject.stateOfOrigin}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.qtyInvoiced}>
                <CngTextField
                  name='qtyInvoiced'
                  label={translatedTextsObject.qtyInvoiced}
                  disabled={disabled}
                  size='small'
                  onChange={() => {
                    if (getValues('unitPrice') > 0 || getValues('qtyInvoiced') > 0) {
                      setValue("lineItemTotal", parseFloat((getValues('unitPrice') * getValues('qtyInvoiced')).toFixed(2)),  { shouldValidate: true })
                    }
                  }}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.qtyUom}>
                <CngSelectField
                  name='qtyUom'
                  label={translatedTextsObject.qtyUom}
                  disabled={disabled}
                  size='small'
                  items={[
                    { text: 'Carat', value: 'CD' },
                    { text: 'Centiliter', value: 'C3' },
                    { text: 'Centimeter', value: 'CM' },
                    { text: 'Cubic Centimeter', value: 'CC' },
                    { text: 'Cubic Decimeter', value: 'C8' },
                    { text: 'Cubic Meters (Net)', value: 'CO' },
                    { text: 'Curie', value: '4D' },
                    { text: 'Deciliter', value: 'DL' },
                    { text: 'Decimeter', value: 'DM' },
                    { text: 'Dozen', value: 'DZ' },
                    { text: 'Dozen Pair', value: 'DP' },
                    { text: 'Each', value: 'EA' },
                    { text: 'Gigabecquerel', value: 'G4' },
                    { text: 'Gram', value: 'GR' },
                    { text: 'Great Gross', value: 'GG' },
                    { text: 'Gross', value: 'GS' },
                    { text: 'Hectogram', value: 'HG' },
                    { text: 'Hectoliter', value: 'H4' },
                    { text: 'Hundred', value: 'HU' },
                    { text: 'Joules', value: '86' },
                    { text: 'Joule Per Kilogram', value: 'J2' },
                    { text: 'Kilogram', value: 'KG' },
                    { text: 'Kilovolt Amperes', value: 'K4' },
                    { text: 'Kilowatt', value: 'K7' },
                    { text: 'Kilowatt Hour', value: 'KH' },
                    { text: 'Liter', value: 'LT' },
                    { text: 'Millicurie', value: 'MU' },
                    { text: 'Milligram', value: 'ME' },
                    { text: 'Millimeter', value: 'MM' },
                    { text: 'Milliliter', value: 'ML' },
                    { text: 'Megabecquerel', value: '4N' },
                    { text: 'Meter', value: 'MR' },
                    { text: 'Metric Ton', value: 'MP' },
                    { text: 'Pound', value: 'LB' },
                    { text: 'Piece', value: 'PC' },
                    { text: 'Pair', value: 'PR' },
                    { text: 'Square Decimeter', value: 'D3' },
                    { text: 'Square Centimeter', value: 'SC' },
                    { text: 'Square Meter', value: 'SM' },
                    { text: 'Thousand', value: 'TH' },
                    { text: 'Watt', value: '99' }
                  ]}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.unitPrice}>
                <CngTextField
                  name='unitPrice'
                  label={translatedTextsObject.unitPrice}
                  disabled={disabled}
                  size='small'
                  onChange={(e) => {
                    handleInputChange(e);
                    if (getValues('unitPrice') > 0 || getValues('qtyInvoiced') > 0) {
                      setValue("lineItemTotal", parseFloat((getValues('unitPrice') * getValues('qtyInvoiced')).toFixed(2)), { shouldValidate: true })
                    }
                  }}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.purchaseOrderNo}>
                <CngTextField
                  name='purchaseOrderNo'
                  label={translatedTextsObject.purchaseOrderNo}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: 600 }}>
                  Weight Information
              </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.netWeight}>
                    <CngTextField
                      name='netWeight'
                      label={translatedTextsObject.netWeight}
                      disabled={disabled}
                      size='small'
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.netWeightUom}>
                    <CngSelectField
                      name='netWeightUom'
                      label={translatedTextsObject.netWeightUom}
                      disabled={disabled}
                      size='small'
                      items={[
                        { text: 'Kilograms', value: 'KG' },
                        { text: 'Pounds', value: 'LB' }
                      ]}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.grossWeight}>
                    <CngTextField
                      name='grossWeight'
                      label={translatedTextsObject.grossWeight}
                      disabled={disabled}
                      size='small'
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.grossWeightUom}>
                    <CngSelectField
                      name='grossWeightUom'
                      label={translatedTextsObject.grossWeightUom}
                      disabled={disabled}
                      size='small'
                      items={[
                        { text: 'Kilograms', value: 'KG' },
                        { text: 'Pounds', value: 'LB' }
                      ]}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.lineItemTotal}>
                    <CngTextField
                      name='lineItemTotal'
                      label={translatedTextsObject.lineItemTotal}
                      disabled={true}
                      onChange={(e) => {
                        if (getValues('unitPrice') > 0 || getValues('qtyInvoiced') > 0) {
                          setValue("lineItemTotal", getValues('unitPrice') * getValues('qtyInvoiced'), { shouldValidate: true })
                        }
                      }}
                      size='small'
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.noOfPkgs}>
                    <CngTextField
                      name='noOfPkgs'
                      label={translatedTextsObject.noOfPkgs}
                      disabled={disabled}
                      size='small'
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.noOfPkgsUom}>
                    <CngSelectField
                      name='noOfPkgsUom'
                      label={translatedTextsObject.noOfPkgsUom}
                      disabled={disabled}
                      size='small'
                      items={[
                        { text: 'Bale', value: 'BA' },
                        { text: 'Base Box', value: 'BB' },
                        { text: 'Bucket', value: 'BC' },
                        { text: 'Bag', value: 'BG' },
                        { text: 'Bulk', value: 'BN' },
                        { text: 'Barrel', value: 'BR' },
                        { text: 'Basket', value: 'BS' },
                        { text: 'Box', value: 'BX' },
                        { text: 'Carload', value: 'C4' },
                        { text: 'Case', value: 'CA' },
                        { text: 'Container', value: 'CH' },
                        { text: 'Cylinder', value: 'CL' },
                        { text: 'Crate', value: 'CP' },
                        { text: 'Carton', value: 'CT' },
                        { text: 'Drum', value: 'DR' },
                        { text: 'Envelope', value: 'EV' },
                        { text: 'Gross Barrels', value: 'GD' },
                        { text: 'Hundred Boxes', value: 'HB' },
                        { text: 'Keg', value: 'KE' },
                        { text: 'Barge', value: 'NB' },
                        { text: 'Vehicle', value: 'NV' },
                        { text: 'Piece', value: 'PC' },
                        { text: 'Pallet(Lift)', value: 'PF' },
                        { text: 'Package', value: 'PK' },
                        { text: 'Pallete/Unit Load', value: 'PL' },
                        { text: 'Rack', value: 'RA' },
                        { text: 'Reel', value: 'RE' },
                        { text: 'Roll', value: 'RL' },
                        { text: 'Sheet', value: 'SH' },
                        { text: 'Split Tanktruck', value: 'SK' },
                        { text: 'Spool', value: 'SO' },
                        { text: 'ShelfPackage', value: 'SP' },
                        { text: 'Skid', value: 'SV' },
                        { text: 'Tank', value: 'TK' },
                        { text: 'Unit', value: 'UN' }
                      ]}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.netCost}>
                    <CngSelectField
                      name='netCost'
                      label={translatedTextsObject.netCost}
                      disabled={disabled}
                      size='small'
                      items={[
                        { text: 'NC', value: 'NC' },
                        { text: 'NO', value: 'NO' }
                      ]}
                    />
                  </CngGridItem>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid alignItems='center' container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography style={{ fontWeight: 600 }}>
                      CUSMA related information
                  </Typography>
                  </Grid>
                  <CngGridItem xs={12} md={6} shouldHide={shouldHideMap?.includeProdNafata && shouldHideMap?.naftaCriteria}>
                    <Card variant='outlined'>
                      <Box padding='4px 4px 4px 8px'>
                        <Grid alignItems='center' container spacing={2}>
                          <CngGridItem xs={12} lg={6} shouldHide={shouldHideMap?.includeProdNafata}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={includeProdNafata === 'Y'}
                                  onChange={handleChangeIncludeProdNafta}
                                />
                              }
                              label={
                                <Typography color='textSecondary' variant='body2' style={{ fontWeight: 600 }}>
                                  {translatedTextsObject.includeProdNafata}
                                </Typography>
                              }
                            />
                          </CngGridItem>
                          <CngGridItem xs={12} lg={6} shouldHide={shouldHideMap?.naftaCriteria}>
                            <CngSelectField
                              name='naftaCriteria'
                              label={translatedTextsObject.naftaCriteria}
                              disabled={disabled}
                              isRequired
                              size='small'
                              key={naftaCriteria}
                              items={[
                                { text: 'A', value: 'A' },
                                { text: 'B', value: 'B' },
                                { text: 'C', value: 'C' },
                                { text: 'D', value: 'D' }
                              ]}
                            />
                          </CngGridItem>
                        </Grid>
                      </Box>
                    </Card>
                  </CngGridItem>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <CollapsibleSection  defaultExpanded={true} title='Include CUSMA product'>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.dateRangeFrom}>
                          <CngDateField
                            name='dateRangeFrom'
                            label={translatedTextsObject.dateRangeFrom}
                            disabled={disabled}
                            size='small'
                          />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.dateRangeTo}>
                          <CngDateField
                            name='dateRangeTo'
                            label={translatedTextsObject.dateRangeTo}
                            disabled={disabled}
                            size='small'
                          />
                        </CngGridItem>
                      </Grid>
                    </Grid>
                    <CngGridItem xs={12}>
                      <Card variant='outlined'>
                        <Box padding={2}>
                          <Box mb={1}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isProducer === 'Y'}
                                  onChange={(event) => setValue('isProducer', event.target.checked ? 'Y' : 'N')}
                                />
                              }
                              label={
                                <Typography style={{ fontWeight: 600 }}>
                                  {translatedTextsObject.isProducer}
                                </Typography>
                              }
                            />
                          </Box>
                          <RadioButtonGroup
                            itemsPerRow={3}
                            onChange={(event) => setValue('notAProducerOfGoods', event.target.value)}
                            options={[
                              {
                                label: (
                                  <Typography variant='body2' style={{ fontWeight: 600 }}>
                                    1. Your knowledge of whether the goods qualifies as an originating good.
                                  </Typography>
                                ),
                                value: '1'
                              },
                              {
                                label: (
                                  <Typography variant='body2' style={{ fontWeight: 600 }}>
                                    2. Your reliance on the producer\'s written representation (other than a Certificate of Origin) that the good qualifies as an originating.
                                  </Typography>
                                ),
                                value: '2'
                              },
                              {
                                label: (
                                  <Typography variant='body2' style={{ fontWeight: 600 }}>
                                    3. A completed & signed Certificate for the good, voluntarily provided to the exporter by the producer.
                                  </Typography>
                                ),
                                value: '3'
                              }
                            ]}
                            value={notAProducerOfGoods}
                          />
                        </Box>
                      </Card>
                    </CngGridItem>
                  </Grid>
                </CollapsibleSection>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ServicePromotionOtherChargesSection />
          </Grid>
        </Grid>
      </CngSection>
      <TemplateSelectDialog
        columns={columns}
        defaultSorts={[{ field: 'productId', direction: 'ASC' }]}
        fetch={{ url: productMasterApiUrls.GET }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() },
          { field: 'usorcaMaster', operator: EQUAL, value: ['CA'] }
        ]}
        onApplyTemplate={handleApplyTemplate}
        onClose={() => setTemplateDialog(false)}
        open={templateDialog}
        search={{ field: 'inputValue', operator: LIKE }} //custom field name for BE to custom search
        sortOptions={sortOptions}
        title='Template'
      />
    </>
  )
}

const InvoiceDetailsFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default InvoiceDetailsFormProperties
