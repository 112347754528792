import React, { useContext, useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../../constants/locale/key/NBCICanadaInvoice'
import { CAInvoiceLookupsContext } from '../lookups/CAInvoiceLookupsContext'
import CngField from '../../../components/cngcomponents/CngField'
import _ from 'lodash'
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  Paper,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core'

const {
  button: { CngButton },
  CngDialog
} = components

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    padding: '8px 8px 8px 16px',
    '& .MuiIconButton-root': {
      minHeight: 'auto'
    }
  },
  badge: {
    padding: 4,
    minWidth: 40,
    borderRadius: 32
  }
}))

function InvoiceSummarySection(props) {
  const { invoiceSummary } = props

  const [referenceDialog, setReferenceDialog] = useState({
    open: false,
    references: []
  })
  const { getLookupValue } = useContext(CAInvoiceLookupsContext)
  const classes = useStyles()
  const theme = useTheme()
  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const invoiceSummary = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvSummary.TITLE
    )
    const totalInvoice = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvSummary.TOTAL_INVOICE
    )
    const summaryReference = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvSummaryReference.TITLE
    )
    const noOfPkgs = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ShipmentSummary.NO_OF_PKGS
    )
    const pkgType = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ShipmentSummary.PKG_TYPE
    )
    const pkgDimLength = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ShipmentSummary.PKG_DIM_LENGTH
    )
    const pkgDimWidth = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ShipmentSummary.PKG_DIM_WIDTH
    )
    const pkgDimHeight = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ShipmentSummary.PKG_DIM_HEIGHT
    )
    const pkgDimUom = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ShipmentSummary.PKG_DIM_UOM
    )
    const commodityDescription = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ShipmentSummary.COMMODITY_DESCRIPTION
    )
    const grossWeight = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ShipmentSummary.GROSS_WEIGHT
    )
    const grossWeightUom = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ShipmentSummary.GROSS_WEIGHT_UOM
    )
    const freightClass = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ShipmentSummary.FREIGHT_CLASS
    )
    const additionDeductionInd = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvSummaryReference.ADDITION_DEDUCTION_IND
    )
    const additionDeductionCode = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvSummaryReference.ADDITION_DEDUCTION_CODE
    )
    const additionDeductionType = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvSummaryReference.ADDITION_DEDUCTION_TYPE
    )
    const amountRatePercent = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvSummaryReference.AMOUNT_RATE_PERCENT
    )
    const explanation = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvSummaryReference.EXPLANATION
    )

    return {
      invoiceSummary,
      totalInvoice,
      summaryReference,
      noOfPkgs,
      pkgType,
      pkgDimLength,
      pkgDimWidth,
      pkgDimHeight,
      pkgDimUom,
      commodityDescription,
      grossWeight,
      grossWeightUom,
      freightClass,
      additionDeductionInd,
      additionDeductionCode,
      additionDeductionType,
      amountRatePercent,
      explanation,
    }
  }

  return (
    <>
      <Card variant='outlined'>
        <Box padding={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h5' style={{ fontWeight: 600 }}>
                {translatedTextsObject.invoiceSummary}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {!_.isEmpty(invoiceSummary) ? (
                <Grid container spacing={2}>
                  {invoiceSummary.map((summary) => {
                    const { shipmentSummary, shipmentSummaryReference } = summary

                    const hasShipmentSummary = !_.isEmpty(shipmentSummary)
                    const hasSummaryReference = !_.isEmpty(shipmentSummaryReference)

                    return (
                      <Grid key={summary.id} item xs={12}>
                        <Card variant='outlined'>
                          <Box
                            bgcolor={theme.palette.background?.sectionOddBg || theme.palette.grey[200]}
                            padding={2}
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Card variant='outlined'>
                                  <Box padding={2}>
                                    {hasShipmentSummary ? (
                                      <Grid container spacing={2}>
                                        {shipmentSummary.map(
                                          (shipment, index, shipmentSummary) => (
                                            <React.Fragment key={shipment.id}>
                                              <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                  <Grid item xs={12} sm={6} md={2} xl={3}>
                                                    <CngField label={translatedTextsObject.noOfPkgs}>
                                                      {shipment.noOfPkgs}
                                                    </CngField>
                                                  </Grid>
                                                  <Grid item xs={12} sm={6} md={2} xl={3}>
                                                    <CngField label={translatedTextsObject.pkgType}>
                                                      {getLookupValue('noOfPkgsUom', shipment.pkgType)}
                                                    </CngField>
                                                  </Grid>
                                                  <Grid item xs={12} sm={4} md={2} xl={1}>
                                                    <CngField label={translatedTextsObject.pkgDimLength}>
                                                      {shipment.pkgDimLength}
                                                    </CngField>
                                                  </Grid>
                                                  <Grid item xs={12} sm={4} md={2} xl={1}>
                                                    <CngField label={translatedTextsObject.pkgDimWidth}>
                                                      {shipment.pkgDimWidth}
                                                    </CngField>
                                                  </Grid>
                                                  <Grid item xs={12} sm={4} md={2} xl={1}>
                                                    <CngField label={translatedTextsObject.pkgDimHeight}>
                                                      {shipment.pkgDimHeight}
                                                    </CngField>
                                                  </Grid>
                                                  <Grid item xs={12} md={2} xl={3}>
                                                    <CngField label={translatedTextsObject.pkgDimUom}>
                                                      {getLookupValue('pkgDimUom', shipment.pkgDimUom)}
                                                    </CngField>
                                                  </Grid>
                                                  <Grid item xs={12} md={4} xl={6}>
                                                    <CngField label={translatedTextsObject.commodityDescription}>
                                                      {shipment.commodityDescription}
                                                    </CngField>
                                                  </Grid>
                                                  <Grid item xs={6} md={2}>
                                                    <CngField label={translatedTextsObject.grossWeight}>
                                                      {shipment.grossWeight}
                                                    </CngField>
                                                  </Grid>
                                                  <Grid item xs={6} md={2}>
                                                    <CngField label={translatedTextsObject.grossWeightUOM}>
                                                      {getLookupValue('weightUom', shipment.grossWeightUOM)}
                                                    </CngField>
                                                  </Grid>
                                                  <Grid item xs={12} md={4} xl={2}>
                                                    <CngField label={translatedTextsObject.freightClass}>
                                                      {shipment.freightClass}
                                                    </CngField>
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                              {index + 1 < shipmentSummary.length && (
                                                <Grid item xs={12}>
                                                  <Divider />
                                                </Grid>
                                              )}
                                            </React.Fragment>
                                          )
                                        )}
                                      </Grid>
                                    ) : (
                                      <Typography variant='body2' color='textSecondary'>
                                        No shipment summary to display
                                      </Typography>
                                    )}
                                  </Box>
                                </Card>
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6}>
                                    <CngField label={translatedTextsObject.totalInvoice}>
                                      {summary.totalInvoice}
                                    </CngField>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Card
                                      variant='outlined'
                                      onClick={() => {
                                        if (!hasSummaryReference) return

                                        setReferenceDialog({
                                          open: true,
                                          references: shipmentSummaryReference
                                        })
                                      }}
                                      style={{ cursor: hasSummaryReference ? 'pointer' : 'default' }}
                                    >
                                      <Box className={classes.wrapper}>
                                        <Box flexGrow='1'>
                                          <Typography variant='body2' style={{ fontWeight: 600 }}>
                                            {translatedTextsObject.summaryReference}
                                          </Typography>
                                        </Box>
                                        <div>
                                          <Paper className={classes.badge} variant='outlined'>
                                            <Typography
                                              align='center'
                                              variant='body2'
                                              style={{ fontWeight: 600 }}
                                            >
                                              {shipmentSummaryReference.length}
                                            </Typography>
                                          </Paper>
                                        </div>
                                      </Box>
                                    </Card>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Box>
                        </Card>
                      </Grid>
                    )
                  })}
                </Grid>
              ) : (
                <Typography variant='body2' color='textSecondary'>
                  No records to display
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </Card>
      <CngDialog
        customDialogContent={
          <Box padding={2}>
            <Grid container spacing={2}>
              {referenceDialog.references.map((reference, index) => (
                <React.Fragment key={reference.id}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={3}>
                        <CngField label={translatedTextsObject.additionDeductionInd}>
                          {getLookupValue('additionDeductionInd', reference.additionDeductionInd)}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <CngField label={translatedTextsObject.additionDeductionCode}>
                          {getLookupValue('additionDeductionCode', reference.additionDeductionCode)}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <CngField label={translatedTextsObject.additionDeductionType}>
                          {getLookupValue('additionDeductionType', reference.additionDeductionType)}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <CngField label={translatedTextsObject.amountRatePercent}>
                          {reference.amountRatePercent}
                        </CngField>
                      </Grid>
                      <Grid item xs={12}>
                        <CngField label={translatedTextsObject.explanation}>
                          {reference.explanation}
                        </CngField>
                      </Grid>
                    </Grid>
                  </Grid>
                  {index + 1 < referenceDialog.references.length && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        }
        dialogAction={
          <CngButton onClick={() => setReferenceDialog({ open: false, references: [] })}>
            OK
          </CngButton>
        }
        dialogTitle={translatedTextsObject.summaryReference}
        fullWidth
        maxWidth='md'
        onClose={() => setReferenceDialog({ open: false, references: [] })}
        open={referenceDialog.open}
        shouldShowCloseButton
      />
    </>
  )
}

export default InvoiceSummarySection
