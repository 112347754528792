import { Yup } from 'cng-web-lib'

function makeValidationSchema() {
  const regexDigitWithDecimal = '^[0-9]*.[0-9]*$'
  const errMsgDigitWithDecimal =
    'Invalid Value - Allowed Digits with/without decimal'

  const validateStringField = (maxLength, regexPattern, errorMsg) =>
    Yup.string()
      .nullable()
      .matches(regexPattern, errorMsg)
      .max(
        maxLength,
        'Field can contain maximum of ' + maxLength + ' characters'
      )

  return Yup.object({
    totalInvoice: validateStringField(
      20,
      regexDigitWithDecimal,
      errMsgDigitWithDecimal
    ),
    shipmentSummary: Yup.array(),
    shipmentSummaryReference: Yup.array()
  })
}

export default makeValidationSchema
