import makeValidationSchema from './CfiaFormMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../../../constants/locale/key/NBCICanadaInvoice'
import Grid from '@material-ui/core/Grid'

const {
  form: {
    field: { CngTextField, CngDateField, CngSelectField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  etaDateTime: '',
  cbsaReleaseOff: '',
  deliveryAddress: '',
  meatShipment: '',
  unmarkMeatShip: '',
  quantity: '',
  driverWaiting: 'N',
  isEdi: 'N',
  transactionNo: '',
  prodDesc: '',
  voyageNo: '',
  entryPoint: '',
  blNumber: '',
  comments: '',
  payerName: '',
  cfiaClientAcctNo: '',
  sendAddressInd: '',
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const etaDateTime = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.ETA_DATE_TIME
    )
    const cbsaReleaseOff = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.CBSA_RELEASE_OFF
    )
    const deliveryAddress = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.DELIVERY_ADDRESS
    )
    const meatShipment = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.MEAT_SHIPMENT
    )
    const unmarkMeatShip = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.UNMARK_MEAT_SHIP
    )
    const quantity = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.QUANTITY
    )
    const driverWaiting = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.DRIVER_WAITING
    )
    const isEdi = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.IS_EDI
    )
    const transactionNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.TRANSACTION_NO
    )
    const prodDesc = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.PROD_DESC
    )
    const voyageNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.VOYAGE_NO
    )
    const entryPoint = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.ENTRY_POINT
    )
    const blNumber = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.BL_NUMBER
    )
    const comments = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.COMMENTS
    )
    const payerName = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.PAYER_NAME
    )
    const cfiaClientAcctNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.CFIA_CLIENT_ACCT_NO
    )
    const sendAddressInd = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.SEND_ADDRESS_IND
    )

    return {
      etaDateTime,
      cbsaReleaseOff,
      deliveryAddress,
      meatShipment,
      unmarkMeatShip,
      quantity,
      driverWaiting,
      isEdi,
      transactionNo,
      prodDesc,
      voyageNo,
      entryPoint,
      blNumber,
      comments,
      payerName,
      cfiaClientAcctNo,
      sendAddressInd,
    }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.etaDateTime}>
        <CngDateField
          name='etaDateTime'
          label={translatedTextsObject.etaDateTime}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.cbsaReleaseOff}>
        <CngTextField
          name='cbsaReleaseOff'
          label={translatedTextsObject.cbsaReleaseOff}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.deliveryAddress}>
        <CngTextField
          name='deliveryAddress'
          label={translatedTextsObject.deliveryAddress}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.meatShipment}>
        <CngTextField
          name='meatShipment'
          label={translatedTextsObject.meatShipment}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.unmarkMeatShip}>
        <CngTextField
          name='unmarkMeatShip'
          label={translatedTextsObject.unmarkMeatShip}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.quantity}>
        <CngTextField
          name='quantity'
          label={translatedTextsObject.quantity}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.driverWaiting}>
        <CngSelectField
          name='driverWaiting'
          label={translatedTextsObject.driverWaiting}
          disabled={disabled}
          size='small'
          items={[
            { text: 'Yes', value: 'Y' },
            { text: 'No', value: 'N' }
          ]}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.isEdi}>
        <CngSelectField
          name='isEdi'
          label={translatedTextsObject.isEdi}
          disabled={disabled}
          size='small'
          items={[
            { text: 'Yes', value: 'Y' },
            { text: 'No', value: 'N' }
          ]}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.transactionNo}>
        <CngTextField
          name='transactionNo'
          label={translatedTextsObject.transactionNo}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.prodDesc}>
        <CngTextField
          name='prodDesc'
          label={translatedTextsObject.prodDesc}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.voyageNo}>
        <CngTextField
          name='voyageNo'
          label={translatedTextsObject.voyageNo}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.entryPoint}>
        <CngTextField
          name='entryPoint'
          label={translatedTextsObject.entryPoint}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.blNumber}>
        <CngTextField
          name='blNumber'
          label={translatedTextsObject.blNumber}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} shouldHide={shouldHideMap?.comments}>
        <CngTextField
          name='comments'
          label={translatedTextsObject.comments}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.payerName}>
        <CngTextField
          name='payerName'
          label={translatedTextsObject.payerName}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.cfiaClientAcctNo}>
        <CngTextField
          name='cfiaClientAcctNo'
          label={translatedTextsObject.cfiaClientAcctNo}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.sendAddressInd}>
        <CngSelectField
          name='sendAddressInd'
          label={translatedTextsObject.sendAddressInd}
          disabled={disabled}
          size='small'
          items={[
            { text: 'National Import Service Center', value: 'NI' },
            { text: 'Eastern Import Service Center', value: 'EI' }
          ]}
        />
      </CngGridItem>
    </Grid>
  )
}

const CfiaFormFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default CfiaFormFormProperties
