import React, {  } from 'react'
import { components, useServices } from 'cng-web-lib'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import { makeStyles } from '@material-ui/core'
import NBCICanadaInvoiceApiUrls from 'src/apiUrls/NBCICanadaInvoiceApiUrls'
import { format } from 'date-fns'
const {
  button: { CngButton },
  CngDialog,
} = components


function PrintFlagDialog(props) {
  const {
    openDialog,
    onClose  } = props

  const { securedSendRequest } = useServices();
  console.log("Inside printFalgDialog: " +JSON.stringify(openDialog.manifest) );
  return (
    <CngDialog
      customDialogContent={
        <Paper>
          <Box m={2} mt={1}>
            <Box mt={0.5}>
            
            <CngButton style={
                {width:'150px',height:'35px'}
              }
              variant="contained"
              className="button-blue originalText"
              disabled = {openDialog.manifest && openDialog.manifest.bolForm && openDialog.manifest.bolForm.length == 0}
              onClick={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "NBCI_BOL_";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate+".pdf";
      
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }
              
                function onComplete(){
              
                }
                const config = {
                  responseType: 'blob'
                }
                securedSendRequest.execute('POST',NBCICanadaInvoiceApiUrls.PRINT_BOL, openDialog.manifest, onSuccess, onError, onComplete,config,null);  
              
              }}
              >BOL
              </CngButton>
              <br></br>
              <br></br>
              <CngButton style={
                {width:'150px',height:'35px'}
              }
              variant="contained"
              className="button-blue originalText"
              disabled = {openDialog.manifest && openDialog.manifest.naftaForm && openDialog.manifest.naftaForm.length == 0}
              onClick={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "NBCI_USMCA_CUSMA_";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate+".pdf";
      
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }
              
                function onComplete(){
              
                }
                const config = {
                  responseType: 'blob'
                }
                securedSendRequest.execute('POST',NBCICanadaInvoiceApiUrls.PRINT_USMCACUSMA, openDialog.manifest, onSuccess, onError, onComplete,config,null);  
              
              }}
              >USMCACUSMA
              </CngButton>
              <br></br>
              <br></br>
              <CngButton style={
                {width:'150px',height:'35px'}
              }
              variant="contained"
              className="button-blue originalText"
              disabled = {openDialog.manifest && openDialog.manifest.packingList && openDialog.manifest.packingList.length == 0}
              onClick={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "NBCI_PACKING_LIST_";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate+".pdf";
      
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }
              
                function onComplete(){
              
                }
                const config = {
                  responseType: 'blob'
                }
                securedSendRequest.execute('POST',NBCICanadaInvoiceApiUrls.PRINT_PL, openDialog.manifest, onSuccess, onError, onComplete,config,null);  

               }}
              >PACKING LIST
              </CngButton>
              <br></br>
              <br></br>
              <CngButton style={
                {width:'150px',height:'35px'}
              }
              variant="contained"
              className="button-blue originalText"
              disabled = {openDialog.manifest && openDialog.manifest.b13aForm && openDialog.manifest.b13aForm.length == 0}
              onClick={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "NBCI_B13A_";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate+".pdf";
      
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }
              
                function onComplete(){
              
                }
                const config = {
                  responseType: 'blob'
                }
                securedSendRequest.execute('POST',NBCICanadaInvoiceApiUrls.PRINT_B13A, openDialog.manifest, onSuccess, onError, onComplete,config,null);  

               }}
              >B13A
              </CngButton>
              <br></br>
              <br></br>
              <CngButton style={
                {width:'150px',height:'35px'}
              }
              variant="contained"
              className="button-blue originalText"
              disabled = {openDialog.manifest && openDialog.manifest.cfiaForm && openDialog.manifest.cfiaForm.length == 0}
              onClick={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "NBCI_CFIA_";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate+".pdf";
      
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }
              
                function onComplete(){
              
                }
                const config = {
                  responseType: 'blob'
                }
                securedSendRequest.execute('POST',NBCICanadaInvoiceApiUrls.PRINT_CFIA, openDialog.manifest, onSuccess, onError, onComplete,config,null);  

               }}
              >CFIA
              </CngButton>
              <br></br>
              <br></br>
              <CngButton style={
                {width:'150px',height:'35px'}
              }
              variant="contained"
              className="button-blue originalText"
              disabled = {openDialog.manifest && openDialog.manifest.importDeclForm && openDialog.manifest.importDeclForm.length == 0}
              onClick={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "NBCI_IMPORT_DECLARTION_";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate+".pdf";
      
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }
              
                function onComplete(){
              
                }
                const config = {
                  responseType: 'blob'
                }
                securedSendRequest.execute('POST',NBCICanadaInvoiceApiUrls.PRINT_IMPORT_DECLARTION, openDialog.manifest, onSuccess, onError, onComplete,config,null);  

               }}
              >IMPORT DECLARTION
              </CngButton>
              <br></br>
              <br></br>
              <CngButton style={
                {width:'150px',height:'35px'}
              }
              variant="contained"
              className="button-blue originalText"
              //disabled = {openDialog.manifest && openDialog.manifest.ciForm && openDialog.manifest.ciForm.length == 0}
              onClick={() => {
                function onSuccess(response) {
                  let blob = new Blob([response.data]);

                  let first = "NBCI_CCI_";
                  let curDate = format(new Date(), 'yyyyMMddHHmmss');
                  let filename = first + curDate+".pdf";
      
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = filename;
                  a.click();
                }
                function onError(error) {
                  console.error(error);
                }
              
                function onComplete(){
              
                }
                const config = {
                  responseType: 'blob'
                }
               securedSendRequest.execute('POST',NBCICanadaInvoiceApiUrls.PRINT_CCI, openDialog.manifest, onSuccess, onError, onComplete,config,null);  
              
              }}
              >CCI
              </CngButton>
            </Box>
           </Box>
         </Paper>
      }
      dialogTitle={
        <>
          <b>Print Forms</b>
          <CloseDialogIconButton onClick={onClose} />
        </>
      }
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth='md'
      onClose={onClose}
      open={openDialog.open}
    />
  )
}

export default PrintFlagDialog