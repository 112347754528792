import React, { useContext } from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../../constants/locale/key/NBCICanadaInvoice'
import { CAInvoiceLookupsContext } from '../lookups/CAInvoiceLookupsContext'
import CngField from '../../../components/cngcomponents/CngField'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import _ from 'lodash'

function NaftaFormViewContent(props) {
  const { naftaForm } = props

  const { getLookupValue } = useContext(CAInvoiceLookupsContext)
  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const blanketPeriodFrom = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.NaftaForm.BLANKET_PERIOD_FROM
    )
    const blanketPeriodTo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.NaftaForm.BLANKET_PERIOD_TO
    )
    const company = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.NaftaForm.COMPANY
    )
    const companyTitle = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.NaftaForm.NAFTA_TITLE
    )
    const naftaDate = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.NaftaForm.NAFTA_DATE
    )
    const partyName = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.NaftaForm.PARTY_NAME
    )
    const contactTelphNum = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.NaftaForm.CONTACT_TELPH_NUM
    )
    const contactFax = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.NaftaForm.CONTACT_FAX
    )
    const email = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.NaftaForm.EMAIL
    )
    const certifierType = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.NaftaForm.CERTIFIER_TYPE
    )

    return {
      blanketPeriodFrom,
      blanketPeriodTo,
      company,
      companyTitle,
      naftaDate,
      partyName,
      contactTelphNum,
      contactFax,
      email,
      certifierType
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.blanketPeriodFrom}>
          {naftaForm.blanketPeriodFrom &&
            moment(naftaForm.blanketPeriodFrom).format('D MMM YYYY')}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.blanketPeriodTo}>
          {naftaForm.blanketPeriodTo &&
            moment(naftaForm.blanketPeriodTo).format('D MMM YYYY')}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.certifierType}>
          {getLookupValue('certifierType', naftaForm.certifierType)}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.partyName}>
          {naftaForm.partyName}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.company}>
          {naftaForm.company}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.companyTitle}>
          {naftaForm.companyTitle}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.contactTelphNum}>
          {naftaForm.contactTelphNum}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.contactFax}>
          {naftaForm.contactFax}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.email}>
          {naftaForm.email}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.naftaDate}>
          {naftaForm.naftaDate &&
            moment(naftaForm.naftaDate).format('D MMM YYYY')}
        </CngField>
      </Grid>
    </Grid>
  )
}

export default NaftaFormViewContent
