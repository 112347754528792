import { Yup } from 'cng-web-lib'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'

function makeValidationSchema() {
  const regexAlphaNumeric =
    ValidationRegex.regexAlphaNumeric
  const errMsgAlphaNumeric =
    'Invalid Value - Allowed Alphanumeric, spaces, and /,+-$#@%&!^_:;.?()[]{}\'"'
  const regexCharacters = '^[a-zA-Z]+'
  const errMsgCharacters = 'Invalid Value - Allowed Alphabets'
  const regexAlphaNumWithHyphenDot = '^[a-zA-Z0-9-.]*$'
  const errMsgAlphaNumWithHyphenDot =
    'Invalid Value - Allowed Alphabetnumeric, digits, hyphen and dot'

  const validateStringField = (maxLength, regexPattern, errorMsg) =>
    Yup.string()
      .nullable()
      .matches(regexPattern, errorMsg)
      .max(
        maxLength,
        'Field can contain maximum of ' + maxLength + ' characters'
      )

  return Yup.object({
    countryTransShipment: Yup.string().nullable(),
    countryOfOrigin: Yup.string().nullable(),
    placeOfDirectShipment: validateStringField(
      25,
      regexCharacters,
      errMsgCharacters
    ),
    alternativeInvNo: validateStringField(
      22,
      regexAlphaNumWithHyphenDot,
      errMsgAlphaNumWithHyphenDot
    ),
    customsRuling: validateStringField(
      50,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ),
    transportTermCode: Yup.string().nullable(),
    termOfSale: validateStringField(80, regexAlphaNumeric, errMsgAlphaNumeric),
    royalityPayments: Yup.string().nullable(),
    royalityGoods: Yup.string().nullable()
  })
}

export default makeValidationSchema
