import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { components, useServices, useTranslation } from 'cng-web-lib'
import Namespace from '../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../constants/locale/key/NBCICanadaInvoice'
import NBCICanadaInvoiceApiUrls from '../../apiUrls/NBCICanadaInvoiceApiUrls'
import pathMap from '../../paths/pathMap'
import InvoiceViewContent from './InvoiceViewContent'
import CAInvoiceLookupsProvider from './lookups/CAInvoiceLookupsContext'
import AlertDialog from '../../components/aciacehighway/AlertDialog'
import { format } from 'date-fns'

import {
  Box,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Grid
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const {
  button: { CngButton }
} = components

function ViewPage(props) {
  const { history, showNotification } = props
  const { id } = useParams()
  const [data, setData] = useState(null)
  const [confirmDialog, setConfirmDialog] = useState(false)
  const { createRecord, deleteRecord, fetchRecord } = useServices()
  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const { securedSendRequest } = useServices();

  useEffect(() => {
    fetchRecord.execute(NBCICanadaInvoiceApiUrls.GET, id, (res) => {
      const data = res.content[0]

      if (data === undefined) {
        showNotification('error', 'Data not found.')
        history.push(pathMap.N_B_C_I_CANADA_INVOICE_LIST_VIEW)
      } else {
        setData(data)
      }
    })
  }, [])

  if (!data) {
    return (
      <Grid container justify='center'>
        <Grid item xs='auto'>
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }

  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const editButton = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.EDIT_BUTTON
    )
    const cloneButton = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CLONE_BUTTON
    )
    const deleteButton = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.DELETE_BUTTON
    )

    return { editButton, cloneButton, deleteButton }
  }

  function formatTradeParty(data){
    const partyData = data.invTradeParty.map(
      (invTradeParty, index) => ({ ...invTradeParty, _id: index + 1 })
    )
    data.invTradeParty = partyData
    return data
  }

  function handleCloneInvoice() {
    if (data) {
      createRecord.execute(
        NBCICanadaInvoiceApiUrls.CLONE,
        data,
        (data) => history.push(pathMap.N_B_C_I_CANADA_INVOICE_ADD_VIEW, formatTradeParty(data)
      )
      )
    }
  }

  function handlePrintInvoice() {
    if (data) {
      function onSuccess(response) {
        let blob = new Blob([response.data]);

        let first = "NBCI_CCI_";
        let curDate = format(new Date(), 'yyyyMMddHHmmss');
        let filename = first + curDate+".pdf";

        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
      }
      function onError(error) {
        console.error(error);
      }
    
      function onComplete(){
    
      }
      const config = {
        responseType: 'blob'
      }
      securedSendRequest.execute('POST',
        NBCICanadaInvoiceApiUrls.PRINT_CCI,
        data,onSuccess, onError, onComplete,config,null)
    }
  }

  function handleDeleteInvoice() {
    deleteRecord.execute(NBCICanadaInvoiceApiUrls.DELETE, data, () => {
      showNotification('success', 'Delete succeeded')
      history.push(pathMap.N_B_C_I_CANADA_INVOICE_LIST_VIEW)
    })
  }

  return (
    <>
      <Card>
        <CardContent>
          <CAInvoiceLookupsProvider>
            <InvoiceViewContent
              data={data}
              history={history}
              showNotification={showNotification}
            />
          </CAInvoiceLookupsProvider>
        </CardContent>
        <Divider />
        <CardActions style={{ padding: 16, justifyContent: 'space-between' }}>
          <CngButton
            color='secondary'
            onClick={() => history.push(pathMap.N_B_C_I_CANADA_INVOICE_LIST_VIEW)}
          >
            Back to Canada invoice list
          </CngButton>
          <Box display='flex' style={{ gap: 16 }}>
            {(data.status === '1005') && (
              <CngButton
                onClick={() => setConfirmDialog(true)}
                startIcon={
                  <FontAwesomeIcon icon={['fal', 'trash']} size='sm' />
                }
                variant='outlined'
              >
                {translatedTextsObject.deleteButton}
              </CngButton>
            )}
            <CngButton
            onClick={handlePrintInvoice}>
            PrintCCI Form
            </CngButton>
            <CngButton
              onClick={handleCloneInvoice}
              startIcon={<FontAwesomeIcon icon={['fal', 'copy']} size='sm' />}
              variant='outlined'
            >
              {translatedTextsObject.cloneButton}
            </CngButton>
            {data.status !== '1008' && (
              <CngButton
                color='primary'
                startIcon={<FontAwesomeIcon icon={['fal', 'pen']} size='sm' />}
                onClick={() =>
                  history.push(`${pathMap.N_B_C_I_CANADA_INVOICE}/edit/${id}`)
                }
              >
                {translatedTextsObject.editButton}
              </CngButton>
            )}
          </Box>
        </CardActions>
      </Card>
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        onCancel={() => setConfirmDialog(false)}
        onConfirm={handleDeleteInvoice}
        title={translatedTextsObject.deleteButton}
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
    </>
  )
}

export default ViewPage
