import makeValidationSchema from './ProdDetailsMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../../../constants/locale/key/NBCICanadaInvoice'
import Grid from '@material-ui/core/Grid'

const {
  form: {
    field: { CngTextField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  purchaseOrderNo: '',
  commonName: '',
  brandName: '',
  gradeCategory: '',
  noOfContainers: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const purchaseOrderNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ProdDetails.PURCHASE_ORDER_NO
    )
    const commonName = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ProdDetails.COMMON_NAME
    )
    const brandName = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ProdDetails.BRAND_NAME
    )
    const gradeCategory = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ProdDetails.GRADE_CATEGORY
    )
    const noOfContainers = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ProdDetails.NO_OF_CONTAINERS
    )

    return {
      purchaseOrderNo,
      commonName,
      brandName,
      gradeCategory,
      noOfContainers,
    }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.purchaseOrderNo}>
        <CngTextField
          name='purchaseOrderNo'
          label={translatedTextsObject.purchaseOrderNo}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.commonName}>
        <CngTextField
          name='commonName'
          label={translatedTextsObject.commonName}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.brandName}>
        <CngTextField
          name='brandName'
          label={translatedTextsObject.brandName}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.gradeCategory}>
        <CngTextField
          name='gradeCategory'
          label={translatedTextsObject.gradeCategory}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.noOfContainers}>
        <CngTextField
          name='noOfContainers'
          label={translatedTextsObject.noOfContainers}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
    </Grid>
  )
}

const ProdDetailsFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default ProdDetailsFormProperties
