import React, { useEffect, useState } from 'react'
import { components, constants, useTranslation } from 'cng-web-lib'
import Namespace from '../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../constants/locale/key/NBCICanadaInvoice'
import NbciInvoiceResponseApiUrls from '../../apiUrls/NbciInvoiceResponseApiUrls'
import CngSection from '../../components/cngcomponents/CngSection'
import Table from '../../components/aciacehighway/Table'
import { Grid, Typography } from '@material-ui/core'
import moment from 'moment'

const {
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL }
} = constants

function ResponseMessage(props) {
  const { showNotification, id } = props

  const [lookups, setLookups] = useState(null)
  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  useEffect(() => {
    Promise.all([
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.STATUS,
        undefined,
        undefined,
        undefined,
        'id'
      )
    ]).then(([status]) => setLookups({ status }))
  }, [])

  function makeTranslatedTextsObject() {
    const title = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.RESPONSE_TAB
    )
    const invoiceNumber = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.INVOICE_NUMBER
    )
    const receiveDatetime = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.RECEIVED_DATE
    )
    const status = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.STATUS
    )
    const errorCode = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ERROR_CODE
    )
    const errorTextLabel = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ERROR_TEXT_LABEL
    )
    const entryNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ENTRY_NUMBER
    )

    return {
      title,
      invoiceNumber,
      receiveDatetime,
      errorCode,
      status,
      entryNo,
      errorTextLabel
    }
  }

  const columns = [
    {
      field: 'invoiceNumber',
      title: translatedTextsObject.invoiceNumber
    },
    {
      field: 'receiveDatetime',
      title: translatedTextsObject.receiveDatetime,
      render: (rowData) =>
        rowData.receiveDatetime &&
        moment(rowData.receiveDatetime).format('DD/MM/YYYY HH:mm:ss')
    },
    {
      field: 'status',
      title: translatedTextsObject.status,
      render: (rowData) => getLookupValue('status', rowData.status)
    },
    {
      field: 'entryNo',
      title: translatedTextsObject.entryNo,
    },
    {
      field: 'errorCode',
      title: translatedTextsObject.errorTextLabel,
      filtering: false,
      render: (rowData) => (
        <Grid container spacing={1}>
          {rowData.errorResponses.map((errorResponse, index) => (
            <Typography key={index} variant='body2'>
              {errorResponse.responseText}
            </Typography>
          ))}
        </Grid>
      )
    }
  ]

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  return (
    <CngSection title={translatedTextsObject.title}>
      <Table
        columns={columns}
        compact
        fetch={{ url: NbciInvoiceResponseApiUrls.GET }}
        fetchFilters={[
          { field: 'headerId', operator: EQUAL, value: id },
          { field: 'docType', operator: EQUAL, value: 'NBCICAINV' }
        ]}
        showNotification={showNotification}
        variant='outlined'
      />
    </CngSection>
  )
}

export default ResponseMessage
