import React, { useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import NBCICanadaInvoiceApiUrls from '../../apiUrls/NBCICanadaInvoiceApiUrls'
import FormProperties from './FormProperties'
import InvoiceDetailsTable from './invoiceDetails/InvoiceDetailsTable'
import InvoiceSummarySection from './invoiceSummary/InvoiceSummarySection'
import Grid from '@material-ui/core/Grid'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import PrintFlagDialog from 'src/views/nbcicanadainvoice/PrintFlagDialog'
import AlertDialog from '../../components/aciacehighway/AlertDialog'

const {
  button: { CngButton },
  form: { CngForm }
} = components

const { FormState } = constants

function InvoiceDetailsAndSummaryInvoiceSection(props) {
  const {
    invoiceData,
    onBack,
    onDiscard,
    // onSetInvoiceData,
    onSetLoading,
    onSkip,
    onSubmit,
    showNotification,
    isPendingSave,
    isPendingSaveShipmentSummary,
    isNotSaved
  } = props

  const { updateRecord, securedSendRequest } = useServices()
  const [invoiceStateData, setInvoiceStateData] = useState(invoiceData)
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [printAlertDialog, setPrintAlertDialog] = useState({
    open: false,
    manifest: null
  })
  const [printFlagDialog, setPrintFlagDialog] = useState({ open: false, manifest: null })

  function prePrint(data) {
    if (isPendingSave.current || isPendingSaveShipmentSummary.current) {
      alert("Changes have not been saved. Please save the changes before proceed.")
    } else {
      if (isNotSaved.current) {
        setPrintAlertDialog({ open: true, manifest: data })
      } else {
        setPrintFlagDialog({ open: true, manifest: invoiceData.current })
      }
    }
  }

  function preSave() {
    if (isPendingSave.current || isPendingSaveShipmentSummary.current) {
      alert("Changes have not been saved. Please save the changes before proceed.")
    } else {
      handleSave(invoiceData.current, false)
    }
  }

  function handleSave(data, isPrint) {
    onSetLoading(true)

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      data.fileForUserId = fileForUserDetails.fileForUserId;
      data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    updateRecord.execute(
      NBCICanadaInvoiceApiUrls.PUT,
      FormProperties.toServerDataFormat(data),
      (response) => {
        showNotification('success', 'Invoice updated.')
        response.invDetails = response.invDetails.map(
          (invoice, index) => ({ ...invoice, _id: index })
        )
        response.invSummary = response.invSummary.map(
          (summary, index) => ({ ...summary, _id: index })
        )
        invoiceData.current = response
        isNotSaved.current = false
        if (isPrint) {
          setPrintFlagDialog({ open: true, manifest: data })
        }
      },
      (error) => {
        console.log(error, error.response)
        const { data } = error.response

        if (data && data.errorMessages) {
          showNotification('error', data.errorMessages)
        }
      },
      () => onSetLoading(false)
    )
  }

  return (
    <>
    <CngForm
      formikProps={{
        initialValues: invoiceData.current,
        makeValidationSchema: FormProperties.formikProps.makeValidationSchema,
        // onSubmit: handleSave
      }}
      formState={FormState.COMPLETED}
      innerForm
      renderBodySection={() => (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InvoiceDetailsTable invoiceData={invoiceData} setInvoiceStateData={setInvoiceStateData} isNotSaved={isNotSaved} />
          </Grid>
          <Grid item xs={12}>
            <InvoiceSummarySection invoiceData={invoiceData} isPendingSave={isPendingSave} isPendingSaveShipmentSummary={isPendingSaveShipmentSummary} invoiceStateData={invoiceStateData} isNotSaved={isNotSaved} />
          </Grid>
        </Grid>
      )}
      renderButtonSection={() => (
        <ButtonSection
          onBack={onBack}
          onDiscard={() => setConfirmDialog(true)}
          onSkip={onSkip}
          onSubmit={onSubmit}
          preSave={preSave}
          onPrint={() => prePrint(invoiceData.current)}
        />
      )}
    />
      <PrintFlagDialog
        openDialog={printFlagDialog}
        onClose={() => setPrintFlagDialog({ open: false, manifest: null })}
        showNotification={showNotification}
      />
      <AlertDialog
        cancelLabel='Continue editing'
        confirmLabel='Yes, Save and Print'
        open={printAlertDialog.open}
        onClose={() => setPrintAlertDialog({ open: false, manifest: null })}
        onCancel={() => setPrintAlertDialog({ open: false, manifest: null })}
        onConfirm={() => {
          setPrintAlertDialog({ open: false, manifest: null })
          handleSave(printAlertDialog.manifest, true)
        }}
        title='Alert'
      >
        Changes have not been saved. Do you want to proceed with saving then print?
      </AlertDialog>
      <AlertDialog
        cancelLabel='Continue editing'
        confirmLabel='Yes, discard'
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        onCancel={() => setConfirmDialog(false)}
        onConfirm={() => {
          setConfirmDialog(false)
          onDiscard(0)
          isPendingSave.current = false
          isPendingSaveShipmentSummary.current = false
          isNotSaved.current = false
        }}
        title='Discard'
      >
        All progress in this session will be lost and cant't be restored. Are
        you sure about this?
      </AlertDialog>
    </>
  )
}

function ButtonSection(props) {
  const { onBack, onDiscard, onSkip, onSubmit, preSave, onPrint } = props

  return (
    <Grid container justify='space-between' spacing={3}>
      <Grid item xs={12} md='auto'>
        <CngButton color='secondary' onClick={onDiscard} size='medium'>
          Discard
        </CngButton>
      </Grid>
      <Grid item xs={12} md='auto'>
        <Grid container spacing={2}>
          <Grid item xs='auto'>
            <CngButton color='secondary' onClick={onBack} size='medium'>
              Back
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <CngButton color='secondary' onClick={onSkip} size='medium'>
              Skip
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <CngButton color='secondary' onClick={onPrint} size='medium'>
              Print
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <CngButton color='primary' onClick={preSave} size='medium'>
              Save as Draft
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <CngButton color='primary' onClick={onSubmit} size='medium'>
              Submit
            </CngButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default InvoiceDetailsAndSummaryInvoiceSection
