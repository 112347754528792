import React, { useRef } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../constants/locale/key/NBCICanadaInvoice'
import FormProperties from './CiCargoSummaryFormProperties'
import CargoSummaryFieldsPlaceholder from './CargoSummaryFieldsPlaceholder'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import _ from 'lodash'
import {
  Box,
  Card,
  Divider,
  Grid,
  Tooltip,
  Typography
} from '@material-ui/core'

const {
  button: { CngButton, CngIconButton }
} = components

const { initialValues } = FormProperties.formikProps

function CargoSummarySection() {
  const generatedIndex = useRef(0)
  const { getValues, setValue, watch } = useFormContext()

  const cargoSummary = watch('ciCargoSummary')

  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const cargoSummary = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CiCargoSummary.TITLE
    )

    return { cargoSummary }
  }

  function handleAddCargoSummary(data) {
    const cargoSummary = [...getValues('ciCargoSummary'), data]

    setValue('ciCargoSummary', cargoSummary,{ shouldDirty: true })
  }

  function handleDeleteCargoSummary(index) {
    const cargoSummary = [...getValues('ciCargoSummary')]

    cargoSummary.splice(index, 1)

    setValue('ciCargoSummary', cargoSummary,{ shouldDirty: true })
  }

  function handleEditCargoSummary(index, data) {
    const cargoSummary = [...getValues('ciCargoSummary')]

    cargoSummary[index] = data

    setValue('ciCargoSummary', cargoSummary,{ shouldDirty: true })
  }

  return (
    <Card variant='outlined'>
      <Box padding={2}>
        <Box mb={2}>
          <Typography style={{ fontWeight: 600 }}>
            {translatedTextsObject.cargoSummary}
          </Typography>
        </Box>
        <Grid container spacing={1}>
          {_.isEmpty(cargoSummary) ? (
            <Grid item xs={12}>
              <CargoSummaryFieldsPlaceholder onAdd={handleAddCargoSummary} />
            </Grid>
          ) : (
            cargoSummary.map((summary, index) => (
              <React.Fragment key={++generatedIndex.current}>
                <Grid item xs={12}>
                  <CargoSummaryEntry
                    cargoSummary={summary}
                    onAdd={handleAddCargoSummary}
                    onDelete={() => handleDeleteCargoSummary(index)}
                    onEdit={(data) => handleEditCargoSummary(index, data)}
                  />
                </Grid>
                {index + 1 !== cargoSummary.length && (
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                )}
              </React.Fragment>
            ))
          )}
        </Grid>
      </Box>
    </Card>
  )
}

function CargoSummaryEntry(props) {
  const { cargoSummary, onAdd, onDelete, onEdit } = props

  const methods = useForm({ defaultValues: cargoSummary })
  const {
    formState: { isDirty }
  } = methods

  function handleEditCargoSummary(data) {
    methods.reset(data)
    onEdit(data)
  }

  return (
    <FormProvider {...methods}>
      <Grid alignItems='center' container spacing={1}>
        <Grid item xs={12} sm>
          <FormProperties.Fields />
        </Grid>
        <Grid item xs={12} sm='auto'>
          {isDirty ? (
            <Grid container spacing={1}>
              <Grid item xs='auto'>
                <Grid container spacing={2}>
                  <Grid item xs='auto'>
                    <CngButton
                      color='secondary'
                      onClick={() => methods.reset()}
                      size='medium'
                    >
                      Reset
                    </CngButton>
                  </Grid>
                  <Grid item xs='auto'>
                    <CngButton
                      color='primary'
                      onClick={methods.handleSubmit(handleEditCargoSummary)}
                      size='medium'
                    >
                      Save changes
                    </CngButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={1}>
              <Grid item xs='auto'>
                <Tooltip placement='bottom' title='Add'>
                  <span>
                    <CngIconButton
                      icon={['fal', 'plus']}
                      onClick={() => onAdd(initialValues)}
                      size='small'
                      type='outlined'
                    />
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs='auto'>
                <Tooltip placement='bottom' title='Clone'>
                  <span>
                    <CngIconButton
                      icon={['fal', 'copy']}
                      onClick={() => onAdd({ ...cargoSummary, id: undefined })}
                      size='small'
                      type='outlined'
                    />
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs='auto'>
                <Tooltip placement='bottom' title='Delete'>
                  <span>
                    <CngIconButton
                      icon={['fal', 'trash']}
                      onClick={() => onDelete(cargoSummary)}
                      size='small'
                      type='outlined'
                    />
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default CargoSummarySection
