import React, { useContext, useState } from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from '../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../constants/locale/key/NBCICanadaInvoice'
import { CAInvoiceLookupsContext } from './lookups/CAInvoiceLookupsContext'
import InvTradePartyFormProperties from './InvTradePartyFormProperties'
import LocalTable from '../../components/aciacehighway/LocalTable'
import Alert from '../../components/Alert'
import DialogForm from '../../components/aciacehighway/DialogForm'
import { useFormContext } from 'react-hook-form'
import { Grid, Card, Typography, Box } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function TradePartySection({ setTradePartyDirty }) {
  const [dialog, setDialog] = useState({ open: false, tradeParty: null })
  const { lookups, getLookupValue } = useContext(CAInvoiceLookupsContext)
  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { getValues, setValue, watch } = useFormContext()
  const tradeParty = watch('invTradeParty')

  function makeTranslatedTextsObject() {
    const tradeParty = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.TITLE
    )
    const partyidentifier = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.PARTYIDENTIFIER
    )
    const partyName = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.PARTY_NAME
    )
    const partyType = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.PARTY_TYPE
    )
    const address = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.ADDRESS
    )
    const countryCode = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.COUNTRY_CODE
    )
    const noteLabel = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.NOTE_LABEL
    )
    const noteTradeParty = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.NOTE_TRADE_PARTY
    )

    return {
      partyidentifier,
      partyType,
      partyName,
      address,
      countryCode,
      tradeParty,
      noteLabel,
      noteTradeParty
    }
  }

  const columns = [
    {
      title: translatedTextsObject.partyidentifier,
      field: 'partyidentifier',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.partyType,
      field: 'partyType',
      filterConfig: {
        type: 'select',
        options: lookups?.partyType
          ? Object.entries(lookups.partyType).map(([code, label]) => ({
            label: label,
            value: code
          }))
          : []
      },
      render: (data) => getLookupValue('partyType', data.partyType)
    },
    {
      title: translatedTextsObject.partyName,
      field: 'partyName',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.address,
      field: 'address',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.countryCode,
      field: 'countryCode',
      filterConfig: { type: 'textfield' },
      render: (data) => getLookupValue('country', data.countryCode)
    }
  ]

  function handleAddTradeParty(data) {
    const tradeParty = [...getValues('invTradeParty'), data].map(
      (party, index) => ({ ...party, _id: index + 1 })
    )

    setValue('invTradeParty', tradeParty)
    setDialog({ open: false, tradeParty: null })
    setTradePartyDirty(true)
  }

  function handleDeleteTradeParty(data) {
    const tradeParty = [...getValues('invTradeParty')].filter((party) =>
      party.id ? party.id !== data.id : party._id !== data._id
    )

    setValue('invTradeParty', tradeParty)
    setTradePartyDirty(true)
  }

  function handleEditTradeParty(data) {
    const tradeParty = [...getValues('invTradeParty')]
    const index = tradeParty.findIndex((party) =>
      party.id ? party.id === data.id : party._id === data._id
    )

    tradeParty[index] = data

    setValue('invTradeParty', tradeParty)
    setDialog({ open: false, tradeParty: null })
    setTradePartyDirty(true)
  }

  return (
    <Card variant='outlined'>
      <LocalTable
        actions={[
          {
            buttonProps: { size: 'medium', color: 'secondary' },
            icon: ['fal', 'plus-circle'],
            label: 'Add party',
            onClick: () => setDialog({ open: true, tradeParty: null })
          }
        ]}
        columns={columns}
        data={tradeParty}
        header={
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography style={{ fontWeight: 600 }}>
                {translatedTextsObject.tradeParty}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display='flex' justifyContent='flex-end'>
                <Alert
                  severity='info'
                  icon={<FontAwesomeIcon icon={['fal', 'info-circle']} size='xs' />}
                >
                  <Typography style={{ lineHeight: 1.2 }} variant='caption'>
                    <Typography style={{ fontWeight: 600 }} variant='inherit'>
                    {translatedTextsObject.noteLabel}&nbsp;
                    </Typography>
                    {translatedTextsObject.noteTradeParty}
                  </Typography>
                </Alert>
              </Box>
            </Grid>
          </Grid>
        }
        rowActions={[
          {
            icon: ['fal', 'pen'],
            onClick: (rowData) =>
              setDialog({ open: true, tradeParty: rowData }),
            tooltip: () => 'Edit'
          },
          {
            icon: ['fal', 'trash'],
            onClick: handleDeleteTradeParty,
            tooltip: () => 'Delete'
          }
        ]}
      />
      <DialogForm
        formProperties={InvTradePartyFormProperties}
        fullWidth
        maxWidth='md'
        open={dialog.open}
        onClose={() => setDialog({ open: false, tradeParty: null })}
        onSubmit={
          dialog.tradeParty ? handleEditTradeParty : handleAddTradeParty
        }
        title={`${dialog.tradeParty ? 'Edit' : 'Add'} trade party`}
        value={dialog.tradeParty}
      />
    </Card>
  )
}

export default TradePartySection
