import React, { useContext } from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import UsProductMasterKeys from '../../../constants/locale/key/UsProductMaster'
import { ProductMasterLookupsContext } from '../lookups/ProductMasterLookupsContext'
import TinyChip from 'src/components/aciacehighway/TinyChip'
import CngSection from '../../../components/cngcomponents/CngSection'
import CngField from '../../../components/cngcomponents/CngField'
import moment from 'moment'
import _ from 'lodash'
import {
  Box,
  Card,
  Divider,
  Grid,
  Typography,
  useTheme
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function ProductMasterViewContent(props) {
  const { data } = props

  const { getLookupValue } = useContext(ProductMasterLookupsContext)
  const theme = useTheme()
  const { translate } = useTranslation(Namespace.US_PRODUCT_MASTER)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const usProductMaster = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.TITLE
    )
    const productId = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.PRODUCT_ID
    )
    const manufacturingPartNo = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.MANUFACTURING_PART_NO
    )
    const itemDescription = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.ITEM_DESCRIPTION
    )
    const productClassificationCode = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.PRODUCT_CLASSIFICATION_CODE
    )
    const hsCode = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.HS_CODE
    )
    const countryOfOrigin = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.COUNTRY_OF_ORIGIN
    )
    const uom = translate(Namespace.US_PRODUCT_MASTER, UsProductMasterKeys.UOM)
    const unitPrice = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.UNIT_PRICE
    )
    const tariffNo = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.TARIFF_NO
    )
    const markPackLoading = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.MARK_PACK_LOADING
    )
    const usmcaLabel = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.USMCA_LABEL
    )
    const includeProduct = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.INCLUDE_PRODUCT
    )
    const isProducer = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.IS_PRODUCER
    )
    const notAProducerOfGoods = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.NOT_A_PRODUCER_OF_GOODS
    )
    const exporterLabelOne = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.EXPORTER_LABEL_ONE
    )
    const exporterLabelTwo = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.EXPORTER_LABEL_TWO
    )
    const exporterLabelThree = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.EXPORTER_LABEL_THREE
    )
    const naftaCriteria = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.NAFTA_CRITERIA
    )
    const netCost = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.NET_COST
    )
    const dateRangeFrom = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.DATE_RANGE_FROM
    )
    const dateRangeTo = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.DATE_RANGE_TO
    )
    const manufacturingLabel = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.MANUFACTURING_LABEL
    )
    const manufacturingPartyName = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.MANUFACTURING_PARTY_NAME
    )
    const manufacturingMID = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.MANUFACTURING_M_I_D
    )
    const manufacturingFDARegNum = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.MANUFACTURING_F_D_A_REG_NUM
    )
    const growerLabel = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.GROWER_LABEL
    )
    const growerPartyName = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.GROWER_PARTY_NAME
    )
    const growerMID = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.GROWER_M_I_D
    )
    const growerFDARegNumber = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.GROWER_F_D_A_REG_NUMBER
    )
    const consolidatorLabel = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.CONSOLIDATOR_LABEL
    )
    const consolidatorPartyName = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.CONSOLIDATOR_PARTY_NAME
    )
    const consolidatorMID = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.CONSOLIDATOR_M_I_D
    )
    const consolidatorFDARegNumber = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.CONSOLIDATOR_F_D_A_REG_NUMBER
    )
    const note = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.NOTE
    )
    const noteLabel = translate(
      Namespace.US_PRODUCT_MASTER,
      UsProductMasterKeys.NOTE_LABEL
    )

    return {
      usProductMaster,
      productId,
      manufacturingPartNo,
      itemDescription,
      hsCode,
      productClassificationCode,
      countryOfOrigin,
      uom,
      unitPrice,
      tariffNo,
      markPackLoading,
      usmcaLabel,
      includeProduct,
      isProducer,
      naftaCriteria,
      netCost,
      dateRangeFrom,
      dateRangeTo,
      notAProducerOfGoods,
      exporterLabelOne,
      exporterLabelTwo,
      exporterLabelThree,
      manufacturingLabel,
      manufacturingPartyName,
      manufacturingMID,
      manufacturingFDARegNum,
      growerLabel,
      growerPartyName,
      growerMID,
      growerFDARegNumber,
      consolidatorLabel,
      consolidatorPartyName,
      consolidatorMID,
      consolidatorFDARegNumber,
      noteLabel,
      note
    }
  }

  const { usorcaMaster } = data

  function getCardHeaderBackgroundColor() {
    return theme.palette.background?.sectionOddBg || theme.palette.grey[200]
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngSection title='Product information'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography color='textSecondary'>
                {getLookupValue('usorcaMaster', usorcaMaster)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.productId}>
                {data.productId}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.manufacturingPartNo}>
                {data.manufacturingPartNo}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CngField label={translatedTextsObject.itemDescription}>
                {data.itemDescription}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.productClassificationCode}>
                {data.productClassificationCode}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              {usorcaMaster === 'CA' ? (
                <CngField label={translatedTextsObject.hsCode}>
                  {data.hsCode}
                </CngField>
              ): (
                <CngField label={translatedTextsObject.productClassificationCode}>
                  {data.productClassificationCode}
                </CngField>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.countryOfOrigin}>
                {getLookupValue('country', data.countryOfOrigin)}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.uom}>
                <Typography component='div' variant='inherit'>
                  {getLookupValue('uom', data.uom)}
                  <TinyChip label={data.uom} variant='outlined' />
                </Typography>
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CngField label={translatedTextsObject.unitPrice}>
                {data.unitPrice}
              </CngField>
            </Grid>
            {usorcaMaster !== 'CA' && (
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.tariffNo}>
                  {data.tariffNo}
                </CngField>
              </Grid>
            )}
          </Grid>
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection title='Party Information'>
          <Card variant='outlined'>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box bgcolor={getCardHeaderBackgroundColor()} px={2} py={1}>
                      <Typography style={{ fontWeight: 600 }}>
                        {translatedTextsObject.growerLabel}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box p={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <CngField label={translatedTextsObject.growerPartyName}>
                            {data.growerPartyName}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <CngField label={translatedTextsObject.growerMID}>
                            {data.growerMID}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <CngField label={translatedTextsObject.growerFDARegNumber}>
                            {data.growerFDARegNumber}
                          </CngField>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box bgcolor={getCardHeaderBackgroundColor()} px={2} py={1}>
                      <Typography style={{ fontWeight: 600 }}>
                        {translatedTextsObject.manufacturingLabel}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box p={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <CngField label={translatedTextsObject.manufacturingPartyName}>
                            {data.manufacturingPartyName}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <CngField label={translatedTextsObject.manufacturingMID}>
                            {data.manufacturingMID}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <CngField label={translatedTextsObject.manufacturingFDARegNum}>
                            {data.manufacturingFDARegNum}
                          </CngField>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box bgcolor={getCardHeaderBackgroundColor()} px={2} py={1}>
                      <Typography style={{ fontWeight: 600 }}>
                        {translatedTextsObject.consolidatorLabel}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box p={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <CngField label={translatedTextsObject.consolidatorPartyName}>
                            {data.consolidatorPartyName}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <CngField label={translatedTextsObject.consolidatorMID}>
                            {data.consolidatorMID}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <CngField label={translatedTextsObject.consolidatorFDARegNumber}>
                            {data.consolidatorFDARegNumber}
                          </CngField>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection title='Weight Information'>
          <CngField label={translatedTextsObject.markPackLoading}>
            {data.markPackLoading}
          </CngField>
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.usmcaLabel}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs='auto'>
                  <FontAwesomeIcon
                    color={
                      data.includeProduct === 'Y'
                        ? theme.palette.primary.main
                        : theme.palette.error.main
                    }
                    icon={[
                      'fal',
                      data.includeProduct === 'Y'
                        ? 'check'
                        : 'ban'
                    ]}
                  />
                </Grid>
                <Grid item xs>
                  <Typography variant='body2'>
                    {translatedTextsObject.includeProduct} - preference
                    criteria&nbsp;
                    <Typography color='primary' variant='inherit'>
                      {data.naftaCriteria}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <CngSection title={translatedTextsObject.includeProduct}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={translatedTextsObject.dateRangeFrom}>
                      {data.dateRangeFrom &&
                        moment(data.dateRangeFrom, 'DD/MM/yyyy').format('D MMM YYYY')}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <CngField label={translatedTextsObject.dateRangeTo}>
                      {data.dateRangeTo &&
                        moment(data.dateRangeTo, 'DD/MM/yyyy').format('D MMM YYYY')}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                      <Grid item xs='auto'>
                        <FontAwesomeIcon
                          color={
                            data.isProducer === 'Y'
                              ? theme.palette.primary.main
                              : theme.palette.error.main
                          }
                          icon={[
                            'fal',
                            data.isProducer === 'Y'
                              ? 'check'
                              : 'ban'
                          ]}
                        />
                      </Grid>
                      <Grid item xs>
                        <Typography variant='body2'>
                          {translatedTextsObject.isProducer}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CngSection>
            </Grid>
          </Grid>
        </CngSection>
      </Grid>
    </Grid>
  )
}

export default ProductMasterViewContent
