import React, { useRef } from 'react'
import { components } from 'cng-web-lib'
import FormProperties from './SummaryReferenceFormProperties'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { Box, Card, Divider, Grid, Tooltip } from '@material-ui/core'
import SummaryReferenceFieldsPlaceholder from './SummaryReferenceFieldsPlaceholder'
import _ from 'lodash'

const {
  button: { CngButton, CngIconButton }
} = components

const { initialValues } = FormProperties.formikProps

function SummaryReferenceSection(props) {
  const { shouldHideMap } = props

  const { getValues, setValue, watch } = useFormContext()
  const references = watch('shipmentSummaryReference')
  const generatedIndex = useRef(0)

  function handleAddSummaryReference(data) {
    const references = [...getValues('shipmentSummaryReference'), data]

    setValue('shipmentSummaryReference', references)
  }

  function handleDeleteSummaryReference(index) {
    const references = [...getValues('shipmentSummaryReference')]

    references.splice(index, 1)

    setValue('shipmentSummaryReference', references)
  }

  function handleEditSummaryReference(index, data) {
    const references = [...getValues('shipmentSummaryReference')]

    references[index] = data

    setValue('shipmentSummaryReference', references)
  }

  return (
    <Card variant='outlined'>
      <Box padding={2}>
        <Grid container spacing={2}>
          {_.isEmpty(references) ? (
            <Grid item xs={12}>
              <SummaryReferenceFieldsPlaceholder
                shouldHideMap={shouldHideMap}
                onAdd={handleAddSummaryReference}
              />
            </Grid>
          ) : (
            references.map((reference, index) => (
              <React.Fragment key={++generatedIndex.current}>
                <Grid item xs={12}>
                  <SummaryReferenceEntry
                    references={reference}
                    onAdd={handleAddSummaryReference}
                    onDelete={() => handleDeleteSummaryReference(index)}
                    onEdit={(data) => handleEditSummaryReference(index, data)}
                    shouldHideMap={shouldHideMap}
                  />
                </Grid>
                {index + 1 !== references.length && (
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                )}
              </React.Fragment>
            ))
          )}
        </Grid>
      </Box>
    </Card>
  )
}

function SummaryReferenceEntry(props) {
  const { references, onAdd, onDelete, onEdit, shouldHideMap } = props

  const methods = useForm({ defaultValues: references })
  const {
    formState: { isDirty }
  } = methods

  function handleEditSummaryReference(data) {
    methods.reset(data)
    onEdit(data)
  }

  return (
    <FormProvider {...methods}>
      <Grid alignItems='center' container spacing={1}>
        <Grid item xs={12}>
          <FormProperties.Fields shouldHideMap={shouldHideMap} />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify='flex-end' spacing={1}>
            {isDirty ? (
              <>
                <Grid item xs='auto'>
                  <CngButton
                    color='secondary'
                    onClick={() => methods.reset()}
                    size='medium'
                  >
                    Reset
                  </CngButton>
                </Grid>
                <Grid item xs='auto'>
                  <CngButton
                    color='primary'
                    onClick={methods.handleSubmit(handleEditSummaryReference)}
                    size='medium'
                  >
                    Save changes
                  </CngButton>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Add'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'plus']}
                        onClick={() => onAdd(initialValues)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Clone'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'copy']}
                        onClick={() => onAdd({ ...references, id: undefined })}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Delete'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'trash']}
                        onClick={() => onDelete(references)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default SummaryReferenceSection
