import React, { useState } from 'react'
import { components } from 'cng-web-lib'
import {
  Box,
  Card,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  Typography
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const {
  button: { CngIconButton }
} = components

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    padding: '8px 8px 8px 16px',
    '& .MuiIconButton-root': {
      minHeight: 'auto'
    }
  },
  badge: {
    padding: 4,
    minWidth: 40,
    borderRadius: 32
  }
}))

function AdditionalFormItem(props) {
  const {
    label,
    data = [],
    isViewOnly = false,
    onManage = () => {},
    onClick,
    onDownload = () => {}
  } = props

  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles()

  function handleClosePopover() {
    setAnchorEl(null)
  }

  return (
    <>
      <Card
        onClick={onClick}
        variant='outlined'
        style={{ cursor: onClick ? 'pointer' : 'default' }}
      >
        <Box className={classes.wrapper}>
          <Box flexGrow='1'>
            <Typography variant='body2' style={{ fontWeight: 600 }}>
              {label}
            </Typography>
          </Box>
          <div>
            <Paper className={classes.badge} variant='outlined'>
              <Typography
                align='center'
                variant='body2'
                style={{ fontWeight: 600 }}
              >
                {data.length}
              </Typography>
            </Paper>
          </div>
          {isViewOnly ? (
            <CngIconButton
              disabled={data.length === 0}
              icon={['fal', 'arrow-to-bottom']}
              onClick={(event) => {
                event.stopPropagation()
                onDownload()
              }}
              size='small'
              type='outlined'
            />
          ) : (
            <CngIconButton
              icon={['fal', 'ellipsis-h']}
              onClick={(event) => {
                event.stopPropagation()
                setAnchorEl(event.currentTarget)
              }}
              size='small'
              type='outlined'
            />
          )}
        </Box>
      </Card>
      <Popover
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        open={anchorEl !== null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuList dense disablePadding>
          <MenuItem
            onClick={() => {
              onManage()
              handleClosePopover()
            }}
          >
            <ListItemIcon>
              <FontAwesomeIcon fixedWidth icon={['fal', 'pen']} />
            </ListItemIcon>
            <ListItemText
              primary='Manage'
              primaryTypographyProps={{
                variant: 'body2',
                style: { fontWeight: 700 }
              }}
            />
          </MenuItem>
          <MenuItem
            onClick={() => {
              onDownload()
              handleClosePopover()
            }}
          >
            <ListItemIcon>
              <FontAwesomeIcon fixedWidth icon={['fal', 'arrow-to-bottom']} />
            </ListItemIcon>
            <ListItemText
              primary='Download'
              primaryTypographyProps={{
                variant: 'body2',
                style: { fontWeight: 700 }
              }}
            />
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  )
}

export default AdditionalFormItem
