import React, { useRef } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import FormProperties from './PackingListFormProperties'
import PackingListFieldsPlaceholder from './PackingListFieldsPlaceholder'
import { FormProvider, useForm } from 'react-hook-form'
import _ from 'lodash'
import { Box, Card, Divider, Grid } from '@material-ui/core'
import useCustomYupValidationResolver from '../../../../components/aciacehighway/hooks/useCustomYupValidationResolver'

const {
  button: { CngButton }
} = components

const { initialValues, makeValidationSchema } = FormProperties.formikProps

function PackingListSection(props) {
  const { packingList: packingListProp, onClose, onSubmit } = props

  const generatedIndex = useRef(0)
  const methods = useForm({ defaultValues: { packingList: packingListProp } })
  const {
    formState: { isSubmitting },
    getValues,
    setValue,
    watch
  } = methods
  const packingList = watch('packingList')

  function handleAddPackingList(data) {
    const packingList = [...getValues('packingList'), data]

    setValue('packingList', packingList)
  }

  function handleDeletePackingList(index) {
    const packingList = [...getValues('packingList')]

    packingList.splice(index, 1)

    setValue('packingList', packingList)
  }

  function handleEditPackingList(index, data) {
    const packingList = [...getValues('packingList')]

    packingList[index] = data

    setValue('packingList', packingList)
  }

  async function handleSubmit(data) {
    return new Promise((resolve) => {
      setTimeout(() => {
        onSubmit(data.packingList)
        resolve()
      }, 500)
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card variant='outlined'>
          <Grid container spacing={2}>
            {_.isEmpty(packingList) ? (
              <Grid item xs={12}>
                <Box padding={2}>
                  <PackingListFieldsPlaceholder onAdd={handleAddPackingList} />
                </Box>
              </Grid>
            ) : (
              packingList.map((item, index) => (
                <React.Fragment key={++generatedIndex.current}>
                  <Grid item xs={12}>
                    <Box padding={2}>
                      <PackingListEntry
                        packingList={item}
                        onDelete={() => handleDeletePackingList(index)}
                        onEdit={(data) => handleEditPackingList(index, data)}
                      />
                    </Box>
                  </Grid>
                  {index + 1 !== packingList.length && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </React.Fragment>
              ))
            )}
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify='space-between' spacing={2}>
          <Grid item xs='auto'>
            <CngButton
              color='secondary'
              disabled={isSubmitting}
              onClick={() => methods.reset(packingList || initialValues)}
            >
              Clear all
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <Grid container spacing={2}>
              <Grid item xs='auto'>
                <CngButton
                  color='secondary'
                  disabled={isSubmitting}
                  onClick={onClose}
                >
                  Discard
                </CngButton>
              </Grid>
              <Grid item xs='auto'>
                <CngButton
                  disabled={isSubmitting}
                  onClick={methods.handleSubmit(handleSubmit)}
                >
                  Save
                </CngButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

function PackingListEntry(props) {
  const { packingList, onDelete, onEdit } = props

  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const methods = useForm({
    defaultValues: packingList,
    resolver: useCustomYupValidationResolver(makeValidationSchema(translate))
  })
  const {
    formState: { isDirty }
  } = methods

  function handleEditPackingList(data) {
    methods.reset(data)
    onEdit(data)
  }

  return (
    <FormProvider {...methods}>
      <Grid alignItems='center' container spacing={1}>
        <Grid item xs={12}>
          <FormProperties.Fields />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify='flex-end' spacing={1}>
            {isDirty ? (
              <>
                <Grid item xs='auto'>
                  <CngButton
                    color='secondary'
                    onClick={() => methods.reset()}
                    size='medium'
                  >
                    Reset
                  </CngButton>
                </Grid>
                <Grid item xs='auto'>
                  <CngButton
                    color='primary'
                    onClick={methods.handleSubmit(handleEditPackingList)}
                    size='medium'
                  >
                    Save changes
                  </CngButton>
                </Grid>
              </>
            ) : (
              <Grid item xs='auto'>
                <CngButton
                  color='primary'
                  onClick={() => onDelete(packingList)}
                  size='medium'
                >
                  Remove
                </CngButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default PackingListSection
