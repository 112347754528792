import { Yup } from 'cng-web-lib'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'

function makeValidationSchema() {
  const regexAlphaNumeric =
    ValidationRegex.regexAlphaNumeric
  const errMsgAlphaNumeric =
    'Invalid Value - Allowed Alphanumeric, spaces, and /,+-$#@%&!^_:;.?()[]{}\'"'
  const regexAlphaNum = '^[a-zA-Z0-9]*$'
  const errMsgAlphaNum = 'Invalid Value - Allowed Alphanumeric'

  const validateStringField = (maxLength, regexPattern, errorMsg) =>
    Yup.string()
      .nullable()
      .matches(regexPattern, errorMsg)
      .max(
        maxLength,
        'Field can contain maximum of ' + maxLength + ' characters'
      )

  return Yup.object({
    otherRefNo: validateStringField(30, regexAlphaNumeric, errMsgAlphaNumeric),
    responsibleForCustoms: validateStringField(
      20,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ),
    instructionsToBroker: Yup.string().nullable(),
    containerized: Yup.string().nullable(),
    billChargesTo: Yup.string().nullable(),
    portOfLoading: validateStringField(4, regexAlphaNum, errMsgAlphaNum),
    flightDetails: validateStringField(20, regexAlphaNum, errMsgAlphaNum),
    placeOfDelivery: validateStringField(20, regexAlphaNum, errMsgAlphaNum),
    specialInstructions: validateStringField(
      200,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    )
  })
}

export default makeValidationSchema
