import React, { useContext } from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../../constants/locale/key/NBCICanadaInvoice'
import { CAInvoiceLookupsContext } from '../lookups/CAInvoiceLookupsContext'
import CngField from '../../../components/cngcomponents/CngField'
import { Grid, useTheme } from '@material-ui/core'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

function B13aFormViewContent(props) {
  const { b13aForm } = props

  const { getLookupValue } = useContext(CAInvoiceLookupsContext)
  const theme = useTheme()
  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const businessNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.BUSINESS_NO
    )
    const exportLicenseNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.EXPORT_LICENSE_NO
    )
    const destinationCountry = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.DESTINATION_COUNTRY
    )
    const transportDocno = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.TRANSPORT_DOCNO
    )
    const custProofReportNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.CUST_PROOF_REPORT_NO
    )
    const goodsInspected = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.GOODS_INSPECTED
    )
    const status = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.STATUS
    )
    const vesselName = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.VESSEL_NAME
    )
    const dateOfExport = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.DATE_OF_EXPORT
    )
    const noOfPkgs = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.NO_OF_PKGS
    )
    const pkgsType = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.PKGS_TYPE
    )
    const containerNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.CONTAINER_NO
    )
    const frieghtChrgsToExit = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.FRIEGHT_CHRGS_TO_EXIT
    )
    const exportRefNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.EXPORT_REF_NO
    )
    const reasonForExport = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.REASON_FOR_EXPORT
    )

    return {
      businessNo,
      exportLicenseNo,
      destinationCountry,
      transportDocno,
      custProofReportNo,
      goodsInspected,
      vesselName,
      dateOfExport,
      pkgsType,
      noOfPkgs,
      containerNo,
      exportRefNo,
      status,
      frieghtChrgsToExit,
      reasonForExport,
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.businessNo}>
          {b13aForm.businessNo}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.exportLicenseNo}>
          {b13aForm.exportLicenseNo}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.destinationCountry}>
          {getLookupValue('country', b13aForm.destinationCountry)}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.transportDocno}>
          {b13aForm.transportDocno}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.custProofReportNo}>
          {b13aForm.custProofReportNo}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Grid container spacing={2}>
          <Grid item xs='auto'>
            <FontAwesomeIcon
              color={
                b13aForm.goodsInspected === 'Y'
                  ? theme.palette.primary.main
                  : theme.palette.error.main
              }
              fixedWidth
              icon={['fal', b13aForm.goodsInspected === 'Y' ? 'check' : 'ban']}
            />
          </Grid>
          <Grid item xs>
            {translatedTextsObject.goodsInspected}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.vesselName}>
          {b13aForm.vesselName}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.dateOfExport}>
          {b13aForm.dateOfExport &&
            moment(b13aForm.dateOfExport).format('D MMM YYYY')}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.pkgsType}>
          {getLookupValue('pkgsType', b13aForm.pkgsType)}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.noOfPkgs}>
          {b13aForm.noOfPkgs}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.containerNo}>
          {b13aForm.containerNo}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.exportRefNo}>
          {b13aForm.exportRefNo}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.status}>
          {getLookupValue('b13aStatus', b13aForm.status)}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.frieghtChrgsToExit}>
          {b13aForm.frieghtChrgsToExit}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CngField label={translatedTextsObject.reasonForExport}>
          {b13aForm.reasonForExport}
        </CngField>
      </Grid>
    </Grid>
  )
}

export default B13aFormViewContent
