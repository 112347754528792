import makeValidationSchema from './ShipmentSummaryMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../../constants/locale/key/NBCICanadaInvoice'
import Grid from '@material-ui/core/Grid'

const {
  form: {
    field: { CngSelectField, CngTextField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  noOfPkgs: '',
  pkgType: '',
  pkgDimLength: '',
  pkgDimWidth: '',
  pkgDimHeight: '',
  pkgDimUom: '',
  commodityDescription: '',
  grossWeight: '',
  grossWeightUom: '',
  freightClass: '',
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const noOfPkgs = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ShipmentSummary.NO_OF_PKGS
    )
    const pkgType = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ShipmentSummary.PKG_TYPE
    )
    const pkgDimLength = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ShipmentSummary.PKG_DIM_LENGTH
    )
    const pkgDimWidth = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ShipmentSummary.PKG_DIM_WIDTH
    )
    const pkgDimHeight = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ShipmentSummary.PKG_DIM_HEIGHT
    )
    const pkgDimUom = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ShipmentSummary.PKG_DIM_UOM
    )
    const commodityDescription = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ShipmentSummary.COMMODITY_DESCRIPTION
    )
    const grossWeight = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ShipmentSummary.GROSS_WEIGHT
    )
    const grossWeightUom = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ShipmentSummary.GROSS_WEIGHT_UOM
    )
    const freightClass = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ShipmentSummary.FREIGHT_CLASS
    )

    return {
      noOfPkgs,
      pkgType,
      pkgDimLength,
      pkgDimWidth,
      pkgDimHeight,
      pkgDimUom,
      commodityDescription,
      grossWeight,
      grossWeightUom,
      freightClass,
    }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} md={2} xl={3} shouldHide={shouldHideMap?.noOfPkgs}>
        <CngTextField
          name='noOfPkgs'
          label={translatedTextsObject.noOfPkgs}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={2} xl={3} shouldHide={shouldHideMap?.pkgType}>
        <CngSelectField
          name='pkgType'
          label={translatedTextsObject.pkgType}
          disabled={disabled}
          size='small'
          items={[
            { text: 'Bale', value: 'BA' },
            { text: 'Base Box', value: 'BB' },
            { text: 'Bucket', value: 'BC' },
            { text: 'Bag', value: 'BG' },
            { text: 'Bulk', value: 'BN' },
            { text: 'Barrel', value: 'BR' },
            { text: 'Basket', value: 'BS' },
            { text: 'Box', value: 'BX' },
            { text: 'Carload ', value: 'C4' },
            { text: 'Case', value: 'CA' },
            { text: 'Container ', value: 'CH' },
            { text: 'Cylinder', value: 'CL' },
            { text: 'Crate', value: 'CP' },
            { text: 'Carton', value: 'CT' },
            { text: 'Drum', value: 'DR' },
            { text: 'Envelope', value: 'EV' },
            { text: 'Gross Barrels', value: 'GD' },
            { text: 'Hundred Boxes', value: 'HB' },
            { text: 'Keg', value: 'KE' },
            { text: 'Barge ', value: 'NB' },
            { text: 'Vehicle', value: 'NV' },
            { text: 'Piece', value: 'PC' },
            { text: 'Pallet(Lift)', value: 'PF' },
            { text: 'Package', value: 'PK' },
            { text: 'Pallete/Unit Load', value: 'PL' },
            { text: 'Rack', value: 'RA' },
            { text: 'Reel', value: 'RE' },
            { text: 'Roll', value: 'RL' },
            { text: 'Sheet', value: 'SH' },
            { text: 'Split Tanktruck', value: 'SK' },
            { text: 'Spool ', value: 'SO' },
            { text: 'ShelfPackage', value: 'SP' },
            { text: 'Skid ', value: 'SV' },
            { text: 'Tank ', value: 'TK' },
            { text: 'Unit', value: 'UN' }
          ]}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={4} md={2} xl={1} shouldHide={shouldHideMap?.pkgDimLength}>
        <CngTextField
          name='pkgDimLength'
          label={translatedTextsObject.pkgDimLength}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={4} md={2} xl={1} shouldHide={shouldHideMap?.pkgDimWidth}>
        <CngTextField
          name='pkgDimWidth'
          label={translatedTextsObject.pkgDimWidth}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={4} md={2} xl={1} shouldHide={shouldHideMap?.pkgDimHeight}>
        <CngTextField
          name='pkgDimHeight'
          label={translatedTextsObject.pkgDimHeight}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} md={2} xl={3} shouldHide={shouldHideMap?.pkgDimUom}>
        <CngSelectField
          name='pkgDimUom'
          label={translatedTextsObject.pkgDimUom}
          disabled={disabled}
          size='small'
          items={[
            { text: 'Inches', value: 'IN' },
            { text: 'Centimeter', value: 'CM' }
          ]}
        />
      </CngGridItem>
      <CngGridItem xs={12} md={4} xl={6} shouldHide={shouldHideMap?.commodityDescription}>
        <CngTextField
          name='commodityDescription'
          label={translatedTextsObject.commodityDescription}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={6} md={2} shouldHide={shouldHideMap?.grossWeight}>
        <CngTextField
          name='grossWeight'
          label={translatedTextsObject.grossWeight}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={6} md={2} shouldHide={shouldHideMap?.grossWeightUom}>
        <CngSelectField
          name='grossWeightUom'
          label={translatedTextsObject.grossWeightUom}
          disabled={disabled}
          size='small'
          items={[
            { text: 'Kilograms', value: 'KG' },
            { text: 'Pounds', value: 'LB' }
          ]}
        />
      </CngGridItem>
      <CngGridItem xs={12} md={4} xl={2} shouldHide={shouldHideMap?.freightClass}>
        <CngSelectField
          name='freightClass'
          label={translatedTextsObject.freightClass}
          disabled={disabled}
          size='small'
          items={[
            { text: 'N/A', value: 'N/A' },
            { text: '50', value: '50' },
            { text: '55', value: '55' },
            { text: '60', value: '60' },
            { text: '65', value: '65' },
            { text: '70', value: '70' },
            { text: '77.5', value: '77.5' },
            { text: '85', value: '85' },
            { text: '92.5', value: '92.5' },
            { text: '100', value: '100' },
            { text: '110', value: '110' },
            { text: '125', value: '125' },
            { text: '150', value: '150' },
            { text: '175', value: '175' },
            { text: '200', value: '200' },
            { text: '250', value: '250' },
            { text: '300', value: '300' },
            { text: '400', value: '400' },
            { text: '500', value: '500' }
          ]}
        />
      </CngGridItem>
    </Grid>
  )
}

const ShipmentSummaryFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default ShipmentSummaryFormProperties
