import React, { useState , useEffect} from 'react'
import { components, useServices } from 'cng-web-lib'
import UsProductMasterApiUrls from '../../../apiUrls/UsProductMasterApiUrls'
import NbciUsInvoiceApiUrls from '../../../apiUrls/NbciUsInvoiceApiUrls'
import NBCICanadaInvoiceApiUrls from '../../../apiUrls/NBCICanadaInvoiceApiUrls'
import { Box, Card, Grid, Typography } from '@material-ui/core'
import { FormProvider, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import useCustomYupValidationResolver from '../../../components/aciacehighway/hooks/useCustomYupValidationResolver'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'

const schema = Yup.object({
  fileType: Yup.string().required('This is required'),
  file: Yup.array().min(1)
})

const {
  button: { CngButton },
  dropzone: { CngFileUpload },
  form: {
    field: { CngSelectField }
  }
} = components

function UploadPage(props) {
  const { showNotification } = props
  const [loading, setLoading] = useState(false)

  const methods = useForm({
    defaultValues: { fileType: 'US', file: [] },
    resolver: useCustomYupValidationResolver(schema),
  })
  const file = methods.watch('file')
  const { errors } = methods.formState

  const { securedSendRequest } = useServices()

  function handleUpload(data) {
    setLoading(true)
    function getUrl(fileType) {
      switch (fileType) {
        case 'US':
          return UsProductMasterApiUrls.UPLOAD
        case 'CA':
          return UsProductMasterApiUrls.UPLOADCA
        case 'USP':
          return UsProductMasterApiUrls.UPLOADPARTY
        case 'CAP':
          return UsProductMasterApiUrls.UPLOADPARTYCA
        case 'USIN':
          return NbciUsInvoiceApiUrls.UPLOAD
        case 'USCA':
          return NBCICanadaInvoiceApiUrls.UPLOAD
      }
    }
    const fileForUserDetails = FileForUserGetUserDetails()
    const formData = new FormData()
    formData.append('file', data.file[0].file)
    formData.append('fileForUserDetails', fileForUserDetails ? JSON.stringify(fileForUserDetails) : '{}')

    securedSendRequest.execute(
      'POST',
      getUrl(data.fileType),
      formData,
      (response) => {
        if (response.status === 200) {
          const { errorMessages } = response.data

          if (!errorMessages) {
            showNotification('success', 'Uploaded successfully')

          } else {
            showNotification('error', errorMessages)
          }
        }
      },
      (error) => {
        console.error(error)
        showNotification('error', 'Something went wrong when uploading file(s)')
      },
      () => {
        setLoading(false)
        methods.setValue('file', [])
      },
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
  }

  return (
    <Card>
      <Box padding={2}>
        <FormProvider {...methods}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} lg={3}>
                  <CngSelectField
                    name='fileType'
                    label='File type'
                    items={[
                      { text: 'US Product Master', value: 'US' },
                      { text: 'CA Product Master', value: 'CA' },
                      { text: 'US Party Master', value: 'USP' },
                      { text: 'CA Party Master', value: 'CAP' },
                      { text: 'US Invoice', value: 'USIN' },
                      { text: 'CA Invoice', value: 'USCA' }
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <CngFileUpload
                accept={['.csv']}
                maxFiles={1}
                maxSize={6291456}
                files={file}
                onFileSelect={(file) => methods.setValue('file', file)}
                showFormFields={false}
                onDropRejected={(message) => showNotification('error', message)}
                moreActions={[
                  {
                    action: 'remove',
                    name: 'Remove',
                    icon: ['fal', 'trash']
                  }
                ]}
              />
              {errors?.file && (
                <Typography color='error' variant='body2'>
                  {errors.file.message}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container justify='flex-end'>
                <Grid item xs='auto'>
                  <CngButton disabled={!file || file.length == 0 || loading} onClick={methods.handleSubmit(handleUpload)}>
                    Upload
                  </CngButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormProvider>
      </Box>
    </Card>
  )
}

export default UploadPage
