import makeValidationSchema from './PackingListMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../../../constants/locale/key/NBCICanadaInvoice'
import Grid from '@material-ui/core/Grid'
import Switch from '../../../../components/Switch'
import { useFormContext } from 'react-hook-form'

const {
  form: {
    field: { CngSelectField, CngTextField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  otherRefNo: '',
  responsibleForCustoms: '',
  instructionsToBroker: '',
  containerized: 'N',
  billChargesTo: '',
  portOfLoading: '',
  flightDetails: '',
  placeOfDelivery: '',
  specialInstructions: '',
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { setValue, watch } = useFormContext()
  const containerized = watch('containerized')
  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const otherRefNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.PackingList.OTHER_REF_NO
    )
    const responsibleForCustoms = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.PackingList.RESPONSIBLE_FOR_CUSTOMS
    )
    const instructionsToBroker = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.PackingList.INSTRUCTIONS_TO_BROKER
    )
    const containerized = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.PackingList.CONTAINERIZED
    )
    const billChargesTo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.PackingList.BILL_CHARGES_TO
    )
    const portOfLoading = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.PackingList.PORT_OF_LOADING
    )
    const flightDetails = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.PackingList.FLIGHT_DETAILS
    )
    const placeOfDelivery = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.PackingList.PLACE_OF_DELIVERY
    )
    const specialInstructions = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.PackingList.SPECIAL_INSTRUCTIONS
    )

    return {
      otherRefNo,
      portOfLoading,
      responsibleForCustoms,
      flightDetails,
      instructionsToBroker,
      placeOfDelivery,
      billChargesTo,
      containerized,
      specialInstructions,
    }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.otherRefNo}>
        <CngTextField
          name='otherRefNo'
          label={translatedTextsObject.otherRefNo}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.portOfLoading}>
        <CngTextField
          name='portOfLoading'
          label={translatedTextsObject.portOfLoading}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.responsibleForCustoms}>
        <CngTextField
          name='responsibleForCustoms'
          label={translatedTextsObject.responsibleForCustoms}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.flightDetails}>
        <CngTextField
          name='flightDetails'
          label={translatedTextsObject.flightDetails}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.instructionsToBroker}>
        <CngSelectField
          name='instructionsToBroker'
          label={translatedTextsObject.instructionsToBroker}
          disabled={disabled}
          size='small'
          items={[
            { text: 'Arrange customs clearance', value: 'Y' },
            { text: 'In bond to destination', value: 'N' }
          ]}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.placeOfDelivery}>
        <CngTextField
          name='placeOfDelivery'
          label={translatedTextsObject.placeOfDelivery}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.billChargesTo}>
        <CngSelectField
          name='billChargesTo'
          label={translatedTextsObject.billChargesTo}
          disabled={disabled}
          size='small'
          items={[
            { text: 'Exporter', value: 'E' },
            { text: 'Sold To', value: 'S' },
            { text: 'Consignee', value: 'C' }
          ]}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.containerized}>
        <Switch
          name='containerized'
          checked={containerized === 'Y'}
          label={translatedTextsObject.containerized}
          disabled={disabled}
          onChange={(e) => setValue('containerized', e.target.checked ? 'Y' : 'N')}
          fullWidth
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.specialInstructions}>
        <CngTextField
          name='specialInstructions'
          label={translatedTextsObject.specialInstructions}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
    </Grid>
  )
}

const PackingListFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default PackingListFormProperties
