import moment from 'moment'
import React from 'react'
import { constants, useTranslation } from 'cng-web-lib'
import Namespace from '../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../constants/locale/key/NBCICanadaInvoice'
import NBCICanadaInvoiceApiUrls from '../../apiUrls/NBCICanadaInvoiceApiUrls'
import CngSection from '../../components/cngcomponents/CngSection'
import Table from '../../components/aciacehighway/Table'

const {
  filter: { EQUAL }
} = constants

function ActivityLog(props) {
  const { showNotification, id } = props
  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const activityLogTitle = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ACTIVITYLOG_TITLE
    )
    const createdDate = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CREATED_DATE
    )
    const loginUserId = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.LOGIN_USER_ID
    )
    const functionCode = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.FUNCTION_CODE
    )
    const auditDescription = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.AUDIT_DESC
    )

    return {
      activityLogTitle,
      createdDate,
      loginUserId,
      functionCode,
      auditDescription
    }
  }
  const columns = [
    {
      field: 'functionCode',
      title: translatedTextsObject.functionCode
    },
    {
      field: 'createdDate',
      title: translatedTextsObject.createdDate,
      render: (rowData) =>
        rowData.createdDate &&
        moment(rowData.createdDate).format('DD/MM/YYYY HH:mm:ss')
    },
    {
      field: 'userId',
      title: translatedTextsObject.loginUserId
    },
    {
      field: 'auditDescription',
      title: translatedTextsObject.auditDescription
    }
  ]

  return (
    <CngSection title={translatedTextsObject.activityLogTitle}>
      <Table
        columns={columns}
        compact
        fetch={{ url: NBCICanadaInvoiceApiUrls.GET_AUDIT_LOGS }}
        fetchFilters={[
          {
            field: 'keyReferenceNo',
            operator: EQUAL,
            value: 'NBCI_CA_INVOICE' + id
          }
        ]}
        showNotification={showNotification}
        variant='outlined'
      />
    </CngSection>
  )
}
export default ActivityLog
