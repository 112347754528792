import makeValidationSchema from './B13aFormMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../../../constants/locale/key/NBCICanadaInvoice'
import CheckboxButton from '../../../../components/button/CheckboxButton'
import Grid from '@material-ui/core/Grid'
import { useFormContext } from 'react-hook-form'

const {
  form: {
    field: { CngTextField, CngDateField, CngSelectField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  businessNo: '',
  exportLicenseNo: '',
  destinationCountry: '',
  transportDocno: '',
  custProofReportNo: '',
  goodsInspected: '',
  status: '',
  vesselName: '',
  dateOfExport: '',
  noOfPkgs: '',
  pkgsType: '',
  containerNo: '',
  frieghtChrgsToExit: '',
  exportRefNo: '',
  reasonForExport: '',
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { setValue, watch } = useFormContext()
  const goodsInspected = watch('goodsInspected')

  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const businessNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.BUSINESS_NO
    )
    const exportLicenseNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.EXPORT_LICENSE_NO
    )
    const destinationCountry = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.DESTINATION_COUNTRY
    )
    const transportDocno = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.TRANSPORT_DOCNO
    )
    const custProofReportNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.CUST_PROOF_REPORT_NO
    )
    const goodsInspected = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.GOODS_INSPECTED
    )
    const status = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.STATUS
    )
    const vesselName = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.VESSEL_NAME
    )
    const dateOfExport = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.DATE_OF_EXPORT
    )
    const noOfPkgs = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.NO_OF_PKGS
    )
    const pkgsType = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.PKGS_TYPE
    )
    const containerNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.CONTAINER_NO
    )
    const frieghtChrgsToExit = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.FRIEGHT_CHRGS_TO_EXIT
    )
    const exportRefNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.EXPORT_REF_NO
    )
    const reasonForExport = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.REASON_FOR_EXPORT
    )

    return {
      businessNo,
      exportLicenseNo,
      destinationCountry,
      transportDocno,
      custProofReportNo,
      goodsInspected,
      vesselName,
      dateOfExport,
      pkgsType,
      noOfPkgs,
      containerNo,
      exportRefNo,
      status,
      frieghtChrgsToExit,
      reasonForExport,
    }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.businessNo}>
        <CngTextField
          name='businessNo'
          label={translatedTextsObject.businessNo}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.exportLicenseNo}>
        <CngTextField
          name='exportLicenseNo'
          label={translatedTextsObject.exportLicenseNo}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.destinationCountry}>
        <CngTextField
          name='destinationCountry'
          label={translatedTextsObject.destinationCountry}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.transportDocno}>
        <CngTextField
          name='transportDocno'
          label={translatedTextsObject.transportDocno}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.custProofReportNo}>
        <CngTextField
          name='custProofReportNo'
          label={translatedTextsObject.custProofReportNo}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.goodsInspected}>
        <CheckboxButton
          name='goodsInspected'
          label={translatedTextsObject.goodsInspected}
          checked={goodsInspected === 'Y'}
          disabled={disabled}
          onChange={(event) => setValue('goodsInspected', event.target.checked ? 'Y' : 'N')}
          labelPlacement='start'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.vesselName}>
        <CngTextField
          name='vesselName'
          label={translatedTextsObject.vesselName}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.dateOfExport}>
        <CngDateField
          name='dateOfExport'
          label={translatedTextsObject.dateOfExport}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.pkgsType}>
        <CngSelectField
          name='pkgsType'
          label={translatedTextsObject.pkgsType}
          disabled={disabled}
          size='small'
          items={[
            { text: 'Carat', value: 'CD' },
            { text: 'Centiliter', value: 'C3' },
            { text: 'Centimeter', value: 'CM' },
            { text: 'Cubic Centimeter', value: 'CC' },
            { text: 'Cubic Decimeter', value: 'C8' },
            { text: 'Cubic Meters (Net)', value: 'CO' },
            { text: 'Curie', value: '4D' },
            { text: 'Deciliter', value: 'DL' },
            { text: 'Decimeter', value: 'DM' },
            { text: 'Dozen', value: 'DZ' },
            { text: 'Dozen Pair', value: 'DP' },
            { text: 'Each', value: 'EA' },
            { text: 'Gigabecquerel', value: 'G4' },
            { text: 'Gram', value: 'GR' },
            { text: 'Great Gross', value: 'GG' },
            { text: 'Gross', value: 'GS' },
            { text: 'Hectogram', value: 'HG' },
            { text: 'Hectoliter', value: 'H4' },
            { text: 'Hundred', value: 'HU' },
            { text: 'Joules', value: '86' },
            { text: 'Joule Per Kilogram', value: 'J2' },
            { text: 'Kilogram', value: 'KG' },
            { text: 'Kilovolt Amperes', value: 'K4' },
            { text: 'Kilowatt', value: 'K7' },
            { text: 'Kilowatt Hour', value: 'KH' },
            { text: 'Liter', value: 'LT' },
            { text: 'Millicurie', value: 'MU' },
            { text: 'Milligram', value: 'ME' },
            { text: 'Milliliter', value: 'MM' },
            { text: 'Milliliter', value: 'ML' },
            { text: 'Megabecquerel', value: '4N' },
            { text: 'Meter', value: 'MR' },
            { text: 'Metric Ton', value: 'MP' },
            { text: 'Pound', value: 'LB' },
            { text: 'Piece', value: 'PC' },
            { text: 'Pair', value: 'PR' },
            { text: 'Square Decimeter', value: 'D3' },
            { text: 'Square Centimeter', value: 'SC' },
            { text: 'Square Meter', value: 'SM' },
            { text: 'Thousand', value: 'TH' },
            { text: 'Watt', value: '99' }
          ]}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.noOfPkgs}>
        <CngTextField
          name='noOfPkgs'
          label={translatedTextsObject.noOfPkgs}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.containerNo}>
        <CngTextField
          name='containerNo'
          label={translatedTextsObject.containerNo}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.exportRefNo}>
        <CngTextField
          name='exportRefNo'
          label={translatedTextsObject.exportRefNo}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.status}>
        <CngSelectField
          name='status'
          label={translatedTextsObject.status}
          disabled={disabled}
          size='small'
          items={[
            { text: 'Exporter (Shipper)', value: 'E' },
            { text: 'Other', value: 'o' }
          ]}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.frieghtChrgsToExit}>
        <CngTextField
          name='frieghtChrgsToExit'
          label={translatedTextsObject.frieghtChrgsToExit}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.reasonForExport}>
        <CngTextField
          name='reasonForExport'
          label={translatedTextsObject.reasonForExport}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
    </Grid>
  )
}

const B13aFormFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default B13aFormFormProperties

