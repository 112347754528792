import React from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../../constants/locale/key/NBCICanadaInvoice'
import CngSection from '../../../components/cngcomponents/CngSection'
import CngField from '../../../components/cngcomponents/CngField'
import { Box, Card, Divider, Grid, Typography, useTheme } from '@material-ui/core'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function ImportDeclFormViewContent(props) {
  const { importDeclForm } = props

  const theme = useTheme()
  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const transactionNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ImportDeclForm.TRANSACTION_NO
    )
    const containerNo1 = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ImportDeclForm.CONTAINER_NO1
    )
    const containerNo2 = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ImportDeclForm.CONTAINER_NO2
    )
    const containerNo3 = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ImportDeclForm.CONTAINER_NO3
    )
    const flightNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ImportDeclForm.FLIGHT_NO
    )
    const trailerNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ImportDeclForm.TRAILER_NO
    )
    const isDairy = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ImportDeclForm.IS_DAIRY
    )
    const isProcessFoodVeg = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ImportDeclForm.IS_PROCESS_FOOD_VEG
    )
    const isHoney = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ImportDeclForm.IS_HONEY
    )
    const isMapleProd = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ImportDeclForm.IS_MAPLE_PROD
    )
    const isSeeds = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ImportDeclForm.IS_SEEDS
    )
    const isFeed = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ImportDeclForm.IS_FEED
    )
    const isFortizer = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ImportDeclForm.IS_FORTIZER
    )
    const prodDetails = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ProdDetails.TITLE
    )
    const purchaseOrderNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ProdDetails.PURCHASE_ORDER_NO
    )
    const commonName = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ProdDetails.COMMON_NAME
    )
    const brandName = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ProdDetails.BRAND_NAME
    )
    const gradeCategory = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ProdDetails.GRADE_CATEGORY
    )
    const noOfContainers = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ProdDetails.NO_OF_CONTAINERS
    )
    const contDetails = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ContDetails.TITLE
    )
    const typeNetContent = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ContDetails.TYPE_NET_CONTENT
    )
    const quantity = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ContDetails.QUANTITY
    )
    const labelNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ContDetails.LABEL_NO
    )
    const importPurpose = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ContDetails.IMPORT_PURPOSE
    )
    const documentation = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ContDetails.DOCUMENTATION
    )
    const registrationNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ContDetails.REGISTRATION_NO
    )

    return {
      transactionNo,
      containerNo1,
      containerNo2,
      containerNo3,
      flightNo,
      trailerNo,
      isDairy,
      isProcessFoodVeg,
      isHoney,
      isMapleProd,
      isSeeds,
      isFeed,
      isFortizer,
      prodDetails,
      purchaseOrderNo,
      commonName,
      brandName,
      gradeCategory,
      noOfContainers,
      contDetails,
      typeNetContent,
      quantity,
      labelNo,
      importPurpose,
      documentation,
      registrationNo,
    }
  }

  function renderProductTypeField(name) {
    function getColor() {
      return importDeclForm[name] === 'Y'
        ? theme.palette.primary.main
        : theme.palette.error.main
    }

    function getIcon() {
      return importDeclForm[name] === 'Y' ? 'check' : 'ban'
    }

    return (
      <Grid alignItems='center' container spacing={1}>
        <Grid item xs='auto'>
          <FontAwesomeIcon
            color={getColor()}
            fixedWidth
            icon={['fal', getIcon()]}
          />
        </Grid>
        <Grid item xs>
          <Typography>
            {translatedTextsObject[name]}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card variant='outlined'>
          <Box padding={2}>
            <Grid container spacing={4}>
              <Grid item>
                {renderProductTypeField('isDairy')}
              </Grid>
              <Grid item>
                {renderProductTypeField('isProcessFoodVeg')}
              </Grid>
              <Grid item>
                {renderProductTypeField('isHoney')}
              </Grid>
              <Grid item>
                {renderProductTypeField('isMapleProd')}
              </Grid>
              <Grid item>
                {renderProductTypeField('isSeeds')}
              </Grid>
              <Grid item>
                {renderProductTypeField('isFeed')}
              </Grid>
              <Grid item>
                {renderProductTypeField('isFortizer')}
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.transactionNo}>
              {importDeclForm.transactionNo}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.flightNo}>
              {importDeclForm.flightNo}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.trailerNo}>
              {importDeclForm.trailerNo}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.containerNo1}>
              {importDeclForm.containerNo1}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.containerNo2}>
              {importDeclForm.containerNo2}
            </CngField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CngField label={translatedTextsObject.containerNo3}>
              {importDeclForm.containerNo3}
            </CngField>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.prodDetails}>
          {!_.isEmpty(importDeclForm.prodDetails) ? (
            <Grid container spacing={2}>
              {importDeclForm.prodDetails.map((product, index) => (
                <React.Fragment key={product.id}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={3}>
                        <CngField label={translatedTextsObject.purchaseOrderNo}>
                          {product.purchaseOrderNo}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <CngField label={translatedTextsObject.commonName}>
                          {product.commonName}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <CngField label={translatedTextsObject.brandName}>
                          {product.brandName}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <CngField label={translatedTextsObject.gradeCategory}>
                          {product.gradeCategory}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <CngField label={translatedTextsObject.noOfContainers}>
                          {product.noOfContainers}
                        </CngField>
                      </Grid>
                    </Grid>
                  </Grid>
                  {index + 1 < importDeclForm.prodDetails.length && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          ) : (
            <Typography variant='body2' color='textSecondary'>
              No records to display
            </Typography>
          )}
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.contDetails}>
          {!_.isEmpty(importDeclForm.contDetails) ? (
            <Grid container spacing={2}>
              {importDeclForm.contDetails.map((container, index) => (
                <React.Fragment key={container.id}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={3}>
                        <CngField label={translatedTextsObject.typeNetContent}>
                          {container.typeNetContent}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <CngField label={translatedTextsObject.quantity}>
                          {container.quantity}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <CngField label={translatedTextsObject.labelNo}>
                          {container.labelNo}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <CngField label={translatedTextsObject.importPurpose}>
                          {container.importPurpose}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <CngField label={translatedTextsObject.documentation}>
                          {container.documentation}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <CngField label={translatedTextsObject.registrationNo}>
                          {container.registrationNo}
                        </CngField>
                      </Grid>
                    </Grid>
                  </Grid>
                  {index + 1 < importDeclForm.contDetails.length && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          ) : (
            <Typography variant='body2' color='textSecondary'>
              No records to display
            </Typography>
          )}
        </CngSection>
      </Grid>
    </Grid>
  )
}

export default ImportDeclFormViewContent
