import makeValidationSchema from './CiCargoSummaryMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from '../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../constants/locale/key/NBCICanadaInvoice'
import Grid from '@material-ui/core/Grid'

const {
  form: {
    field: { CngSelectField, CngTextField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  reference: '',
  referenceNumber: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const reference = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CiCargoSummary.REFERENCE
    )
    const referenceNumber = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CiCargoSummary.REFERENCE_NUMBER
    )

    return { reference, referenceNumber }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.reference}>
        <CngSelectField
          name='reference'
          label={translatedTextsObject.reference}
          disabled={disabled}
          size='small'
          items={[
            { text: 'Cargo Control Number', value: 'XC' },
            { text: 'Bill of Lading', value: 'BM' },
            { text: 'Master Bill of Lading', value: 'MB' },
            { text: 'Container Number', value: 'CN' }
          ]}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.referenceNumber}>
        <CngTextField
          name='referenceNumber'
          label={translatedTextsObject.referenceNumber}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
    </Grid>
  )
}

const CiCargoSummaryFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default CiCargoSummaryFormProperties
