import React, { useContext } from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../../constants/locale/key/NBCICanadaInvoice'
import { CAInvoiceLookupsContext } from '../lookups/CAInvoiceLookupsContext'
import CngField from '../../../components/cngcomponents/CngField'
import Grid from '@material-ui/core/Grid'
import _ from 'lodash'

function BolFormViewContent(props) {
  const { bolForm } = props

  const { getLookupValue } = useContext(CAInvoiceLookupsContext)
  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const containerNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.BolForm.CONTAINER_NO
    )
    const freightCharges = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.BolForm.FREIGHT_CHARGES
    )
    const placeOfLadding = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.BolForm.PLACE_OF_LADDING
    )
    const deliveryInstructions = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.BolForm.DELIVERY_INSTRUCTIONS
    )
    const codAmount = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.BolForm.COD_AMOUNT
    )
    const currency = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.BolForm.CURRENCY
    )
    const declareValueOfShipment = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.BolForm.DECLARE_VALUE_OF_SHIPMENT
    )
    const chargeTo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.BolForm.CHARGE_TO
    )
    const releaseValue = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.BolForm.RELEASE_VALUE
    )

    return {
      containerNo,
      codAmount,
      freightCharges,
      declareValueOfShipment,
      placeOfLadding,
      releaseValue,
      currency,
      deliveryInstructions,
      chargeTo,
    }
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.containerNo}>
          {bolForm.containerNo}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.codAmount}>
          {bolForm.codAmount}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.freightCharges}>
          {getLookupValue('freightCharges', bolForm.freightCharges)}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.declareValueOfShipment}>
          {bolForm.declareValueOfShipment}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.placeOfLadding}>
          {bolForm.placeOfLadding}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.releaseValue}>
          {bolForm.releaseValue}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.currency}>
          {bolForm.currency}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.deliveryInstructions}>
          {bolForm.deliveryInstructions}
        </CngField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <CngField label={translatedTextsObject.chargeTo}>
          {bolForm.chargeTo}
        </CngField>
      </Grid>
    </Grid>
  )
}

export default BolFormViewContent
