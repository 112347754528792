import React, { useCallback, useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../../constants/locale/key/NBCICanadaInvoice'
import CngSection from '../../../components/cngcomponents/CngSection'
import AdditionalFormItem from './AdditionalFormItem'
import { Box, Grid } from '@material-ui/core'
import NaftaFormSection from './NaftaForm/NaftaFormSection'
import BolFormSection from './BolForm/BolFormSection'
import PackingListSection from './PackingList/PackingListSection'
import B13aFormSection from './B13aForm/B13aFormSection'
import CfiaFormSection from './CfiaForm/CfiaFormSection'
import ImportDeclFormSection from './ImportDeclForm/ImportDeclFormSection'
import { useFormContext } from 'react-hook-form'

const { CngDialog } = components

function AdditionalFormInformationSection({ setAddiFormsDirty }) {
  const [dialog, setDialog] = useState({ key: null, open: false })
  const { setValue, watch } = useFormContext()

  const naftaForm = watch('naftaForm')
  const bolForm = watch('bolForm')
  const packingList = watch('packingList')
  const b13aForm = watch('b13aForm')
  const cfiaForm = watch('cfiaForm')
  const importDeclForm = watch('importDeclForm')

  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const naftaForm = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.NaftaForm.TITLE
    )
    const bolForm = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.BolForm.TITLE
    )
    const packingList = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.PackingList.TITLE
    )
    const b13aForm = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.TITLE
    )
    const cfiaForm = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.TITLE
    )
    const importDeclForm = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ImportDeclForm.TITLE
    )

    return {
      naftaForm,
      bolForm,
      packingList,
      b13aForm,
      cfiaForm,
      importDeclForm,
    }
  }

  function handleCloseDialog() {
    setDialog({ key: null, open: false })
  }

  function handleChange(fieldName, data) {
    setValue(fieldName, data)
    handleCloseDialog()
    setAddiFormsDirty(true)
  }

  const renderForm = useCallback(() => {
    switch (dialog.key) {
      case 'naftaForm':
        return (
          <NaftaFormSection
            naftaForm={naftaForm}
            onClose={handleCloseDialog}
            onSubmit={(data) => handleChange('naftaForm', data)}
          />
        )
      case 'bolForm':
        return (
          <BolFormSection
            bolForm={bolForm}
            onClose={handleCloseDialog}
            onSubmit={(data) => handleChange('bolForm', data)}
          />
        )
      case 'packingList':
        return (
          <PackingListSection
            packingList={packingList}
            onClose={handleCloseDialog}
            onSubmit={(data) => handleChange('packingList', data)}
          />
        )
      case 'b13aForm':
        return (
          <B13aFormSection
            b13aForm={b13aForm}
            onClose={handleCloseDialog}
            onSubmit={(data) => handleChange('b13aForm', data)}
          />
        )
      case 'cfiaForm':
        return (
          <CfiaFormSection
            cfiaForm={cfiaForm}
            onClose={handleCloseDialog}
            onSubmit={(data) => handleChange('cfiaForm', data)}
          />
        )
      case 'importDeclForm':
        return (
          <ImportDeclFormSection
            importDeclForm={importDeclForm}
            onClose={handleCloseDialog}
            onSubmit={(data) => handleChange('importDeclForm', data)}
          />
        )
      default:
        return null
    }
  }, [dialog.key])

  function getDialogTitle(key) {
    switch (key) {
      case 'naftaForm':
        return translatedTextsObject.naftaForm
      case 'bolForm':
        return translatedTextsObject.bolForm
      case 'packingList':
        return translatedTextsObject.packingList
      case 'b13aForm':
        return translatedTextsObject.b13aForm
      case 'cfiaForm':
        return translatedTextsObject.cfiaForm
      case 'importDeclForm':
        return translatedTextsObject.importDeclForm
      default:
        return ''
    }
  }

  return (
    <>
      <CngSection title='Additional Form Information'>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <AdditionalFormItem
              label={getDialogTitle('naftaForm')}
              data={naftaForm}
              onManage={() => setDialog({ key: 'naftaForm', open: true })}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AdditionalFormItem
              label={getDialogTitle('bolForm')}
              data={bolForm}
              onManage={() => setDialog({ key: 'bolForm', open: true })}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AdditionalFormItem
              label={getDialogTitle('packingList')}
              data={packingList}
              onManage={() => setDialog({ key: 'packingList', open: true })}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AdditionalFormItem
              label={getDialogTitle('b13aForm')}
              data={b13aForm}
              onManage={() => setDialog({ key: 'b13aForm', open: true })}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AdditionalFormItem
              label={getDialogTitle('cfiaForm')}
              data={cfiaForm}
              onManage={() => setDialog({ key: 'cfiaForm', open: true })}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AdditionalFormItem
              label={getDialogTitle('importDeclForm')}
              data={importDeclForm}
              onManage={() => setDialog({ key: 'importDeclForm', open: true })}
            />
          </Grid>
        </Grid>
      </CngSection>
      <CngDialog
        customDialogContent={<Box padding={2}>{renderForm()}</Box>}
        dialogTitle={`Manage ${getDialogTitle(dialog.key)}`}
        fullWidth
        maxWidth='md'
        onClose={handleCloseDialog}
        open={dialog.open && dialog.key !== null}
        shouldShowCloseButton
      />
    </>
  )
}

export default AdditionalFormInformationSection
