import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  const regexAlphaNumeric =
    ValidationRegex.regexAlphaNumeric
  const errMsgAlphaNumeric =
    'Invalid Value - Allowed Alphanumeric, spaces, and /,+-$#@%&!^_:;.?()[]{}\'"'
  const regexDigitWithDecimal = '^[0-9]*.[0-9]*$'
  const errMsgDigitWithDecimal =
    'Invalid Value - Allowed Digits with/without decimal'
  const regexAlphaNum = '^[a-zA-Z0-9]*$'
  const errMsgAlphaNum = 'Invalid Value - Allowed Alphanumeric'

  const validateStringField = (maxLength, regexPattern, errorMsg) =>
    Yup.string()
      .nullable()
      .matches(regexPattern, errorMsg)
      .max(
        maxLength,
        'Field can contain maximum of ' + maxLength + ' characters'
      )

  return Yup.object({
    etaDateTime: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    cbsaReleaseOff: validateStringField(50, regexAlphaNum, errMsgAlphaNum),
    deliveryAddress: validateStringField(
      200,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ),
    meatShipment: validateStringField(
      50,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ),
    unmarkMeatShip: validateStringField(
      50,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ),
    quantity: validateStringField(
      50,
      regexDigitWithDecimal,
      errMsgDigitWithDecimal
    ),
    driverWaiting: Yup.string().nullable(),
    isEdi: Yup.string().nullable(),
    transactionNo: validateStringField(
      50,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ),
    prodDesc: validateStringField(30, regexAlphaNumeric, errMsgAlphaNumeric),
    voyageNo: validateStringField(17, regexAlphaNum, errMsgAlphaNum),
    entryPoint: validateStringField(50, regexAlphaNum, errMsgAlphaNum),
    blNumber: validateStringField(50, regexAlphaNum, errMsgAlphaNum),
    comments: validateStringField(200, regexAlphaNumeric, errMsgAlphaNumeric),
    payerName: validateStringField(60, regexAlphaNumeric, errMsgAlphaNumeric),
    cfiaClientAcctNo: validateStringField(60, regexAlphaNum, errMsgAlphaNum),
    sendAddressInd: Yup.string().nullable()
  })
}

export default makeValidationSchema
