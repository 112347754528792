import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React , { useEffect, useRef, useState } from 'react'
import { components, DataFlattener, useServices, useTranslation } from 'cng-web-lib'
import pathMap from 'src/paths/pathMap'
import NBCICanadaInvoiceApiUrls from 'src/apiUrls/NBCICanadaInvoiceApiUrls'
import PrintFlagDialog from 'src/views/nbcicanadainvoice/PrintFlagDialog'
import withFormState from '../../components/aciacehighway/hocs/withFormState'
import { useFormContext } from 'react-hook-form'
import Namespace from '../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../constants/locale/key/NBCICanadaInvoice'
import AlertDialog from '../../components/aciacehighway/AlertDialog'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'

const {
  button: { CngButton },
  form: { CngEditForm }
} = components

function EditPage(props) {
  const {
    history,
    id,
    invoiceData,
    onSaveContinueNextStep,
    onContinueNextStep,
    // onDiscard,
    // onSetInvoiceData,
    onSetLoading,
    showNotification,
    invoiceIsDirty,
    setInvoiceIsDirty
  } = props

  const { updateRecord } = useServices()
  const [printFlagDialog, setPrintFlagDialog] = useState({ open: false, manifest: null })
  const onSuccessCallback = useRef(() => {})

  const [printAlertDialog, setPrintAlertDialog] = useState({
    open: false,
    manifest: null
  })

  function prePrint(data) {
    if (invoiceIsDirty) {
      setPrintAlertDialog({ open: true, manifest: data })
    } else {
      setPrintFlagDialog({ open: true, manifest: data })
    }
  }
  function handleSaveInvoice(data) {
    onSetLoading(true)

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      data.fileForUserId = fileForUserDetails.fileForUserId;
      data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }
    updateRecord.execute(
      NBCICanadaInvoiceApiUrls.PUT,
      FormProperties.toServerDataFormat(data),
      (response) => {
        showNotification('success', 'Invoice updated.')
        response.invDetails = response.invDetails.map(
          (invoice, index) => ({ ...invoice, _id: index })
        )
        response.invSummary = response.invSummary.map(
          (summary, index) => ({ ...summary, _id: index })
        )
        invoiceData.current = response
        setPrintFlagDialog({ open: true, manifest: invoiceData.current })
      },
      (error) => {
        const { data } = error.response
        if (data && data.errorMessages) {
          showNotification('error', data.errorMessages)
        }
      },
      () => {
        onSetLoading(false)
      }
    )
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CngEditForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            showNotification={showNotification}
            renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
              <FormProperties.Fields
                showNotification={showNotification}
                shouldHideMap={shouldHideMap}
                onSetLoading={onSetLoading}
                setInvoiceIsDirty={setInvoiceIsDirty}
                invoiceData={invoiceData}
              />
            )}
            formikProps={FormProperties.formikProps}
            toClientDataFormat={FormProperties.toClientDataFormat}
            toServerDataFormat={FormProperties.toServerDataFormat}
            fetch={{
              url: NBCICanadaInvoiceApiUrls.GET,
              onPreSuccess: (serverData) => {
                invoiceData.current = serverData
              }
            }}
            update={{
              url: NBCICanadaInvoiceApiUrls.PUT,
              onPostSubmitSuccess: (e) => {
                invoiceData.current = e
                onSuccessCallback.current()
              }
            }}
            id={id}
            renderButtonSection={(_, loading) => (
              <EditPageButtonSection
                history={history}
                loading={loading}
                invoiceData={invoiceData.current}
                // onDiscard={onDiscard}
                onPrint={(data) => prePrint(data)}
                onSetLoading={onSetLoading}
                onSaveAndContinue={() => {
                  onSuccessCallback.current = onSaveContinueNextStep
                }}
                onSkip={onContinueNextStep}
                showNotification={showNotification}
                setInvoiceIsDirty={setInvoiceIsDirty}
              />
            )}
          />
        </Grid>
      </Grid>
      <PrintFlagDialog
        openDialog={printFlagDialog}
        onClose={() => setPrintFlagDialog({ open: false, manifest: null })}
        showNotification={showNotification}
      />
      <AlertDialog
        cancelLabel='Continue editing'
        confirmLabel='Yes, Save and Print'
        open={printAlertDialog.open}
        onClose={() => setPrintAlertDialog({ open: false, manifest: null })}
        onCancel={() => setPrintAlertDialog({ open: false, manifest: null })}
        onConfirm={() => {
          setPrintAlertDialog({ open: false, manifest: null })
          handleSaveInvoice(printAlertDialog.manifest)
        }}
        title='Alert'
      >
        Changes have not been saved. Do you want to proceed with saving then print?
      </AlertDialog>
    </>
  )
}

function EditPageButtonSection(props) {
  const {
    history,
    loading,
    invoiceData,
    // onDiscard,
    onPrint,
    onSetLoading,
    onSaveAndContinue,
    onSkip,
    showNotification,
    setInvoiceIsDirty
  } = props
  const { securedSendRequest } = useServices()
  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const {
    reset,
    getValues, 
    formState: { isSubmitting }
  } = useFormContext()

  const [confirmDialog, setConfirmDialog] = useState(false)

  useEffect(() => {
    onSetLoading(isSubmitting)
  }, [isSubmitting])

  function makeTranslatedTextsObject() {
    const submitSuccessMsg = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.SUBMIT_SUCCESS_MSG
    )

    return { submitSuccessMsg }
  }

  function handleSubmit() {
    onSetLoading(true)

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      invoiceData.fileForUserId = fileForUserDetails.fileForUserId;
      invoiceData.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      invoiceData.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    securedSendRequest.execute(
      'POST',
      NBCICanadaInvoiceApiUrls.SUBMIT,
      DataFlattener.unflatten(invoiceData),
      (response) => {
        var data = response.data
        var errorMessages = data.errorMessages
        if (response.status == 200 && data != null && errorMessages == null) {
          showNotification('success', translatedTextsObject.submitSuccessMsg)
          history.push(pathMap.N_B_C_I_CANADA_INVOICE_LIST_VIEW)
        } else {
          showNotification('error', errorMessages)
        }
      },
      (error) => console.error(error),
      () => onSetLoading(false)
    )
  }

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={isSubmitting}
            onClick={() => setConfirmDialog(true)}
            size='medium'
          >
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton
                color='secondary'
                disabled={loading || isSubmitting}
                onClick={onSkip}
                size='medium'
              >
                Skip
              </CngButton>
            </Grid>
            {/* <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={isSubmitting}
                onClick={handleSubmit}
                size='medium'
              >
                Submit
              </CngButton>
            </Grid> */}
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={isSubmitting}
                onClick={() => onPrint(getValues())}
                size='medium'
              >
                Print
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={isSubmitting}
                type='submit'
                size='medium'
              >
                Save as draft
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={isSubmitting}
                type='submit'
                onClick={onSaveAndContinue}
                size='medium'
              >
                Save &amp; continue
              </CngButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AlertDialog
        cancelLabel='Continue editing'
        confirmLabel='Yes, discard'
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        onCancel={() => setConfirmDialog(false)}
        onConfirm={() => {
          reset()
          setConfirmDialog(false)
          setInvoiceIsDirty(false)
        }}
        title='Discard'
      >
        All progress in this session will be lost and cant't be restored. Are
        you sure about this?
      </AlertDialog>
    </>
  )
}

export default withFormState(EditPage)
