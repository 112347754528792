import React, { useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../../constants/locale/key/NBCICanadaInvoice'
import LocalTable from '../../../components/aciacehighway/LocalTable'
import { Box, Card, CardHeader } from '@material-ui/core'
import InvoiceDetailsViewContent from '../invoiceDetails/view/InvoiceDetailsViewContent'

const {
  button: { CngButton },
  CngDialog
} = components

function InvoiceDetailsTable(props) {
  const { data } = props

  const [dialog, setDialog] = useState({ open: false, invoiceDetail: null })
  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const invoiceDetails = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.TITLE
    )
    const partNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.PART_NO
    )
    const description = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.DESCRIPTION
    )
    const hsCode = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.HS_CODE
    )
    const qtyInvoiced = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.QTY_INVOICED
    )
    const unitPrice = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.UNIT_PRICE
    )
    const lineItemTotal = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvDetails.LINE_ITEM_TOTAL
    )

    return {
      invoiceDetails,
      partNo,
      description,
      hsCode,
      qtyInvoiced,
      unitPrice,
      lineItemTotal,
    }
  }

  const columns = [
    {
      field: 'partNo',
      sortKey: 'partNo',
      title: translatedTextsObject.partNo,
      filterConfig: { type: 'textfield' }
    },
    {
      field: 'description',
      sortKey: 'descriptionn',
      title: translatedTextsObject.description,
      filterConfig: { type: 'textfield' }
    },
    {
      field: 'hsCode',
      sortKey: 'hsCode',
      title: translatedTextsObject.hsCode,
      filterConfig: { type: 'textfield' }
    },
    {
      field: 'qtyInvoiced',
      sortKey: 'qtyInvoiced',
      title: translatedTextsObject.qtyInvoiced,
      filterConfig: { type: 'textfield' }
    },
    {
      field: 'unitPrice',
      sortKey: 'unitPrice',
      title: translatedTextsObject.unitPrice,
      filterConfig: { type: 'textfield' }
    },
    {
      field: 'lineItemTotal',
      sortKey: 'lineItemTotal',
      title: translatedTextsObject.lineItemTotal,
      filterConfig: { type: 'textfield' }
    }
  ]

  return (
    <>
      <Card variant='outlined'>
        <CardHeader
          title={translatedTextsObject.invoiceDetails}
          titleTypographyProps={{ style: { fontWeight: 600 } }}
        />
        <LocalTable
          columns={columns}
          data={data}
          onRowClick={(data) => setDialog({ open: true, invoiceDetail: data })}
        />
      </Card>
      <CngDialog
        customDialogContent={
          <Box padding={2}>
            <InvoiceDetailsViewContent invoiceDetail={dialog.invoiceDetail} />
          </Box>
        }
        dialogAction={
          <CngButton onClick={() => setDialog({ open: false, invoiceDetail: null })}>
            OK
          </CngButton>
        }
        dialogTitle='View line item'
        maxWidth='md'
        onClose={() => setDialog({ open: false, invoiceDetail: null })}
        open={dialog.open}
        shouldShowCloseButton
      />
    </>
  )
}

export default InvoiceDetailsTable
