import React, { useState } from 'react'
import { components, useServices, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../../constants/locale/key/NBCICanadaInvoice'
import NBCICanadaInvoiceApiUrls from '../../../apiUrls/NBCICanadaInvoiceApiUrls'
import CngSection from '../../../components/cngcomponents/CngSection'
import { Box, Card, Grid, Typography } from '@material-ui/core'
import { format } from 'date-fns'
import AdditionalFormItem from './AdditionalFormItem'
import NaftaFormViewContent from './NaftaFormViewContent'
import BolFormViewContent from './BolFormViewContent'
import PackingListFormViewContent from './PackingListFormViewContent'
import B13aFormViewContent from './B13aFormViewContent'
import CfiaFormViewContent from './CfiaFormViewContent'
import ImportDeclFormViewContent from './ImportDeclFormViewContent'
import _ from 'lodash'

const {
  button: { CngButton },
  CngDialog
} = components

function AdditionalFormInfoSection(props) {
  const { invoiceData, showNotification } = props

  const [viewDialog, setViewDialog] = useState({
    open: false,
    formKey: '',
    form: []
  })
  const { securedSendRequest } = useServices()
  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const naftaForm = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.NaftaForm.TITLE
    )
    const bolForm = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.BolForm.TITLE
    )
    const packingList = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.PackingList.TITLE
    )
    const b13aForm = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.B13aForm.TITLE
    )
    const cfiaForm = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CfiaForm.TITLE
    )
    const importDeclForm = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ImportDeclForm.TITLE
    )

    return {
      naftaForm,
      bolForm,
      packingList,
      b13aForm,
      cfiaForm,
      importDeclForm,
    }
  }

  function handleDownload({ url, prefix }) {
    const config = { responseType: 'blob' } 
    securedSendRequest.execute(
      'POST',
      url,
      invoiceData,
      (response) => {
        const blob = new Blob([response.data])
        const curDate = format(new Date(), 'yyyyMMddHHmmss')
        const a = document.createElement('a')

        a.href = window.URL.createObjectURL(blob)
        a.download = `${prefix}_${curDate}.pdf`
        a.click()
      },
      (error) => {
        console.error(error)
        showNotification('error', 'Download failed')
      },
      undefined,
      config,
      null
    )
  }

  function renderDialogContent(form) {
    switch (viewDialog.formKey) {
      case 'naftaForm':
        return <NaftaFormViewContent naftaForm={form} />
      case 'bolForm':
        return <BolFormViewContent bolForm={form} />
      case 'packingList':
        return <PackingListFormViewContent packingList={form} />
      case 'b13aForm':
        return <B13aFormViewContent b13aForm={form} />
      case 'cfiaForm':
        return <CfiaFormViewContent cfiaForm={form} />
      case 'importDeclForm':
        return <ImportDeclFormViewContent importDeclForm={form} />

      default:
        return (
          <Typography variant='body2' color='textSecondary'>
            No records to display
          </Typography>
        )
    }
  }

  return (
    <>
      <CngSection title='Additional Form Information'>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <AdditionalFormItem
              label={translatedTextsObject.naftaForm}
              data={invoiceData.naftaForm}
              isViewOnly
              onClick={() => {
                if (_.isEmpty(invoiceData.naftaForm)) return

                setViewDialog({
                  open: true,
                  formKey: 'naftaForm',
                  form: invoiceData.naftaForm
                })
              }}
              onDownload={() =>
                handleDownload({
                  url: NBCICanadaInvoiceApiUrls.PRINT_USMCACUSMA,
                  prefix: 'NBCI_USMCACUSMA'
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AdditionalFormItem
              label={translatedTextsObject.b13aForm}
              data={invoiceData.b13aForm}
              isViewOnly
              onClick={() => {
                if (_.isEmpty(invoiceData.b13aForm)) return

                setViewDialog({
                  open: true,
                  formKey: 'b13aForm',
                  form: invoiceData.b13aForm
                })
              }}
              onDownload={() => {}}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AdditionalFormItem
              label={translatedTextsObject.cfiaForm}
              data={invoiceData.cfiaForm}
              isViewOnly
              onClick={() => {
                if (_.isEmpty(invoiceData.cfiaForm)) return

                setViewDialog({
                  open: true,
                  formKey: 'cfiaForm',
                  form: invoiceData.cfiaForm
                })
              }}
              onDownload={() => {}}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AdditionalFormItem
              label={translatedTextsObject.bolForm}
              data={invoiceData.bolForm}
              isViewOnly
              onClick={() => {
                if (_.isEmpty(invoiceData.bolForm)) return

                setViewDialog({
                  open: true,
                  formKey: 'bolForm',
                  form: invoiceData.bolForm
                })
              }}
              onDownload={() =>
                handleDownload({
                  url: NBCICanadaInvoiceApiUrls.PRINT_BOL,
                  prefix: 'NBCI_BOL'
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AdditionalFormItem
              label={translatedTextsObject.packingList}
              data={invoiceData.packingList}
              isViewOnly
              onClick={() => {
                if (_.isEmpty(invoiceData.packingList)) return

                setViewDialog({
                  open: true,
                  formKey: 'packingList',
                  form: invoiceData.packingList
                })
              }}
              onDownload={() =>
                handleDownload({
                  url: NBCICanadaInvoiceApiUrls.PRINT_PL,
                  prefix: 'NBCI_PL'
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AdditionalFormItem
              label={translatedTextsObject.importDeclForm}
              data={invoiceData.importDeclForm}
              isViewOnly
              onClick={() => {
                if (_.isEmpty(invoiceData.importDeclForm)) return

                setViewDialog({
                  open: true,
                  formKey: 'importDeclForm',
                  form: invoiceData.importDeclForm
                })
              }}
              onDownload={() => {}}
            />
          </Grid>
        </Grid>
      </CngSection>
      <CngDialog
        customDialogContent={
          <Box padding={2}>
            <Grid container spacing={2}>
              {viewDialog.form.map((form) => (
                <Grid key={form.id} item xs={12}>
                  <Card variant='outlined'>
                    <Box padding={2}>{renderDialogContent(form)}</Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        }
        dialogAction={
          <CngButton onClick={() => setViewDialog({ open: false, formKey: '', form: [] })}>
            OK
          </CngButton>
        }
        dialogTitle={translatedTextsObject?.[viewDialog.formKey] || ''}
        maxWidth='md'
        onClose={() => setViewDialog({ open: false, formKey: '', form: [] })}
        open={viewDialog.open}
        shouldShowCloseButton
      />
    </>
  )
}

export default AdditionalFormInfoSection
