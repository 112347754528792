import React from 'react'
import { components } from 'cng-web-lib'
import ResponseMessage from './ResponseMessage'
import ActivityLog from './ActivityLog'
import Grid from '@material-ui/core/Grid'

const {
  button: { CngButton }
} = components

function ViewLogsAndHistorySection(props) {
  const { onBack, onDiscard, onSubmit, ...otherProps } = props

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ResponseMessage {...otherProps} />
          </Grid>
          <Grid item xs={12}>
            <ActivityLog {...otherProps} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify='space-between' spacing={3}>
          <Grid item xs={12} md='auto'>
            <CngButton color='secondary' onClick={onDiscard} size='medium'>
              Discard
            </CngButton>
          </Grid>
          <Grid item xs={12} md='auto'>
            <Grid container spacing={2}>
              <Grid item xs='auto'>
                <CngButton color='secondary' onClick={onBack} size='medium'>
                  Back
                </CngButton>
              </Grid>
              <Grid item xs='auto'>
                <CngButton color='primary' onClick={onSubmit} size='medium'>
                  Submit
                </CngButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ViewLogsAndHistorySection
