import React, { useContext, useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../constants/locale/key/NBCICanadaInvoice'
import { CAInvoiceLookupsContext } from './lookups/CAInvoiceLookupsContext'
import StatusBar from '../../components/aciacehighway/StatusBar'
import TinyChip from 'src/components/aciacehighway/TinyChip'
import LocalTable from '../../components/aciacehighway/LocalTable'
import CngSection from '../../components/cngcomponents/CngSection'
import CngField from '../../components/cngcomponents/CngField'
import AdditionalFormInfoSection from './view/AdditionalFormInfoSection'
import InvoiceDetailsTable from './view/InvoiceDetailsTable'
import InvoiceSummarySection from './view/InvoiceSummarySection'
import ResponseMessage from './ResponseMessage'
import ActivityLog from './ActivityLog'
import moment from 'moment'
import _ from 'lodash'
import {
  Avatar,
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const {
  button: { CngButton },
  CngDialog
} = components

const useStyles = makeStyles((theme) => ({
  transportIcon: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.divider}`,
    color: theme.palette.text.primary,
    height: 32,
    width: 32
  }
}))

function InvoiceViewContent(props) {
  const { data, history, showNotification } = props

  const [tradePartyDialog, setTradePartyDialog] = useState({
    open: false,
    party: null
  })
  const { lookups, getLookupValue, getCountryStateLabel } = useContext(
    CAInvoiceLookupsContext
  )
  const classes = useStyles()
  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const invoiceNumber = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.INVOICE_NUMBER
    )
    const invoiceDate = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.INVOICE_DATE
    )
    const purchaseOrderNum = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.PURCHASE_ORDER_NUM
    )
    const currencey = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CURRENCEY
    )
    const tripVoyageNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.TRIP_VOYAGE_NO
    )
    const shipmentDate = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.SHIPMENT_DATE
    )
    const entryNumber = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ENTRY_NUMBER
    )
    const transactionType = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.TRANSACTION_TYPE
    )
    const portOfExit = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.PORT_OF_EXIT
    )
    const transportMode = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.TRANSPORT_MODE
    )
    const carrierCode = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CARRIER_CODE
    )
    const carrierName = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CARRIER_NAME
    )
    const ccn = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CCN
    )
    const grossWeight = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.GROSS_WEIGHT
    )
    const grossWeightUom = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.GROSS_WEIGHT_UOM
    )
    const netWeight = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.NET_WEIGHT
    )
    const netWeightUom = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.NET_WEIGHT_UOM
    )
    const marksNumbers = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.MARKS_NUMBERS
    )
    const tradeParty = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.TITLE
    )
    const partyidentifier = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.PARTYIDENTIFIER
    )
    const partyName = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.PARTY_NAME
    )
    const partyType = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.PARTY_TYPE
    )
    const address = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.ADDRESS
    )
    const countryCode = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.COUNTRY_CODE
    )

    return {
      invoiceNumber,
      invoiceDate,
      carrierCode,
      carrierName,
      purchaseOrderNum,
      tripVoyageNo,
      shipmentDate,
      ccn,
      currencey,
      portOfExit,
      transportMode,
      grossWeight,
      grossWeightUom,
      netWeight,
      netWeightUom,
      entryNumber,
      transactionType,
      marksNumbers,
      partyidentifier,
      partyType,
      partyName,
      address,
      countryCode,
      tradeParty
    }
  }

  const { ciForm, invDetails, invSummary, invTradeParty } = data

  const tradePartyTableColumns = [
    {
      title: translatedTextsObject.partyidentifier,
      field: 'partyidentifier',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.partyType,
      field: 'partyType',
      filterConfig: {
        type: 'select',
        options: lookups?.partyType
          ? Object.entries(lookups.partyType).map(([code, label]) => ({
              label: label,
              value: code
            }))
          : []
      },
      render: (data) => getLookupValue('partyType', data.partyType)
    },
    {
      title: translatedTextsObject.partyName,
      field: 'partyName',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.address,
      field: 'address',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.countryCode,
      field: 'countryCode',
      filterConfig: { type: 'textfield' },
      render: (data) => getLookupValue('country', data.countryCode)
    }
  ]

  function renderTransportModeIcon(mode) {
    function getIconName() {
      switch (mode) {
        case '7':
          return 'envelope'
        case 'J':
          return 'truck'
        case 'A':
          return 'plane'
        case 'R':
          return 'train-track'
        case 'S':
          return 'ship'

        default:
          return null
      }
    }

    const iconName = getIconName()

    return (
      iconName && (
        <Avatar className={classes.transportIcon}>
          <FontAwesomeIcon
            color='inherit'
            icon={['fal', getIconName()]}
            size='xs'
          />
        </Avatar>
      )
    )
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StatusBar status={data.status} />
        </Grid>
        <Grid item xs={12}>
          <CngSection title='Invoice information'>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.invoiceNumber}>
                  {data.invoiceNumber}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.invoiceDate}>
                  {data.invoiceDate &&
                    moment(data.invoiceDate).format('D MMM YYYY')}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.carrierCode}>
                  {data.carrierCode}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.carrierName}>
                  {data.carrierName}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.purchaseOrderNum}>
                  {data.purchaseOrderNum}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.tripVoyageNo}>
                  {data.tripVoyageNo}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.shipmentDate}>
                  {data.shipmentDate &&
                    moment(data.shipmentDate).format('D MMM YYYY')}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.ccn}>
                  {data.ccn}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.currencey}>
                  {data.currencey}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.portOfExit}>
                  {getLookupValue('acePort', data.portOfExit)}
                  <TinyChip label={data.portOfExit} variant='outlined' />
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.transportMode}>
                  <Box display='flex' alignItems='center' style={{ gap: 8 }}>
                    {renderTransportModeIcon(data.transportMode)}
                    <Typography variant='inherit'>
                      {getLookupValue('transportMode', data.transportMode)}
                    </Typography>
                  </Box>
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.grossWeight}>
                  {data.grossWeight}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.grossWeightUom}>
                  {getLookupValue('weightUom', data.grossWeightUom)}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.netWeight}>
                  {data.netWeight}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.netWeightUom}>
                  {getLookupValue('weightUom', data.netWeightUom)}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.entryNumber}>
                  {data.entryNumber}
                </CngField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CngField label={translatedTextsObject.transactionType}>
                  {getLookupValue('transactionType', data.transactionType)}
                </CngField>
              </Grid>
              <Grid item xs={12}>
                <CngField label={translatedTextsObject.marksNumbers}>
                  {data.marksNumbers}
                </CngField>
              </Grid>
            </Grid>
          </CngSection>
        </Grid>
        {!_.isEmpty(invTradeParty) && (
          <Grid item xs={12}>
            <Card variant='outlined'>
              <LocalTable
                columns={tradePartyTableColumns}
                data={invTradeParty}
                header={
                  <Typography
                    component='div'
                    variant='h5'
                    style={{ fontWeight: 600 }}
                  >
                    {translatedTextsObject.tradeParty}
                  </Typography>
                }
                onRowClick={(data) =>
                  setTradePartyDialog({ open: true, party: data })
                }
              />
              <TradePartyDialog
                getCountryStateLabel={getCountryStateLabel}
                getLookupValue={getLookupValue}
                open={tradePartyDialog.open}
                onClose={() =>
                  setTradePartyDialog({ open: false, party: null })
                }
                title={translatedTextsObject.tradeParty}
                translate={translate}
                party={tradePartyDialog.party}
              />
            </Card>
          </Grid>
        )}
        {!_.isEmpty(ciForm) && (
          <Grid item xs={12}>
            <CiFormSection
              ciForm={ciForm}
              getLookupValue={getLookupValue}
              getCountryStateLabel={getCountryStateLabel}
              translate={translate}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <AdditionalFormInfoSection
            invoiceData={data}
            showNotification={showNotification}
          />
        </Grid>
        <Grid item xs={12}>
          <InvoiceDetailsTable
            data={invDetails}
            showNotification={showNotification} 
          />
        </Grid>
        <Grid item xs={12}>
          <InvoiceSummarySection
            invoiceSummary={invSummary}
            showNotification={showNotification} 
          />
        </Grid>
        <Grid item xs={12}>
          <ResponseMessage
            id={data.id}
            showNotification={showNotification}
          />
        </Grid>
        <Grid item xs={12}>
          <ActivityLog
            id={data.id}
            showNotification={showNotification}
          />
        </Grid>
      </Grid>
    </>
  )
}

function TradePartyDialog(props) {
  const {
    getCountryStateLabel,
    getLookupValue,
    onClose,
    open,
    party,
    title,
    translate
  } = props

  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const partyId = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.PARTY_ID
    )
    const partyName = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.PARTY_NAME
    )
    const identificationCode = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.IDENTIFICATION_CODE
    )
    const fdaRegNum = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.FDA_REG_NUM
    )
    const address = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.ADDRESS
    )
    const city = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.CITY
    )
    const countryCode = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.COUNTRY_CODE
    )
    const stateCode = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.STATE_CODE
    )
    const postalCode = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.POSTAL_CODE
    )
    const contactName = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.CONTACT_NAME
    )
    const contactTelphNum = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.CONTACT_TELPH_NUM
    )
    const contactFax = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.CONTACT_FAX
    )
    const contactEmail = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.CONTACT_EMAIL
    )
    const partyType = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.PARTY_TYPE
    )
    const dba = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.DBA
    )
    const partyidentifier = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.PARTYIDENTIFIER
    )
    const secondIdentificationCode = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvTradeParty.SECOND_IDENTIFICATION_CODE
    )

    return {
      partyId,
      partyType,
      dba,
      partyName,
      fdaRegNum,
      identificationCode,
      address,
      city,
      countryCode,
      stateCode,
      postalCode,
      contactName,
      contactTelphNum,
      contactFax,
      contactEmail,
      partyidentifier,
      secondIdentificationCode
    }
  }

  return (
    <CngDialog
      customDialogContent={
        <Box p={2}>
          {party ? (
            <CngSection title='Party Information'>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.partyId}>
                        {party.partyId}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.partyType}>
                        {getLookupValue('partyType', party.partyType)}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <CngField label={translatedTextsObject.dba}>
                        {party.dba}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={9}>
                      <CngField label={translatedTextsObject.partyName}>
                        {party.partyName}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.fdaRegNum}>
                        {party.fdaRegNum}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField
                        label={[
                          translatedTextsObject.partyidentifier,
                          translatedTextsObject.identificationCode
                        ].join(' & ')}
                      >
                        {[
                          party.partyidentifier,
                          party.identificationCode
                        ].join(' - ')}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={9}>
                      <CngField label={translatedTextsObject.address}>
                        {party.address}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.city}>
                        {party.city}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.countryCode}>
                        {getLookupValue('country', party.countryCode)}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.stateCode}>
                        {getCountryStateLabel(party.countryCode, party.stateCode)}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.postalCode}>
                        {party.postalCode}
                      </CngField>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid alignItems='center' container spacing={2}>
                    <Grid item xs='auto'>
                      <Typography variant='body2' color='textSecondary'>
                        Local Contact Information
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Divider />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.contactName}>
                        {party.contactName}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.contactTelphNum}>
                        {party.contactTelphNum}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.contactFax}>
                        {party.contactFax}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.contactEmail}>
                        {party.contactEmail}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <CngField label={translatedTextsObject.secondIdentificationCode}>
                        {party.secondIdentificationCode}
                      </CngField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CngSection>
          ) : (
            <Box display='flex' alignItems='center' justifyContent='center'>
              <CircularProgress />
            </Box>
          )}
        </Box>
      }
      dialogAction={<CngButton onClick={onClose}>OK</CngButton>}
      dialogTitle={title}
      maxWidth='md'
      fullWidth
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

function CiFormSection(props) {
  const { ciForm, getLookupValue, translate } = props
  const theme = useTheme()

  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const ciForm = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CiForm.TITLE
    )
    const countryTransShipment = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CiForm.COUNTRY_TRANS_SHIPMENT
    )
    const countryOfOrigin = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CiForm.COUNTRY_OF_ORIGIN
    )
    const placeOfDirectShipment = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CiForm.PLACE_OF_DIRECT_SHIPMENT
    )
    const alternativeInvNo = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CiForm.ALTERNATIVE_INV_NO
    )
    const customsRuling = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CiForm.CUSTOMS_RULING
    )
    const transportTermCode = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CiForm.TRANSPORT_TERM_CODE
    )
    const termOfSale = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CiForm.TERM_OF_SALE
    )
    const royalityPayments = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CiForm.ROYALITY_PAYMENTS
    )
    const royalityGoods = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CiForm.ROYALITY_GOODS
    )
    const cargoSummary = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CiCargoSummary.TITLE
    )
    const reference = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CiCargoSummary.REFERENCE
    )
    const referenceNumber = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CiCargoSummary.REFERENCE_NUMBER
    )

    return {
      ciForm,
      countryTransShipment,
      countryOfOrigin,
      placeOfDirectShipment,
      alternativeInvNo,
      customsRuling,
      transportTermCode,
      termOfSale,
      royalityPayments,
      royalityGoods,
      cargoSummary,
      reference,
      referenceNumber,
    }
  }

  function getCardHeaderBackgroundColor() {
    return theme.palette.background?.sectionOddBg || theme.palette.grey[200]
  }

  return (
    <Card variant='outlined'>
      <Box p={2}>
        <Typography style={{ fontWeight: 600 }}>
          Additional Information
        </Typography>
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <Box bgcolor={getCardHeaderBackgroundColor()} px={2} py={1}>
            <Typography color='textSecondary' style={{ fontWeight: 600 }}>
              {translatedTextsObject.ciForm}
            </Typography>
          </Box>
          <Grid container>
            {ciForm.map((data, index) => (
              <React.Fragment key={data.id}>
                <Grid item xs={12}>
                  <Box p={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} md={3}>
                            <CngField label={translatedTextsObject.countryTransShipment}>
                              {getLookupValue('country', data.countryTransShipment)}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <CngField label={translatedTextsObject.countryOfOrigin}>
                              {getLookupValue('country', data.countryOfOrigin)}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <CngField label={translatedTextsObject.placeOfDirectShipment}>
                              {data.placeOfDirectShipment}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <CngField label={translatedTextsObject.alternativeInvNo}>
                              {data.alternativeInvNo}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <CngField label={translatedTextsObject.customsRuling}>
                              {data.customsRuling}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <CngField label={translatedTextsObject.termOfSale}>
                              {data.termOfSale}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <CngField label={translatedTextsObject.transportTermCode}>
                              {getLookupValue('transportTermCode', data.transportTermCode)}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <CngField label={translatedTextsObject.royalityPayments}>
                              {data.royalityPayments === 'Y' ? 'Yes' : 'No'}
                            </CngField>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <CngField label={translatedTextsObject.royalityGoods}>
                              {data.royalityGoods === 'Y' ? 'Yes' : 'No'}
                            </CngField>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Card variant='outlined'>
                          <Box padding={2}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Typography variant='h5' style={{ fontWeight: 600 }}>
                                  {translatedTextsObject.cargoSummary}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                {!_.isEmpty(data.ciCargoSummary) ? (
                                  <Grid container spacing={2}>
                                    {data.ciCargoSummary.map((cargoSummary, index) => (
                                      <React.Fragment key={cargoSummary.id}>
                                        <Grid item xs={12}>
                                          <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                              <CngField label={translatedTextsObject.reference}>
                                                {getLookupValue('reference', cargoSummary.reference)}
                                              </CngField>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                              <CngField label={translatedTextsObject.referenceNumber}>
                                                {cargoSummary.referenceNumber}
                                              </CngField>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                        {index + 1 < data.ciCargoSummary.length && (
                                          <Grid item xs={12}>
                                            <Divider />
                                          </Grid>
                                        )}
                                      </React.Fragment>
                                    ))}
                                  </Grid>
                                ) : (
                                  <Typography color='textSecondary'>
                                    No records to display
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          </Box>
                        </Card>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                {index + 1 < ciForm.length && (
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                )}
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

export default InvoiceViewContent
